import { MODAL_TOGGLE_NONE,
MODAL_TOGGLE_BLOCK, 
MODAL_TOGGLE_STATE} from "../Actions/types";

const reducer=(state={}, action)=>{
    
    switch (action.type) {
        
        case MODAL_TOGGLE_NONE :  return Object.assign(state, {toggle:action.payload})

        case MODAL_TOGGLE_BLOCK : return Object.assign(state, {toggle:action.payload})
        
        case MODAL_TOGGLE_STATE : return Object.assign(state,{toggle:action.payload})
    
        default: return state
            
    }
}

export default  reducer