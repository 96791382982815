import axios from 'axios'
import React, { useState } from 'react'
import { withRouter } from 'react-router'
import { url } from '../../../enum'

function FindPsswordModal(props) {
    const [check,setCheck]=useState(true)
    const postEmail=()=>{
        !check? alert("이메일을 이미 보냈습니다.")
        :
        axios.post(url+"/users/temp-pw",{
            userid: props.email,
            username: props.name
        }).then(res=>{
            if(res.data.resCode===0){
                alert(res.data.message)
                props.history.push('/menu')
            }else{
                alert("비밀번호 찾기에 실패 했습니다 잠시후 다시 시도해 주십시오")
                props.history.push('/menu')
            }
        })
        setCheck(false)

    }
    return (
        <div className="modal-main-practice_test-default" style={{display:props.visible}}>
        <div className="modal-dim"></div>

        <div className="modal-content content_1 bg-white px-10 passwordConfirmModal" >
            <p className="fz-14 fw-bold text-center c-text11 mt-20 mb-10">{props.email}</p>
            <p className="fz-12 text-center lh-15 c-text1 mb-40 " >
                확인을 누르시면 해당 이메일로 바뀐 비밀번호가 발송됩니다. <br/>
                임시 비밀번호로 로그인 이후 회원정보 변경에서 비밀 번호 변경을 해주십시오.
            </p>
                <input type="button" value="확인" onClick={()=>postEmail()} className="confirm_2 button bg-orange2 fz-12 fw-bold"/>
        </div>
    </div>
    )
}

export default withRouter(FindPsswordModal)
