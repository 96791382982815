import React, { useEffect, useState } from 'react'
import ModifyUserConfirmModal from'./ModifyUserConfirmModal'
import logo_black from '../../../image/publishing_Img/logo_black.png'


function ModifyUserInfo(props) {
    let userInfo=JSON.parse(localStorage.getItem('userInfo'))
    const [email]=useState(userInfo.userid)
    
    const [password]=useState("")
    const [confirmPassword]=useState("")
    const [gender, setGender]=useState({before:userInfo.gender===1?"남자":"여자", after:""})
    
    const [afterPass,setafterPass]=useState(password)
    const [afterConfirmPass,setAfterConfirmPass]=useState(confirmPassword)
    const [confirm,setConfirm]=useState("")

    const [name]=useState(userInfo.username)
    const [birth]=useState(userInfo.birthday)
    
    const [button,setButton]=useState(( 
                                        <button name="next" className="button revise">
                                            <p name="next" className="c-white">회원가입수정</p>
                                        </button>
                                        ))
                                        
    const [visible,setVisible]=useState('none')

    
    useEffect(() => {
        if(userInfo.gender===1){
            document.getElementById('male').className="fz-12 c-white button gender_button bg-orange2 gender_B"
            document.getElementById('female').className="fz-12 text11 button gender_button bg-gray1 gender_A"
        }else{
            document.getElementById('male').className="fz-12 text11 button gender_button bg-gray1 gender_B"
            document.getElementById('female').className="fz-12 c-white button gender_button bg-orange2 gender_A"
        }
    
    
    
    }, [])
    
    useEffect(() => {

       
        let bool={genter:"", password:""}

        if(gender.after!==""&&gender.before!==gender.after){
            //변함
            bool.genter=true
            
        }else{
            bool.genter=false
        }
        if(afterPass===afterConfirmPass&&afterPass!==""){
            if(afterPass!==password){
                console.log("패스워드 변함")
                bool.password=true
            }else{
                bool.password=false 
            }
        }
        //비밀번호 채크
        if(afterPass.length!==0&&afterPass===afterConfirmPass){
            
            setConfirm((<p className="fz-8 c-orange3 underline_position">비밀번호가 일치합니다.</p>))
        }else{
            setConfirm("")
        }

        if(bool.genter||bool.password){
            setButton((
                <div className="is-flex x-between mt-50" style={{marginTop:'10px'}}>
                    <input onClick={()=>props.history.push('/menu')} type="button"  defaultValue="취소" className="cancel button bg-gray2 c-white"/>
                    <button className="cancel button revise_2 bg-orange3 c-white">
                        수정하기
                    </button>
                </div>
            ))

        }else{
            setButton((
                <input name="next" defaultValue="회원가입 수정" disabled style={{textAlign:"center"}}  className="button revise c-white" />
            ))
        }
    }, [afterPass,afterConfirmPass,gender])    
   
    const passwordChange =(e)=>{
        setafterPass(e.target.value)
    }
    //비밀번호 확인
    const confirmPasswordChange=(e)=>{
        setAfterConfirmPass(e.target.value)
    }
   
   
    //성별선택
    const changeGender=(e)=>{
        let guest_3 =document.getElementsByClassName("wrap-menu-user_info")[0];

     
        if(e.target.id==='female'){
            guest_3.querySelector(".gender_A").className="fz-12 c-white button gender_button  bg-orange2 gender_A"
            guest_3.querySelector(".gender_B").className="fz-12 text11 button gender_button bg-gray1 gender_B"
            gender.after="여자"
            gender.before=gender.before
            setGender({before:gender.before,after:"여자"})
        }else{
            guest_3.querySelector(".gender_A").className="fz-12 text11 button gender_button bg-gray1 gender_A"
            guest_3.querySelector(".gender_B").className="fz-12 c-white button gender_button bg-orange2 gender_B"
            gender.after="남자"
            gender.before=gender.before
            setGender({before:gender.before,after:"남자"})
        }
    }
   
  

    //회원가입
    const onSubmit=(e)=>{
        e.preventDefault()
        
        if(afterPass!==afterConfirmPass){
            alert("비밀번호가 서로 맞지 안습니다.")
            return false
        }
        
        if(afterPass.length<6){
            alert("비밀번호 6자리를 입력해주세요")
            return false
        }
       setVisible('block')
    }

    return (
      <div className="bg-gray1">
        <header className="gosu-header-relative">
          <div className="gosu-header is-flex y-center x-center bg-white">
            <label className="back_1">
              <p
                onClick={() => props.history.goBack()}
                className="fz-14 c-text11"
              >
                이전
              </p>
            </label>
            <p className="c-text11 fz-16 fw-bold">회원정보 조회/수정</p>
          </div>
        </header>

        <form onSubmit={onSubmit}>
          <div className="wrap-menu-user_info bg-gray1">
            <div className="wrap_box bg-white">
              <div className="logo is-flex x-center y-center mb-5">
                <img alt="로고" src={logo_black} />
              </div>
              <div className="">
                <p className="fz-12 fw-bold c-text11 mb-10">아이디(이메일)</p>
                <div className="mb-30 rel_box">
                  <input
                    style={{ width: "100%" }}
                    className="typing_2 ml-5 c-text1 fz-12"
                    type="email"
                    defaultValue={email}
                    readOnly
                  />
                </div>

                <p className="fz-12 fw-bold c-text11 mb-10">비밀번호</p>
                <div className="mb-30 rel_box">
                  <input
                    className="typing_1 ml-5 c-text1 fz-12"
                    name="password"
                    id="password"
                    type="password"
                    defaultValue={afterPass}
                    onChange={passwordChange}
                    placeholder="비밀번호 6자리"
                    required
                  />
                  {confirm}
                </div>
                <p className="fz-12 fw-bold c-text11 mb-10">비밀번호 확인</p>
                <div className="mb-25 rel_box">
                  <input
                    className="typing_1 ml-5 c-text1 fz-12"
                    name="password"
                    id="confirmPassword"
                    type="password"
                    defaultValue={afterConfirmPass}
                    onChange={confirmPasswordChange}
                    placeholder="비밀번호 확인"
                    required
                  />
                  {confirm}
                </div>

                <p className="fz-12 fw-bold c-text11 mb-10">이름</p>
                <div className="mb-40">
                  <input
                    type="text"
                    className="typing_1 ml-5 c-text1 fz-12"
                    value={name}
                    readOnly
                  />
                </div>
                <p className="fz-12 fw-bold c-text11 mb-10">생년월일</p>
                <div className="mb-25">
                  <input
                    className="typing_1 ml-5 c-text1 fz-12"
                    type="text"
                    name="birth"
                    defaultValue={birth}
                    readOnly
                  />
                </div>
              </div>
              <div>
                <p className="fz-12 fw-bold c-text11 mb-20"></p>
                <div>
                  <div className="gender_box is-flex">
                    <input
                      onClick={changeGender}
                      type="hidden"
                      id="female"
                      value="여성"
                      className="fz-12 c-white button gender_button bg-orange2 gender_A"
                    />
                    {/* fz-12 c-white */}
                    <input
                      onClick={changeGender}
                      type="hidden"
                      id="male"
                      value="남성"
                      className="fz-12 text11 button gender_button bg-gray1 gender_B"
                    />
                    {/* fz-12 c-white */}
                  </div>
                  <div style={{ marginTop: "15%" }}>{button}</div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <ModifyUserConfirmModal
          visible={visible}
          setVisible={setVisible}
          email={email}
          gender={gender}
          name={name}
          afterPass={afterPass}
          afterConfirmPass={afterConfirmPass}
          birth={birth}
        />
      </div>
    );
}

export default ModifyUserInfo
