import React, { useEffect, useState} from 'react'
import NearDriveTestCenterLocationListTestCenter from'./NearDriveTestCenterLocationList_testCenter'
import NearDriveTestCenterLocationListGosu from'./NearDriveTestCenterLocationList_gosu'



function NearDriveTestCenterLocation(props) {
    
    
    const [where,setWhere]=useState("nation")
    const [location,setLocation]=useState("전국 운전면허 시험장")
    const [view,setView]=useState(<NearDriveTestCenterLocationListTestCenter where={where}/>)
    let gosuOrTestCenter=props.location

    useEffect(() => {
        if(gosuOrTestCenter==="country"){
            setLocation("전국 운전면허 시험장")
            setView(<NearDriveTestCenterLocationListTestCenter where={where}/>)
        }else{
            setLocation("가까운 고수의 운전면허 연습장 찾기")
            setView(<NearDriveTestCenterLocationListGosu where={where}/>)
        }

    }, [gosuOrTestCenter,where])

   
    const countryCourse =(e,where)=>{
        
        let td=document.getElementsByTagName('td')
        for(let i=0; i<td.length; i++){
            td[i].className=""
        }
        e.currentTarget.className="on"
        //currentTarget는 event가 붙어있는 요소를 직접적으로 가르킴
        setWhere(where)
      
    }
   
    
    return (

    <div className=" test_place_box bg-white" >
         <p className="text11 fz-14 fw-bold mb-20">{location}</p>
         <table className="top_table mb-10">
             <tbody>
                <tr>
                     <td className="on" onClick={(e)=>countryCourse(e,'nation')} ><p>전체</p></td> 
                     <td onClick={(e)=>countryCourse(e,'seoul')} ><p>서울</p></td>
                     <td onClick={(e)=>countryCourse(e,'kyungki')} ><p>경기/인천</p></td> 
                 </tr>
                <tr>
                    <td onClick={(e)=>countryCourse(e,'busan')} ><p>부산/울산</p></td>
                    <td onClick={(e)=>countryCourse(e,'daegu')} ><p>대구/경북</p></td>
                    <td onClick={(e)=>countryCourse(e,'kwangju')} ><p>광주/전라</p></td> 
                </tr>
                <tr>
                    <td onClick={(e)=>countryCourse(e,'daejyun')} ><p>대전/세종/충청</p></td>
                    <td onClick={(e)=>countryCourse(e,'kangwon')} ><p>강원/제주</p></td>
                </tr>
             </tbody>
         </table>
        {view}

    </div>
    )
}

export default NearDriveTestCenterLocation
