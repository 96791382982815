import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import procedure from'../../../image/publishing_Img/test/procedure_img.png'
import DeTailTab1 from'./DetailTab_1'
import DeTailTab2 from'./DetailTab_2'
import DeTailTab3 from'./DetailTab_3'
import DeTailTab4 from'./DetailTab_4'
import DeTailTab5 from'./DetailTab_5'
function TotalTestInfomation(props) {
    let menuNumber=props.location.state.menuNum
    const [view,setView]=useState("")
    const [name,setName]=useState("")
    useEffect(() => {
        if(menuNumber===1){
            setView((
                <article className="wrap_gnb_test_info–step bg-gray1">
                    <img src={procedure} alt="절차"/>
                </article>
            ))
            setName("면허취득 절차")
        }else if(menuNumber===2){
            setView(<DeTailTab1/>)
            setName("시험자격 / 결격 / 면제")
        }else if(menuNumber===3){
            setView(<DeTailTab2/>)
            setName('응시 전 교통안전교육')
        }else if(menuNumber===4){
            setView(<DeTailTab3/>)
            setName("학과시험")
        }else if(menuNumber===5){
            setName("기능시험")
            setView(<DeTailTab4/>)
        }else if(menuNumber===6){
            setName("연습면허 발급")
            setView((
                <div className="wrap_gnb_test_info–practice_license bg-white">
                    <h2 className="fz-15 c-orange2 mb-15 fw-bold">준비물 및 수수료</h2>
                    <p className=" c-text11 mb-20">· 응시원서, 신분증, 수수료 4,000원<br/>
                        · 대리접수 : 대리인 신분증(원본), 위임장</p>
                    <h2 className="fz-15 c-orange2 fw-bold mb-15">연습면허 교환 발급 유효기간 신청 안내</h2>
                    <p className="fz-14 c-text11 mb-10">· 연습면허발급의 유효기간은 1년입니다.</p>
                    <p className="fz-14 c-text11 lh-15">· 최초 1종 연습면허 소지자가 2종 보통 연습 면허로 격하
                        하여 교환 발급시 최초 발급받은 연습면허 잔여기간을 
                        유효기간으로 하는 연습 면허가 발급됨</p>
                    
                    <input type='button' onClick={()=>window.open("https://www.safedriving.or.kr/mainM.do")}  value="연습면허 발급신청"className="button Apply_button"/>
                </div>
            ))
        }else if(menuNumber===7){
            setName("도로주행시험")
            setView(<DeTailTab5/>)
        }else if(menuNumber===8){
            setName("운전면허증 발급")
            setView((
                <article className="wrap_gnb_test_info–driver_license bg-white">
        
                    <h3 className="fz-12 lh-15 mb-15">※ 각 응시 종별<br/>
                    <span className="fz-10">(1종 대형, 1종 보통, 1종 특수, 2종 보통, 2종 소형, 2종원동기장치 자전거)에</span> 
                    따른 응시과목을 최종 합격했을 경우 발급</h3>
                    <h2 className="mb-10 lh-15">
                        발급대상
                    </h2>
                    <p className="mb-10 lh-15">· 1,2종 보통 면허 : 연습면허 발급 후 도로주행시험<br/> 
                        (운전전문학원 졸업자는 도로주행 검정)에 합격한 자</p>
                    <p className="mb-20">· 기타 면허 : 학과시험 및 장내기능 시험에 합격한 자</p>
                    <h2 className="mb-10">발급 장소</h2>
                    <p className="mb-25">· 운전면허시험장</p>
                    <h2 className="mb-10">구비서류</h2>
                    <p className="lh-15 mb-10">· 최종 합격한 응시원서, 신분증, 최근 6개월 이내 촬영한 
                        컬러 사진 (규격 3.5cm*4.5cm) 1매, 수수료 8,000원</p>
                    <p>· 대리접수 : 대리인 신분증, 위임장</p>
                </article>
            ))
        }else if(menuNumber===9){
            setName("면허시험 준비물 가이드")
            setView((
                <article class="wrap_gnb_test_info–driver_license">
                    <h2 class="mb-20">단계별 준비물</h2>
                    <table class="materials_text_box">
                        <thead>
                            <tr>
                                <td>
                                    구분
                                </td>
                                <td>
                                    수수료
                                </td>
                                <td>
                                    준비물 및<br/>
                                    주의사항
                                </td>
                                <td>
                                    인터넷접수 및<br/>
                                    대리접수<br/>
                                    가능여부
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    신규응시<br/>
                                    신체검사
                                </td>
                                <td>
                                    1종 대형/특수<br/>
                                    <span>7,000원</span><br/>
                                    기타면허<br/>
                                    <span>6,000원</span>
                                </td>
                                <td>
                                    신분증, 6개월이내
                                    촬영한 컬러 사진
                                    3매<br/>
                                    (3.5cm x 4.5cm)
                                </td>
                                <td>
                                    인터넷 불가<br/>
                                    대리 불가,<br/>
                                    본인신체검사
                                </td>
                            </tr>
                     
                            <tr>
                                <td>
                                    학과시험<br/>
                                    (재 응시 포함)
                                </td>
                                <td>
                                    원동기제외<br/>
                                    <span>10,000원</span>
                                    원동기
                                    <span>8,000원</span>
                                </td>
                                <td>
                                    신분증, 응시원서
                                </td>
                                <td>
                                    인터넷 불가<br/>
                                    대리 불가
                                </td>
                            </tr>
                          
                            <tr>
                                <td>
                                    기능시험<br/>
                                    (재 응시 포함)
                                </td>
                                <td>
                                    대형/특수<br/>
                                    <span>20,000원</span><br/>
                                    1,2종 보통<br/>
                                    <span>22,000원</span><br/>
                                    2종 소형<br/>
                                    <span>10,000원</span><br/>
                                    원동기<br/>
                                    <span>8,000원</span>
                                </td>
                                <td>
                                    신분증, 응시원서
                                </td>
                                <td>
                                    인터넷 가능<br/>
                                    대리 가능<br/>
                                    (위임장, 대리인
                                    위임자 신분증,
                                    응시원서)
                                </td>
                            </tr>
                        
                            <tr>
                                <td>
                                    도로주행 시험<br/>
                                    (재 응시 포함)
                                </td>
                                <td>
                                    <span>25,000원</span>
                                </td>
                                <td>
                                    신분증, 응시원서
                                </td>
                                <td>
                                    인터넷 가능<br/>
                                    대리 가능<br/>
                                    (위임장, 대리인
                                    위임자 신분증,
                                    응시원서)
                                </td>
                            </tr>
                        
                            <tr>
                                <td>
                                    합격자 면허증<br/>
                                    교부
                                </td>
                                <td>
                                    <span>8,000원</span>
                                </td>
                                <td>
                                    신분증, 응시원서,
                                    6개월이내 촬영한
                                    컬러사진 1매<br/>
                                    (3.5cm x 4.5cm)
                                </td>
                                <td>
                                    인터넷 불가<br/>
                                    대리 가능<br/>
                                    (위임장, 대리인
                                    위임자 신분증,
                                    응시원서)
                                </td>
                            </tr>
                         

                            <tr>
                                <td>
                                    연습면허 /<br/>
                                    연습면허 재교부
                                </td>
                                <td>
                                    <span>4,000원</span>
                                </td>
                                <td>
                                    신분증, 응시원서
                                </td>
                                <td>
                                    인터넷 가능<br/>
                                    대리 가능<br/>
                                    (위임장, 대리인
                                    위임자 신분증,
                                    응시원서)
                                </td>
                            </tr>
                           

                            <tr>
                                <td>
                                    응시원서 분실
                                </td>
                                <td>
                                    <span>없음</span><br/>
                                        연습면허 재발급시<br/>
                                        <span>4,000원</span>
                                </td>
                                <td>
                                    신분증, 6개월 이내<br/>
                                    촬영한 컬러 사진<br/>
                                    3매<br/>
                                    (3.5cm x 4.5cm)
                                </td>
                                <td>
                                    인터넷 불가<br/>
                                    대리 가능<br/>
                                    (위임장, 대리인
                                    위임자 신분증,
                                    응시원서)
                                </td>
                            </tr>
                          
                        </tbody>
                    </table>
                </article>
            ))
        }
    }, [menuNumber])
   

    return (
        <>
        <header className="gosu-header-relative">
            <div className="gosu-header is-flex y-center x-center bg-orange2">
                <label className="back_1">
                    <p onClick={()=>props.history.goBack()} className="fz-14 c-text11">이전</p>
                </label>
                <p className="c-text11 fz-16 fw-bold">{name}</p>
            </div>
        </header>
        
            {view}  
    

        </>
        
            
        
    )
}

export default withRouter(TotalTestInfomation)