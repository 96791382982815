import React, {useEffect, useState } from 'react'
import icon_left_arrow from'../../../image/publishing_Img/icon_left_arrow.svg'
import icon_right_arrow from'../../../image/publishing_Img/icon_right_arrow.svg'
import ScheduleModal1 from './ScheduleModal_1'
import moment from'moment'
import axios from 'axios'
import { url } from '../../../enum'

function Schedule(props) {

    const [getMoment,setMoment]=useState(moment())
    // 스케쥴 인덱스
    const [index,setIndex]=useState("")
    const today=getMoment
    const firstWeek=today.clone().startOf('month').week()
    const lastWeek=today.clone().endOf('month').week()===1?53:today.clone().endOf('month').week()
    //스케쥴 임시데이터

    let data=props.location.state
    
    const [schedule,setSchedule]=useState([])
    useEffect(() => {

        axios.post(url+"/schedules",data).then(res=>setSchedule([...schedule,...res.data.rows]))
    }, [])

    

    //캘릭더 클릭시 className="bg-gray1"
    //데이터가 있는 날짜 <div>날짜<div> 밑에   <div className="calendar_mark"></div> 을 입력
    //<div className="c-red1">31</div> 일요일표시
    const click=(e)=>{

        let td=document.getElementsByName('td')
        for(let i=0; i<td.length; i++){
            td[i].className="c-text13"
        }

        e.currentTarget.className="bg-gray1"
    }
    const openModal=(e,scheduleData)=>{

        let td=document.getElementsByName('td')
        for(let i=0; i<td.length; i++){
            td[i].className="c-text13"
        }

        e.currentTarget.className="bg-gray1"
        
        // 모달에 전달할 데이터
        setVisible_1('block')
        setIndex(scheduleData)
    }
    const calendarArr= ()=>{
        let result=[];
        let week=firstWeek
        
        // 스케쥴이 임시데이터
        
        
        
        for(week; week<=lastWeek; week++){
           
            result=result.concat(
                
                    <tr className="days" key={week}>
                        {
                            Array(7).fill(0).map((data,index)=>{

                                let days=today.clone().startOf('year').week(week).startOf('week').add(index,'day')

                                if(moment().format('YYYYMMDD')=== days.format('YYYYMMDD')){
                                    // console.log("오늘 : ",days.format('YYYY-MM-DD'))
                                    //스케쥴있는날이 오늘이면 
                                    for(let i=0;i<schedule.length;i++){
                                        if(schedule[i].reservationTime.substring(0,10)===days.format('YYYY-MM-DD')){
                                        //오늘 스케쥴이 있는경우
                                            return(<td key={index} style={{backgroundColor:'#FFEE00'}} onClick={(e)=>openModal(e,schedule[i])} name="td">
                                                        <div className="c-black">{days.format('D')}</div>
                                                        <div className="calendar_mark"></div>
                                                    </td>)
                                            
                                        }
                                    }
                                    //오늘 스케쥴이없는경우
                                    return(
                                        //오늘
                                        <td key={index} style={{backgroundColor:'#FFEE00'}} onClick={click} name="td">
                                            <div className="c-black">{days.format('D')}</div>
                                        </td>
                                    );
                                }else if(days.format('MM')!==today.format('MM')){
                                    return(
                                        //전월 혹은 다음월의 일자
                                        <td key={index} onClick={click} name="td">
                                            <div style={{color:'#6d6d6d'}}>{days.format('D')}</div>
                                        </td>
                                    )
                                }else{
                                    //주말표시
                                    if(index===0||index===6){
                                        //주말
                                        // console.log("주말 : ",days.format('YYYY-MM-DD'))

                                        for(let i=0; i<schedule.length;i++){
                                            if(schedule[i].reservationTime.substring(0,10)===days.format("YYYY-MM-DD")){
                                                // console.log("스케쥴 있는 주말 css로 리턴하자!!")
                                                return(
                                                    <td key={index} onClick={(e)=>openModal(e,schedule[i])} name="td">
                                                        <div className="c-black"><p style={{color:'red'}}>{days.format('D')}</p></div>
                                                        <div className="calendar_mark"></div>
                                                    </td>
                                                )
                                            }
                                        }

                                        return(
                                            <td key={index} onClick={click} name="td">
                                                <div className="c-black"><p style={{color:'red'}}>{days.format('D')}</p></div>
                                            </td>
                                        )    
                                    }
                                    //일반 날짜
                                    // console.log("평일 : ",days.format('YYYY-MM-DD'))
                                    for(let i=0; i<schedule.length;i++){
                                        if(schedule[i].reservationTime.substring(0,10)===days.format("YYYY-MM-DD")){
                                            // console.log("스케쥴 있는 날 css로 리턴하자!!")
                                        return(
                                            <td key={index} onClick={(e)=>openModal(e,schedule[i])} name="td">
                                                <div className="c-black">{days.format('D')}</div>
                                                <div className="calendar_mark"></div>
                                            </td>
                                            )
                                        }
                                    }
                                    //스케쥴 없는 날짜
                                    return(
                                        
                                        <td key={index} onClick={click} name="td">
                                            <div className="c-black">{days.format('D')}</div>
                                            
                                        </td>
                                    )
                                }
                            })
                        }
                    </tr>
                
            );
        }
        return result
    }
    
    const [visible_1,setVisible_1]=useState("none")

    const [visible_2,setVisible_2]=useState("none")
    
    //스케쥴정보 모달 컨트롤 펑션
    const visible1=()=>{
        setVisible_1('block')
    }
    const visible2=()=>{
        setVisible_2('block')
    }
    return (
        <div>
            <header className="gosu-header-relative">
                <div className="gosu-header is-flex y-center x-center bg-white">
                    <label className="back_1">
                        <p onClick={()=>props.history.push("/menu")} className="fz-14 c-text11">이전</p>
                    </label>
                    <p className="c-text11 fz-16 fw-bold">스케쥴</p>
                </div>
            </header>
            <article className="wrap-main-calendar bg-gray1">
           
                <div className="calender_box bg-white">
                    <div className="calender_header is-flex x-between y-center">
                        <button onClick={()=>setMoment(getMoment.clone().subtract(1,'month'))} className="button_size button">
                            <img alt="화살표" src={icon_left_arrow}/>
                        </button>
                        {/* 클릭하면 오늘날짜로 ㄱㄱ */}
                        <p onClick={()=>setMoment(moment())} className="fz-20">{today.format("YYYY 년 MM 월")}</p>

                        <button onClick={()=>setMoment(getMoment.clone().add(1,'month'))} className="button_size button">
                            <img alt="화살표" src={icon_right_arrow}/>
                        </button>
                    </div>
                    {/* <!--calender_header끝--> */}
                    <table>
                        <thead>
                            <tr className="days">
                                <th className="c-">일</th>
                                <th>월</th>
                                <th>화</th>
                                <th>수</th>
                                <th>목</th>
                                <th>금</th>
                                <th>토</th>
                            </tr>
                        </thead>
                        {calendarArr()}
                    </table>
                </div>
      
            </article>

            <ScheduleModal1
                setVisible_1={setVisible_1}
                visible_1={visible_1}
                index={index}
            />
    </div>
        
    )
}

export default Schedule
