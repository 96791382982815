import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import axios from'axios'
import { url } from '../../../enum'

function SignUpConfirmModla(props) {

   
   const setVisible = props.setVisible
   const visible=props.visible
   let gender=props.gender

   useEffect(() => {
    if(gender.after!==""){
        
        if(gender.after==="여자"){

            document.getElementsByName('female')[0].className="button gender_button bg-orange2"
            document.getElementsByName('female')[1].className="fz-12 c-white"
    
            document.getElementsByName('male')[0].className="button gender_button bg-gray1"
            document.getElementsByName('male')[1].className="fz-12 c-text11"

        }else{

            document.getElementsByName('male')[0].className="button gender_button bg-orange2"
            document.getElementsByName('male')[1].className="fz-12 c-white"
    
            document.getElementsByName('female')[0].className="button gender_button bg-gray1"
            document.getElementsByName('female')[1].className="fz-12 c-text11"
        }
     
    }else{

      if(gender.before==="여자"){
        
        document.getElementsByName('female')[0].className="button gender_button bg-orange2"
        document.getElementsByName('female')[1].className="fz-12 c-white"

        document.getElementsByName('male')[0].className="button gender_button bg-gray1"
        document.getElementsByName('male')[1].className="fz-12 c-text11"

      }else{
        
        document.getElementsByName('male')[0].className="button gender_button bg-orange2"
        document.getElementsByName('male')[1].className="fz-12 c-white"

        document.getElementsByName('female')[0].className="button gender_button bg-gray1"
        document.getElementsByName('female')[1].className="fz-12 c-text11"

      }
    }

   }, [gender])
   const confirmModify=()=>{
        //여기에 담아서 axios 사용 ㄱㄱ   
        //     let DATA={

        //    }
        if(gender==="여자"){
            gender=2
        }else{
            gender=1
        }
        axios.put(url+"/users/update",{password:props.afterPass, gender:gender, token:localStorage.getItem('token')}).then(res=>{
            if(res.data.resCode===0){
                alert("성공적으로 수정되었습니다!! 로그아웃 후에 다시 로그인 해주세요!!")
                props.history.push('/menu')

            }else if(res.data.statusCode===500){
                alert("다시 로그인 한뒤 수정해 주세요!!")
                props.history.push('/logInPage')
            }
            
        })
       

   }
    return (
      <article className="modal amend-2" style={{ display: visible }}>
        <div className="modal-dim"></div>
        <div className="modal-content content bg-white">
          <p className="fz-12 fw-bold c-text11 mb-10">아이디(이메일)</p>
          <p className="ID ml-5 c-text12 fz-12 mb-20">{props.email}</p>

          <p className="fz-12 fw-bold c-text11 mb-10">비밀번호</p>
          <input
            type="password"
            defaultValue={props.afterPass}
            className="typing_1 ml-5 c-text12 fz-12 mb-30"
          />

          <p className="fz-12 fw-bold c-text11 mb-10">비밀번호 확인</p>
          <input
            type="password"
            defaultValue={props.afterConfirmPass}
            className="typing_1 ml-5 c-text12 fz-12 mb-30"
          />

          <p className="fz-12 fw-bold c-text11 mb-10">이름</p>
          <p className="ID ml-5 c-text12 fz-12 mb-30">{props.name}</p>

          <p className="fz-12 fw-bold c-text11 mb-10">생년월일</p>
          <p className="ID ml-5 c-text12 fz-12 mb-25">{props.birth}</p>

          <article className="mb-15">
            <p className="fz-12 fw-bold c-text11 mb-20"></p>
            <div className="gender_box is-flex ">
              <button
                name="female"
                className="button gender_button bg-gray1"
                style={{ display: "none" }}
              >
                <p name="female" className="fz-12 c-white"></p>
              </button>
              <button
                name="male"
                className="button gender_button bg-gray1 gender_B"
                style={{ display: "none" }}
              >
                <p name="male" className="fz-12 c-text11"></p>
              </button>
            </div>
          </article>

          <div className="text-center">
            <p className="fz-14 c-text11 mb-20">
              위 내용의로 수정하시겠습니까?
            </p>
          </div>

          <div className=" is-flex x-between">
            <button
              onClick={() => setVisible("none")}
              className="button cancel bg-gray2"
            >
              <p className="c-white">취소</p>
            </button>
            <button
              onClick={confirmModify}
              className="button revise_2 bg-orange3"
            >
              <p className="c-white">수정하기</p>
            </button>
          </div>
        </div>
      </article>
    );
        

}

export default withRouter(SignUpConfirmModla)
