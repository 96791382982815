import React from 'react'
import { withRouter } from 'react-router-dom'
import logo from '../../../image/publishing_Img/logo_black.png'

function UnregistSplash(props) {

    
    const confirmLocation =()=>{
        props.history.push('/menu')
    }
    return (
        
            <div className="full-height bg-white " onClick={confirmLocation}>
                <div className="wrap-splash" >
                    <div className=" bg-orange2 bar" >
                        <img className="logo_img" alt="랜딩페이지 이미지" src={logo}/>
                    </div>
                   
                 
                    <div className="touch_text">
                        <p style={{width:"110%", color:'gray'}}>
                            그동안 고수의 운전면허를<br/>
                            이용해 주셔서 감사합니다.
                        </p>
                    </div>
                </div>
            </div>
        
    )
}

export default withRouter (UnregistSplash)