import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { url } from '../../../enum'




function TestModals(props) {
    //모달 none/block 샛
    const setVisible=props.setVisible
    ////모달 목적 파악
    let goal=props.isGoal
    
    const [modalView,setModalView]=useState("")

    
    //내가푼문제
    let questionView=props.questionView

    //문항 이동 하기 관련 코드///////////////////////////////////////////////////////////////
    const setQueryNumber=props.setQueryNumber
    const goToQuery=(e)=>{
        //관리자 페이지에 데이터를 1씩 보내자.
        //아이디가있으면 token을 보내고
        if(localStorage.getItem('token')){

            axios.put(url+"/users/actions/previous-exam",{token:localStorage.getItem('token')}).then(res=>console.log(res.data.message))
        }
        setVisible('none')
        setQueryNumber(e.target.value-1)
        
   }
   
    ////////////////////////////////////////////////////////////////////////////////////////
    
    useEffect(() => {
        
        if(goal==="switchQuery"){
            
            setModalView(( 
                <div className="modalWrapper">
                    <div className="modalInner modal_bg">
                        <p>항목을 누르면 <br/>해당문제로 이동합니다</p>
                        <ol style={{overflow:"auto" }}>
                            {questionView.map((value,index)=>
                                <li  key={index} value={index+1} onClick={goToQuery}>
                                   
                                        {index+1}
                                    번문항
                                </li>
                            )}
                        </ol>
                    <button onClick={()=>setVisible('none')}>가리기</button> 
                    </div>
                </div>))

        }

    }, [goal,questionView])
    


    return (
            <div className="modalOverlay" style={{display:props.visible}}>
               {modalView}
            </div>
            )
        

}

export default  withRouter (TestModals)
