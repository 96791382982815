import React, { useEffect, useState } from 'react'
import Iconionic_close_circle_outline_gray from'../../../image/publishing_Img/Iconionic-close-circle-outline-gray.png'
import numberPad from '../WriteTest/numberpad'

function ScheduleModal_1(props) {
    
    let  index=props.index
    const [time,setTime]=useState({start:"",end:""})
    useEffect(() => {

        console.log("시작시간",index.reservationTime)
        console.log("종료시간",index.reservationTimeEnd)

        let startTime=new Date(index.reservationTime)
        startTime=`${startTime.getFullYear()}-${numberPad(startTime.getMonth(),2)}-${numberPad(startTime.getDate(),2)} 
                    ${numberPad(startTime.getHours().toString(),2)}:${numberPad(startTime.getMinutes(),2)}`
        console.log(startTime)
        
        let endTime=new Date(index.reservationTimeEnd)
        endTime=`${endTime.getFullYear()}-${numberPad(endTime.getMonth(),2)}-${numberPad(endTime.getDate(),2)}
                 ${numberPad(endTime.getHours().toString(),2)}:${numberPad(endTime.getMinutes(),2)}`
        console.log(endTime)

        setTime({start:startTime,end:endTime})

  
    }, [index])

    return (
        <div>
             <div style={{display:props.visible_1}} className="modal-calendar">
                <div className="modal-dim"></div>
                <div className="modal-content content bg-white">
                    <div className="header">
                        <p className="fz-20">내 스케줄</p>
                        <button className="close_icon button" onClick={()=>props.setVisible_1('none')}>
                            <img alt="그래이" src={Iconionic_close_circle_outline_gray}/>
                        </button>
                    </div>
                    <table className="schedule">
                        <tr>
                            <th>지점이름</th>
                            <td>{index.branchOfficeName}</td>
                        </tr>
                        <tr>
                            <th>예약 시간</th>
                            <td>{time.start}</td>
                        </tr>
                        <tr>
                            <th>종료 시간</th>
                            <td>{time.end}</td>
                        </tr>
                        <tr>
                            <th>예약 구분</th>
                            <td>{index.type}</td>
                        </tr>
                        <tr>
                            <th>상품 정보</th>
                            <td>{index.productDesc}</td>
                        </tr>
                        <tr>
                            <th>결석여부</th>
                            <td>{index.absence}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ScheduleModal_1
