import React, { useEffect, useState } from 'react'
import {sampleQuestions} from'./TestData'
import SampleTestModalsnew from'./SampleTestModals_new'
import icon_test_img from '../../../image/publishing_Img/icon_test_img.svg'
import Icon_tri_dropleft from'../../../image/publishing_Img/Icon-tri-dropleft.png'
import Icon_tri_dropcenter from'../../../image/publishing_Img/icon-tri-dropcenter.png'
import Icon_tri_dropright from'../../../image/publishing_Img/Icon-tri-dropright.png'
import axios from 'axios'
import { url } from '../../../enum'

 
function SampleTest(props) {
    
    let sample=sampleQuestions.question

    const [types,setTypes]=useState("sentence41")
    
    //기출문제 정보
    const [questionNumber,setQuestionNumber]=useState(0)
    const [questionView,setQuestionView]=useState(sampleQuestions.question)
    
    //모달창 노출/비노출
    const [visible, setVisible]=useState('none')
    //모달창의 목적
    const [isGoal,setIsGoal]=useState("")
   
    
    
    //유형선택
    useEffect(() => {
       
        //유형별 문재가 담기는 곳
        let queryArray=[]   
        
        if(types==="sentence41"){
            for(let i =0; i<sample.length;i++){
                //문장형 1지선다
                console.log(sample[i]);
                if(sample[i].type.includes("문장형")&&sample[i].right.length===1){
                    queryArray.push(sample[i])
                }
            }
            setQuestionView(queryArray)
            

        }else if(types==="sentence42"){

            for(let i=0; i<sample.length; i++){
                if(sample[i].type.includes("문장형")&&sample[i].right.length===2){
                    queryArray.push(sample[i])
                }
            }
            setQuestionView(queryArray)
        }else if(types==="poto51"){
            for(let i=0; i<sample.length; i++){
                if(sample[i].type.includes("사진형")){
                    queryArray.push(sample[i])
                }
            }
            setQuestionView(queryArray)
        }else if(types==="illust52"){
            for(let i=0; i<sample.length; i++){
                if(sample[i].type.includes("일러스트")){
                    queryArray.push(sample[i])
                }
            }
            setQuestionView(queryArray)
        }else if(types==="safetySign"){
            for(let i=0; i<sample.length; i++){
                if(sample[i].type.includes("안전표지")){
                    queryArray.push(sample[i])
                }
            }
            setQuestionView(queryArray)
        }else if(types==="video412"){
            for(let i=0; i<sample.length; i++){
                if(sample[i].type.includes("동영상")){
                    queryArray.push(sample[i])
                }
            }
            setQuestionView(queryArray)
        }
        
    }, [types])
    //정답
    useEffect(() => {
        
        let answerBox=document.getElementsByName('answerBox')
        let number=document.getElementsByName('number')
        let branches=document.getElementsByName('branches')

        //정답체크 풀기
        for(let i=0; i<answerBox.length; i++){
            answerBox[i].className="answer_box"
        }
        for(let i=0; i<number.length; i++){
            number[i].className="c-text2 number_box"
        }
        for(let i=0; i<branches.length;i++){
            branches[i].className="ml-10 fz-14 lh-14 c-text2"
        }
        for(let i=0; i<questionView[questionNumber].right.length; i++){
            let index=questionView[questionNumber].right[i]
            number[index-1].className="c-white number_box"
            answerBox[index-1].className="answer_box bg-orange2"
            branches[index-1].className="ml-10 fz-14 lh-14 c-white"
        }
   
    }, [questionView,questionNumber,types])


    //내가 풀었던 문제만 다음으로 넘어 갈 수 있다.
    const ChangeView=(where)=>{
        if(where==='back'){

            //이전
            if((questionNumber+1)===1){
                return alert("첫문제 입니다.")
            }
            setQuestionNumber(questionNumber-1)    
            
        }else if(where==='next'){

            if((questionNumber+1)>=questionView.length){

                return alert("마지막 문제입니다.")
            }
            
            setQuestionNumber(questionNumber+1)    
        }

        //관리자 페이지에 데이터를 1씩 보내자.
        //아이디가있으면 token을 보내고
        if(localStorage.getItem('token')){

            axios.put(url+"/users/actions/previous-exam",{token:localStorage.getItem('token')}).then(res=>console.log(res.data.message))
        }
    }

    const switchQuery=()=>{

        setIsGoal("switchQuery")
        setVisible('block')
        
    }
    const styleRemove=(type)=>{
        for(let i=0; i<type.length; i++){
            if(i%2===0){
                type[i].className="bg-gray1"
            }
            if(i%2!==0){
                type[i].className="c-text14"
            }
        }
    }

   const switchType=(e)=>{
        let qt=e.target.getAttribute("qt")
        setTypes(qt)
        setQuestionNumber(0)
        let type=document.getElementsByName('type')

        if(qt==="sentence41"){
            styleRemove(type)
            type[0].className="bg-orange3"
            type[1].className="c-white"
        }else if(qt==="sentence42"){
            styleRemove(type)

            type[2].className="bg-orange3"
            type[3].className="c-white"

        }else if(qt==="poto51"){
            styleRemove(type)

            type[4].className="bg-orange3"
            type[5].className="c-white"

        }else if(qt==="illust52"){

            styleRemove(type)

            type[6].className="bg-orange3"
            type[7].className="c-white"
        }else if(qt==="safetySign"){
            styleRemove(type)

            type[8].className="bg-orange3"
            type[9].className="c-white"

        }else if(qt==="video412"){

            styleRemove(type)

            type[10].className="bg-orange3"
            type[11].className="c-white"
        }

        
    }
    
   
   
    return (
        <div>
            <header className="gosu-header-relative">
                <div className="gosu-header is-flex y-center x-center bg-white">
                    <label onClick={()=>props.history.goBack()} className="back_1">
                        <p className="fz-14 c-orange2">이전</p>
                    </label>
                    <p className="c-orange2 fz-16 fw-bold">기출문제 전력질주!</p>
                </div>
            </header>

            <article className="wrap-main-speed_test-1 bg-white">
                <table className="gnb_top">
                    <tbody>
                        <tr>
                            <td className="bg-orange3" onClick={switchType} name="type" qt="sentence41">
                                <p className="c-white" name="type" qt="sentence41"  >문장형<br/>(4-1)</p>
                            </td>
                            <td className="bg-gray1" onClick={switchType} name="type" qt="sentence42">
                                <p className="c-text14" name="type" qt="sentence42">문장형<br/>(4-2)</p>
                            </td>
                            <td className="bg-gray1" onClick={switchType} name="type" qt="poto51">
                                <p className="c-text14" name="type" qt="poto51">사진형<br/>(5-2)</p>
                            </td>
                            <td className="bg-gray1" onClick={switchType} name="type" qt="illust52">
                                <p className="c-text14" name="type" qt="illust52">일러스트<br/>(5-2)</p>
                            </td>
                            <td className="bg-gray1" onClick={switchType} name="type" qt="safetySign">
                                <p className="c-text14" name="type" qt="safetySign">안전표지<br/>(4-1)</p>
                            </td>
                            <td className="bg-gray1" onClick={switchType} name="type" qt="video412">
                                <p className="c-text14" name="type" qt="video412">동영상<br/>(4-1,2)</p>
                            </td>
                        </tr>
                    </tbody>
                
                </table>
            
            
                <SampleTestModalsnew
                    visible={visible}
                    setVisible={setVisible}
                    questionView={questionView}
                    isGoal={isGoal}
                    setQueryNumber={setQuestionNumber}
                    type={types}
                    questionNumber={questionNumber}
                />
                <div className="exam_name">
                    <img alt="시험이미지" src={icon_test_img}/>
                    <p className="fz-16 c-white">
                        {questionView[questionNumber].type} {' '}
                        {questionView[questionNumber].branches.length}지 {questionView[questionNumber].right.length}답 ({questionView[questionNumber].score}점)
                    </p>
                </div>

            
                <div className="exam_box">
                    <div className="mx-20 question">
                        <h1 className="c-orange2">{questionNumber+1}</h1>
                        <p className="c-text2">
                        {questionView[questionNumber].query} {' '}
                        <br/>
                        {questionView[questionNumber].right.length>1?`[정답을${questionView[questionNumber].right.length}개를 선택해주세요]`:``}
                        
                        </p>
                        {/* <img alt={questionView[questionNumber].queryImg?"문재이미지":null} src={questionView[questionNumber].queryImg}/> */}
                    </div>
                    <div className="imageBox"> 
                        
                 
                        {questionView[questionNumber].queryImg!==""?
                            questionView[questionNumber].queryImg.includes("mp4")?
                                <video playsInline autoPlay loop muted id="embed"     title="문제동영상"  src={require(`../../../image/examMedia/${questionView[questionNumber].queryImg}`).default} key={questionView[questionNumber].queryImg}/>
                                :
                               
                                <img id="image" alt="문제이미지" src={require(`../../../image/examMedia/${questionView[questionNumber].queryImg}`).default} key={questionView[questionNumber].queryImg} />
                               
                                :
                                ""
                        }
                       
                    </div>
                </div>
            
                
                <div className="answer_group">
                        {questionView[questionNumber].branches.map((value,index)=>
                            <label key={index} className="answer_box"  id={index+1+"select"} name="answerBox" htmlFor={index+1}>
                                <p name="number" className="number_box c-text2">
                                   {index+1}
                                   
                                </p>
                                <p name="branches" className="ml-10 fz-14 lh-14 c-text2">
                                    {value.word}    
                                    {value.img===""?"":<img style={{width:"80px",height:'80px'}} alt={value.img?"보기이미지":null} src={require(`../../../image/examMedia/${value.img}`).default}/>   }
                                    
                                </p>
                            </label>
                        )}
                </div>
                <div  className="explanation">
                    <p className="c-text11 fz-12">해설</p>

                    <div className="bg-gray1 grary_box" >
                        <p className="c-text1 fz-14 fw-bold">정답 : {questionView[questionNumber].right.map((value)=>
                                value+"번 "
                                )}
                        </p>
                        <p className="c-text1 fz-12 lh-14">
                            {questionView[questionNumber].explanation}
                        </p>
                        <div style={{textAlign:'center'}}>
                            {
                                questionView[questionNumber].explanationMedia!==""? 
                                
                                <img alt="해설사진" style={{width:"40%",height:"40%",display:'inline-block'}} src={require(`../../../image/examMedia/${questionView[questionNumber].explanationMedia}`).default}/>
                                :
                                ""
                            }
                        </div>
                    </div>
                </div>
            </article>
            <footer className="gnb-exam">
                <label onClick={()=>ChangeView("back")} className="ex-left">
                    <img  alt="아이콘" src={Icon_tri_dropleft}/>
                    <p>{'<'}이전 문항</p> 
                </label>
                <label onClick={switchQuery} className="ex-center">
                     <img alt="아이콘" src={Icon_tri_dropcenter}/>
                    <p >
                        문항이동
                        {questionNumber+1}/{questionView.length}
                    </p>
                    
                </label>
                <label onClick={()=>ChangeView("next")}  className="ex-right">
                    <img   alt="아이콘" src={Icon_tri_dropright}/>
                    <p >이후{'>'}</p>
                </label>
            </footer>  
        </div>
    )
}

export default SampleTest