import React from 'react'
import { withRouter } from 'react-router-dom';
import Icon_logo_youtube_purple from'../../../image/publishing_Img/Icon-logo-youtube_purple.png'
import road_with_broken_line_purple from'../../../image/publishing_Img/road-with-broken-line_purple.png'



function DrivingTestInfo(props) {
   
   let where= props.location.state.where
    const goBack=()=>{
        props.history.goBack();
    }


    const goPushDriveTestInfo=(e,category,testInfo)=>{
        
        props.history.push('/driveTestInfo',
        {where:props.location.state.where,category:category,testInfo:testInfo})
    }
    
    return (
        <div className="">
               <header className="gosu-header-relative">
                <div className="gosu-header is-flex y-center x-center bg-purple2">
                    <label className="back_1">
                        <p   onClick={goBack} className="fz-14 c-white">이전</p>
                    </label>
                    <p className="fz-16 fw-bold c-white">{where.centerName}</p>
                </div>
            </header>
            <article className="wrap-test_place_video–2 bg-gray1" >
                <ul className="exam_type"> 

                {where.testInfo.typeOneTwoNormalFunctionalTest.length>=0?
                      <li className="type_name" onClick={(e)=>goPushDriveTestInfo(e,e.target.textContent,where.testInfo.typeOneTwoNormalFunctionalTest)} >
                      <div className="left_img">
                          <img alt="유튜브 아이콘" className="youtube_img" src={Icon_logo_youtube_purple}/>
                      </div>
                      <p className="c-text11 fz-16">
                          1,2종 보통 기능시험
                      </p>
                  </li>:""}
                {where.testInfo.perpendicularParking.length>=0?
                    <li className="type_name" onClick={(e)=>goPushDriveTestInfo(e,e.target.textContent,where.testInfo.perpendicularParking)} >
                        <div className="left_img">
                        <img alt="아이콘" className="youtube_img" src={Icon_logo_youtube_purple}/>
                        </div>
                        <p className="c-text11 fz-16" style={{lineHeight:'1.2'}}>
                           1,2종 보통 직각주차, 가속코스, <br/> 상세 진행요령
                        </p>
                    </li>:""}  
                {/* {where.testInfo.typeOneLargeFunctionalTest.length>=0?
                    <li className="type_name" onClick={(e)=>goPushDriveTestInfo(e,e.target.textContent,where.testInfo.typeOneLargeFunctionalTest)} >
                        <div className="left_img">
                        <img alt="아이콘" className="youtube_img" src={Icon_logo_youtube_purple}/>
                        </div>
                        <p className="c-text11 fz-16">
                            1종 대형 기능시험
                        </p>
                    </li>:""} */}

                {/* {where.testInfo.typeTwoSmallAndMotorFunctionTest.length>=0?
                 <li className="type_name" onClick={(e)=>goPushDriveTestInfo(e,e.target.textContent,where.testInfo.typeTwoSmallAndMotorFunctionTest)} >
                    <div className="left_img">
                        <img alt="아이콘" className="youtube_img" src={Icon_logo_youtube_purple}/>
                    </div>
                    <p className="c-text11 fz-16">
                        2종 소형 및 원동기 기능시험
                    </p>
                </li>:""} */}

                {where.testInfo.roadDrivingA.length>=0?
                    <li className="type_name" onClick={(e)=>goPushDriveTestInfo(e,e.target.textContent,where.testInfo.roadDrivingA)} >
                        <div className="left_img">
                        <img alt="아이콘" className="youtube_img" src={road_with_broken_line_purple}/>
                        </div>
                        <p className="c-text11 fz-16">
                            
                        도로주행 A
                        </p>
                    </li>:""}
                {where.testInfo.roadDrivingB.length>=0?
                    <li className="type_name" onClick={(e)=>goPushDriveTestInfo(e,e.target.textContent,where.testInfo.roadDrivingB)} >
                        <div className="left_img">
                        <img alt="아이콘" className="youtube_img" src={road_with_broken_line_purple}/>
                        </div>
                        <p className="c-text11 fz-16">
                            
                        도로주행 B
                        </p>
                    </li>:""}
                {where.testInfo.roadDrivingC.length>=0?
                    <li className="type_name" onClick={(e)=>goPushDriveTestInfo(e,e.target.textContent,where.testInfo.roadDrivingC)} >
                        <div className="left_img">
                        <img alt="아이콘" className="youtube_img" src={road_with_broken_line_purple}/>
                        </div>
                        <p className="c-text11 fz-16">
                            
                        도로주행 C
                        </p>
                    </li>:""}
                {where.testInfo.roadDrivingD.length>=0?
                    <li className="type_name" onClick={(e)=>goPushDriveTestInfo(e,e.target.textContent,where.testInfo.roadDrivingD)} >
                        <div className="left_img">
                        <img alt="아이콘" className="youtube_img" src={road_with_broken_line_purple}/>
                        </div>
                        <p className="c-text11 fz-16">
                            
                        도로주행 D
                        </p>
                    </li>:""}
                {/* {where.testInfo.recoveryCar.length>=0?
                   <li className="type_name" onClick={(e)=>goPushDriveTestInfo(e,e.target.textContent,where.testInfo.recoveryCar)} >
                        <div className="left_img">
                        <img alt="아이콘" className="youtube_img" src={Icon_logo_youtube_purple}/>
                        </div>     
                        <p className="c-text11 fz-16">
                            
                        구난차(레커) 기능시험
                        </p>                   
                    </li>:""} */}
                {/* {where.testInfo.smallTowing.length>=0?
                    <li className="type_name" onClick={(e)=>goPushDriveTestInfo(e,e.target.textContent,where.testInfo.smallTowing)} >
                        <div className="left_img">
                        <img alt="아이콘" className="youtube_img" src={Icon_logo_youtube_purple}/>
                        </div>
                        <p className="c-text11 fz-16">
                            
                        소형견인(캠핑카) 기능시험
                        </p>
                    </li>:""} */}
                {/* {where.testInfo.largeTowing.length>=0?
                  <li className="type_name" onClick={(e)=>goPushDriveTestInfo(e,e.target.textContent,where.testInfo.largeTowing)} >
                    <div className="left_img">
                    <img alt="아이콘" className="youtube_img" src={Icon_logo_youtube_purple}/>
                    </div>
                    <p className="c-text11 fz-16">
                        
                    대형견인(트레일러) 기능시험
                    </p>
                </li>:""} */}
                {/* {where.testInfo.anotherMotor.length>=0?
                     <li className="type_name" onClick={(e)=>goPushDriveTestInfo(e,e.target.textContent,where.testInfo.anotherMotor)} >
                        <div className="left_img">
                        <img alt="아이콘" className="youtube_img" src={Icon_logo_youtube_purple}/>
                        </div>
                        <p className="c-text11 fz-16">
                            
                        다륜원동기 기능시험
                        </p>
                    </li>:""} */}
                </ul>
            </article>
        </div>
    )
}

export default withRouter( DrivingTestInfo)
