import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { url } from '../../../enum'


function DetailTab(props) {
    const [roadVideo,setRoadVideo]=useState([])
    useEffect(() => {
        function getVideos() {
           
            axios.get(url+"/videos/drive").then(res=>
                setRoadVideo(res.data.rows)    
            )
        }
        getVideos()
    }, [])
    const [view,setView]=useState((
        <div className="guide_text_box bg-white">
            <h2 className="mb-15">면허종별</h2>
            <p className="mb-20">· 1종 보통 / 2종 보통 (수동, 자동)</p>
            <h2 className="mb-15">준비물</h2>
            <p className="mb-10">· 온라인 접수 또는 현장 예약 접수</p>
            <p className="mb-25">· 시험 당일 연습면허 발급,부착된 응시원허, 신분증 지참</p>
            <h2 className="mb-10">합격기준</h2>
            <p className="mb-25">· 70점 이상 시 합격</p>
            <h2 className="mb-10">수수료</h2>
            <p className="mb-20">· 25,000원</p>
            <h2 className="mb-20">시험 코스</h2>
            <p className="lh-14 mb-15">· 총 연장거리 5km 이상인 4개 코스 중 추첨을 통한 1개 코스 선택</p>
            <p className="mb-20">· 내비게이션 음성 길 안내</p>
            <h2 className="mb-15">시험 내용</h2>
            <p className="lh-14 mb-20">· 긴급자동차 양보, 어린이보호구역, 지정 속도 위반 등 안전 운전에 필요한 57개 항목을 평가</p>
            <h2 className="mb-10">실격기준</h2>
            <p className="lh-15 mb-10">· 3회 이상 “출발 불능” , “클러치 조작 불량으로 인한 엔진
                정지”, “급브레이크 사용”, “급조작·급출발” 또는 그 밖의
                사유로 운전능력이 현저하게 부족한 것으로 인정할 수 있
                는 행위를 한 경우</p>
            <p className="lh-15 mb-5">· 안전거리 미확보나 경사로에서 뒤로 1미터 이상 밀리는 현
                상 등 운전능력 부족으로 교통사고를 일으킬 위험이 현저
                한 경우 또는 교통사고를 야기한 경우</p>
            <p className="lh-15 mb-10">· 음주, 과로, 마약, 대마 등 약물의 영향 이나 휴대전화 사용
                등으로 정상적으로 운전하지 못할 우려가 있거나 교통안
                전과 소통을 위한 시험관의 지시 및 통제에 불응한 경우
            </p>
            <p className="mb-10">· 신호 또는 지시에 따르지 않은 경우</p>
            <p className="lh-15 mb-5">· 보행자 보호의무 등을 소홀히 한 경우</p>
            <p className="lh-15 mb-5">· 어린이 보호구역, 노인 및 장애인 보호구역에 지정되어 있는 최고 속도를 초과한 경우</p>
            <p className="lh-15 mb-10">· 도로의 중앙으로부터 우측 부분을 통행하여야 할 의무를 위반한 경우</p>
            <p className="lh-15 mb-5">· 법령 또는 안전표지 등으로 지정되어 있는 최고 속도를 
                시속 10킬로미터 초과한 경우</p>
            <p className="lh-15 mb-5">· 긴급자동차의 우선통행 시 일시정지하거나 진로를 양보하
                지 않은 경우</p>
            <p className="lh-15 mb-10">· 어린이 통학버스의 특별보호의무를 위반한 경우</p>
            <p className="mb-15">· 시험시간 동안 좌석안전띠를 착용하지 않은 경우</p>
            <h2 className="lh-15 mb-10">주의사항</h2>
            <p className="lh-15 mb-5">· 연습운전면허 유효기간(발급일로부터 1년) 이내 도로주행
                시험에 합격하여야 함.</p>
            <p className="lh-15 mb-20">· 연습운전면허 유효기간이 지났을 경우, 도로주행시험 접수
                가 불가능하며 학과시험, 기능 시험에 재응시하여야 함.
                <span>(응시 전 교통안전교육은 재수강 불필요)</span>
            </p>
            <h2 className="mb-10">외국어 음성 지원 서비스 (코스안내) 제공</h2>
            <p>· 영어</p>
        </div>
             ))
    
    const menuChange=(e)=>{
        let inputs=document.getElementsByTagName('input')
        for(let i=0; i<inputs.length; i++){
            inputs[i].className="button button-1 bg-white c-text1"
        }
        
        if(e.target.id==="1"){
            setView((
                <div className="guide_text_box bg-white">
                    <h2 className="mb-15">면허종별</h2>
                    <p className="mb-20">· 1종 보통 / 2종 보통 (수동, 자동)</p>
                    <h2 className="mb-15">준비물</h2>
                    <p className="mb-10">· 온라인 접수 또는 현장 예약 접수</p>
                    <p className="mb-25">· 시험 당일 연습면허 발급,부착된 응시원허, 신분증 지참</p>
                    <h2 className="mb-10">합격기준</h2>
                    <p className="mb-25">· 70점 이상 시 합격</p>
                    <h2 className="mb-10">수수료</h2>
                    <p className="mb-20">· 25,000원</p>
                    <h2 className="mb-20">시험 코스</h2>
                    <p className="lh-14 mb-15">· 총 연장거리 5km 이상인 4개 코스 중 추첨을 통한 1개 코스 선택</p>
                    <p className="mb-20">· 내비게이션 음성 길 안내</p>
                    <h2 className="mb-15">시험 내용</h2>
                    <p className="lh-14 mb-20">· 긴급자동차 양보, 어린이보호구역, 지정 속도 위반 등 안전 운전에 필요한 57개 항목을 평가</p>
                    <h2 className="mb-10">실격기준</h2>
                    <p className="lh-15 mb-10">· 3회 이상 “출발 불능” , “클러치 조작 불량으로 인한 엔진
                        정지”, “급브레이크 사용”, “급조작·급출발” 또는 그 밖의
                        사유로 운전능력이 현저하게 부족한 것으로 인정할 수 있
                        는 행위를 한 경우</p>
                    <p className="lh-15 mb-5">· 안전거리 미확보나 경사로에서 뒤로 1미터 이상 밀리는 현
                        상 등 운전능력 부족으로 교통사고를 일으킬 위험이 현저
                        한 경우 또는 교통사고를 야기한 경우</p>
                    <p className="lh-15 mb-10">· 음주, 과로, 마약, 대마 등 약물의 영향 이나 휴대전화 사용
                        등으로 정상적으로 운전하지 못할 우려가 있거나 교통안
                        전과 소통을 위한 시험관의 지시 및 통제에 불응한 경우
                    </p>
                    <p className="mb-10">· 신호 또는 지시에 따르지 않은 경우</p>
                    <p className="lh-15 mb-5">· 보행자 보호의무 등을 소홀히 한 경우</p>
                    <p className="lh-15 mb-5">· 어린이 보호구역, 노인 및 장애인 보호구역에 지정되어 있는 최고 속도를 초과한 경우</p>
                    <p className="lh-15 mb-10">· 도로의 중앙으로부터 우측 부분을 통행하여야 할 의무를 위반한 경우</p>
                    <p className="lh-15 mb-5">· 법령 또는 안전표지 등으로 지정되어 있는 최고 속도를 
                        시속 10킬로미터 초과한 경우</p>
                    <p className="lh-15 mb-5">· 긴급자동차의 우선통행 시 일시정지하거나 진로를 양보하
                        지 않은 경우</p>
                    <p className="lh-15 mb-10">· 어린이 통학버스의 특별보호의무를 위반한 경우</p>
                    <p className="mb-15">· 시험시간 동안 좌석안전띠를 착용하지 않은 경우</p>
                    <h2 className="lh-15 mb-10">주의사항</h2>
                    <p className="lh-15 mb-5">· 연습운전면허 유효기간(발급일로부터 1년) 이내 도로주행
                        시험에 합격하여야 함.</p>
                    <p className="lh-15 mb-20">· 연습운전면허 유효기간이 지났을 경우, 도로주행시험 접수
                        가 불가능하며 학과시험, 기능 시험에 재응시하여야 함.
                        <span>(응시 전 교통안전교육은 재수강 불필요)</span>
                    </p>
                    <h2 className="mb-10">외국어 음성 지원 서비스 (코스안내) 제공</h2>
                    <p>· 영어</p>
                </div>
            ))
        }else if(e.target.id==="2"){

           
            setView((

                <div className="guide_text_box bg-white">
                    <h2 className="mb-20">도로주행 시험안내 동영상</h2>
                    {roadVideo.map((value,index)=>
                    <div key={value.id} className="video_box">
                        <iframe 
                        className="iframe_1 mb-5"
                        src={value.link}
                        title={value.title}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen/>

                        <p className="c-text1 fz-12 mb-20">{value.title}</p>
                    </div>
                )}
                </div>
            ))
        }
        
        e.currentTarget.className="button button-1 c-white bg-orange2"
            
        }
        return (
            <>
            <div className="wrap_gnb_test_info–2 bg-gray1">
                <div className="is-flex x-between mx-20 mb-20">
                    <input onClick={menuChange} id="1"  type="button" value="도로주행 시험"
                    className="button button-1 c-white bg-orange2"/>
    
                    <input onClick={menuChange} id="2" type="button" value="시험안내 동영상"
                    className=" button button-1 bg-white c-text1"/>
                </div>
                {view}    
            </div>
            </>
        )
    }
   


export default DetailTab
