import React, { useEffect, useState } from "react";
import FunctionalDrivingChild from "./FunctionalDrivingChild";
import FunctionalLocationChild from "./FunctionalLocationChild";
import axios from "axios";
import { url } from "../../../enum";
import img_warning_label from "../../../image/publishing_Img/img_warning_label.svg";
function FunctionalTabs(props) {
  const menuName = props.menuName;
  const [driveindex] = useState(["on","off","off"]);
  const [locationindex] = useState([
    "on",
    "off",
    "off",
    "off",
    "off",
    "off",
    "off",
    "off",
    "off",
  ]);
  const [videos, setVideos] = useState(<FunctionalDrivingChild menuName="4K" />);
  const [locations, setLocations] = useState(
    <FunctionalLocationChild menuName={"전체"} />
  );
  const [videotype, setvideotype] = useState([]);
  const [citytype, setcitytype] = useState([]);

  function getCourses() {
    axios
      .get(url + "/videos/citytype")
      .then((res) => setcitytype(res.data.rows));
    axios.get(url + "/videos/videotype").then((res) => {
      setvideotype(res.data.rows);
    });
  }

  const drivechange = (e, category, name) => {
    console.log(category, name);
    drivepage(category);
    setVideos(<FunctionalDrivingChild menuName={name} />);
  };
  const drivepage = function (category) {
    driveEffect(category);
  };
  const driveEffect = function (number) {
    let menu = document.getElementsByName("videos");
    for (let i = 0; i < menu.length; i++) {
      if (i == number) {
        menu[i].className = "on";
      } else {
        menu[i].className = "off";
      }
      driveindex[i] = menu[i].className;
    }
  };
  const locationchange = (e, lc) => {
    locationpage(lc);
    switch (lc) {
      case "1":
        return setLocations(<FunctionalLocationChild menuName={"전체"} />);
      case "2":
        return setLocations(<FunctionalLocationChild menuName={"서울"} />);
      case "3":
        return setLocations(<FunctionalLocationChild menuName={"경기/인천"} />);
      case "4":
        return setLocations(<FunctionalLocationChild menuName={"부산/울산"} />);
      case "5":
        return setLocations(<FunctionalLocationChild menuName={"대구/경북"} />);
      case "6":
        return setLocations(<FunctionalLocationChild menuName={"광주/전라"} />);
      case "7":
        return setLocations(
          <FunctionalLocationChild menuName={"대전/세종/충청"} />
        );
      case "8":
        return setLocations(<FunctionalLocationChild menuName={"강원/제주"} />);
      default:
        break;
    }
  };
  const locationEffect = function (number) {
    let menu = document.getElementsByName("locationplace");
    for (let i = 0; i < menu.length; i++) {
      if (i == number - 1) {
        menu[i].className = "on";
      } else {
        menu[i].className = "off";
      }
      locationindex[i] = menu[i].className;
    }
  };
  useEffect(() => {
    getCourses()
    for (var i = 0; i < videotype.length; i++) {
      if (i == 0) driveindex[i] = "on";
      else {
        driveindex[i] = "off";
      }
    }

    return () => {};
  }, []);
  const locationpage = function (category) {
    locationEffect(category);
  };
var jv="$(document).ready(function() {$('#play-video').on('click', function(ev) { $('#video')[0].src += '&autoplay=1';  ev.preventDefault();});});"
const count =0  
  if (menuName === "추천") {
    return (
      <div>
        <p className="text11 fz-14 fw-bold mb-20"></p>
        <table className="top_table">
          <tbody>
           
              {
              videotype.map((value, index) => (
                <td
                  key={index}
                  name="videos"
                  onClick={(e) => drivechange(e, index, value.name)}
                  className={driveindex[index]}
                >
                  <p> {value.name} </p>
                  </td>
                  
              ))}
            
          </tbody>
        </table>
        {videos}
      </div>
    );
  } else if (menuName === "시험장") {
    return (
      <div>
        <p className="fz-14 fw-bold mb-20"></p>
        <table className="top_table">
          <thead>
            <tr>
              <td
                name="locationplace"
                className={locationindex[0]}
                onClick={(e) => locationchange(e, "1")}
              >
                <p>전체</p>
              </td>
              <td
                name="locationplace"
                className={locationindex[1]}
                onClick={(e) => locationchange(e, "2")}
              >
                <p>서울</p>
              </td>
              <td
                name="locationplace"
                className={locationindex[2]}
                onClick={(e) => locationchange(e, "3")}
              >
                <p>경기/인천</p>
              </td>
            </tr>
            <tr>
              <td
                name="locationplace"
                className={locationindex[3]}
                onClick={(e) => locationchange(e, "4")}
              >
                <p>부산/울산</p>
              </td>
              <td
                name="locationplace"
                className={locationindex[4]}
                onClick={(e) => locationchange(e, "5")}
              >
                <p>대구/경북</p>
              </td>
              <td
                name="locationplace"
                className={locationindex[5]}
                onClick={(e) => locationchange(e, "6")}
              >
                <p>광주/전라</p>
              </td>
            </tr>
            <tr>
              <td
                name="locationplace"
                className={locationindex[6]}
                onClick={(e) => locationchange(e, "7")}
              >
                <p>대전/세종/충청</p>
              </td>
              <td
                name="locationplace"
                className={locationindex[7]}
                onClick={(e) => locationchange(e, "8")}
              >
                <p>강원/제주</p>
              </td>
            </tr>
          </thead>
        </table>
        {locations}

      </div>
    );
  }
}

export default FunctionalTabs;
