import React from 'react'
import { withRouter } from 'react-router-dom'

import Icon_ionic_ios_arrow_forward from'../../../image/publishing_Img/Icon-ionic-ios-arrow-forward.png'


function TotalTestDetailInfomation(props) {
    
    const goToDetailInfo=(e,where)=>{
        props.history.push('/detailInfo',{menuNum:where})

    }
    
    return (
        <div className="">
           
           <div className="inpormation_list mb-20">
                    <label  onClick={(e)=>goToDetailInfo(e,1)} className=" list_bar">
                        <p className="fz-16 c-text11">면허취득 절차</p>
                        <img alt="사진"  src={Icon_ionic_ios_arrow_forward}/>
                    </label>
                    <label onClick={(e)=>goToDetailInfo(e,2)} className=" list_bar">
                        <p className="fz-16 c-text11">시험자격 / 결격 / 면제</p>
                        <img alt="사진" src={Icon_ionic_ios_arrow_forward}/>
                    </label>
                    <label onClick={(e)=>goToDetailInfo(e,3)} className=" list_bar">
                        <p className="fz-16 c-text11">응시 전 교통안전교육</p>
                        <img alt="사진" src={Icon_ionic_ios_arrow_forward}/>
                    </label>
                    <label onClick={(e)=>goToDetailInfo(e,4)} className=" list_bar">
                        <p className="fz-16 c-text11">학과 시험</p>
                        <img alt="사진" src={Icon_ionic_ios_arrow_forward}/>
                    </label>
                    <label onClick={(e)=>goToDetailInfo(e,5)} className=" list_bar">
                        <p className="fz-16 c-text11">기능 시험</p>
                        <img alt="사진" src={Icon_ionic_ios_arrow_forward}/>
                    </label>
                    <label onClick={(e)=>goToDetailInfo(e,6)} className=" list_bar">
                        <p className="fz-16 c-text11">연습면허 발급</p>
                        <img alt="사진" src={Icon_ionic_ios_arrow_forward}/>
                    </label>
                    <label onClick={(e)=>goToDetailInfo(e,7)} className=" list_bar">
                        <p className="fz-16 c-text11">도로주행 시험</p>
                        <img alt="사진" src={Icon_ionic_ios_arrow_forward}/>
                    </label>
                    <label onClick={(e)=>goToDetailInfo(e,8)} className=" list_bar">
                        <p className="fz-16 c-text11">운전면허증 발급</p>
                        <img alt="사진" src={Icon_ionic_ios_arrow_forward}/>
                    </label>
                    <label onClick={(e)=>goToDetailInfo(e,9)} className=" list_bar">
                        <p className="fz-16 c-text11">면허시험 준비물 가이드</p>
                        <img alt="사진" src={Icon_ionic_ios_arrow_forward}/>
                    </label>

                </div>
            
        </div>
    )
}

export default withRouter(TotalTestDetailInfomation)
