import React, { useEffect, useState } from "react";
import axios from "axios";
import { url } from "../../../enum";
import faroad from "../../../image/publishing_Img/faroad.png";

function FunctionalCourseLandingpage(props) {
  const [course, setcourse] = useState([]);

  const search = props.location.search; // returns the URL query String
  const params = new URLSearchParams(search);
  const menuName = params.get("menuName");

  const returnBack = () => {
    props.history.goBack();
  };
  function getCourses() {
    axios
      .get(url + "/videos/course?branch=" + menuName)
      .then((res) => setcourse(res.data.rows));
  }
  useEffect(() => {
    getCourses();
    return () => { };
  }, []);
  if (localStorage.getItem("favorite")) {
  }
  const twhite = { color: "#ffffff" };
  const bpurple = { background: "#4C1130" };
  const dmargin = {
    marginBottom:
      '5px',
    minHeight: '4.5vh'
  };
  const ipadding = { paddingBottom: "1.2vh" };

  return (
    <div className="startDriveVideo full-height bg-gray1">
      <header className="gosu-header-relative">
        <div
          className="gosu-header is-flex y-center x-center bg-white"
          style={bpurple}
        >
          <label className="back_1">
            <p onClick={returnBack} className="fz-14 c-purple2" style={twhite}>
              이전
            </p>
          </label>
          <p className="fz-16 fw-bold c-purple2" style={twhite}>
            {menuName}
          </p>
        </div>
      </header>
      <article className="wrap-test_place_video–2">
        <div className="test_place_list ">
          <ul>
            {course.length != 0 ? (
              course.map((value, index) => (
                <a
                  href={
                    "locationvideo?branch=" + menuName + "&course=" + value.name
                  }
                  key={value.idx}
                >
                  <li className="list_box bg-white" style={dmargin}>
                    <div className="right_icons is-flex">
                      <label>
                        <div>
                          <img
                            alt="아이콘"
                            className="zocial_www"
                            src={faroad}
                            style={ipadding}
                          />
                        </div>
                      </label>
                      <p className="fz-16 c-text11 lh-15">{value.name}</p>
                    </div>
                  </li>
                </a>
              ))
            ) : (
              <p className="fz-14 fw-bold mb-20">
                최신 영상을 준비 중에 있습니다.
              </p>
            )}
          </ul>
        </div>
      </article>
    </div>
  );
}

export default FunctionalCourseLandingpage;
