import React, { useEffect, useState } from 'react'
import axios from'axios'
import handle from'../../../image/publishing_Img/handle.png'
import video from'../../../image/publishing_Img/video.png'
import side_bar from '../../../image/publishing_Img/side_bar.png'
import logo_white from'../../../image/publishing_Img/logo_white.png'
import icon_kakao from'../../../image/publishing_Img/icon_kakao.png'
import icon_gosu_phone from '../../../image/publishing_Img/icon_gosu_phone.png'
import Icon_feather_openBook from '../../../image/publishing_Img/Icon-feather-book-open.svg'
import Icon_feather_openBook2 from '../../../image/publishing_Img/Icon-feather-book-open_2.svg'
import Icon_feather_search from '../../../image/publishing_Img/Icon-feather-search.svg'
import Icon_feather_search2 from '../../../image/publishing_Img/Icon-feather-search_2.svg'
import Icon_feather_settings from'../../../image/publishing_Img/Icon-feather-settings.svg'
import Icon_feather_settings2 from'../../../image/publishing_Img/Icon-feather-settings_2.svg'
import icon_Calendar from'../../../image/publishing_Img/icon_Calendar.png'
import ScheduleLogInModal from './ScheduleLogInModal'
import { url } from '../../../enum'



function MenuPage(props) {
    //모의고사 문제 지우기
    localStorage.removeItem('numbers')
    let guest={
        mockTest:[]
    }
    
    const [loginMenu,setLoginMenu]=useState("")
    const [userName,setUserName]=useState("")
    const [banners,setBanners]=useState("")
    const [logoutState,setLogoutState]=useState("")
    const [visible,setVisible]=useState('none')
   
    
    useEffect(() => {
        //로그인 되어있는지 안되어있는지 확인
        if(localStorage.getItem('token')){
            axios.post(url+"/users/is-logged-in",{token:localStorage.getItem('token')}).then(res=>{
                if(res.data.resCode!==0){
                    localStorage.removeItem('userInfo')
                    localStorage.removeItem('token')
                    localStorage.removeItem('schedule')
                    setUserName("guest")
                    setLoginMenu((
                        <p onClick={memberLogin} className="c-text11 fw-bold fz-16 text_login">로그인하기</p>
                    ))

                }
            })
        }else{
            localStorage.removeItem('userInfo')
            localStorage.removeItem('token')
            localStorage.removeItem('schedule')
        }
       

        if(!localStorage.getItem('guest')){
            window.localStorage.setItem('guest',JSON.stringify(guest))
        }

        if(localStorage.getItem('userInfo')){
            let userInfo=JSON.parse(localStorage.getItem('userInfo'))
            setUserName(userInfo.username)
            
            setLoginMenu((
                <p onClick={()=>props.history.push('/ModifyUserInfo')} className="c-text11 fw-bold fz-16 text_login">회원정보 수정</p>
            ))

        }else{
            setUserName("guest")
            setLoginMenu((

                <p onClick={memberLogin} className="c-text11 fw-bold fz-16 text_login">로그인하기</p>
                
            ))
        }
        async function getBanners(){

            await axios.get(url+"/banners").then(res=>{
                
                setBanners(res.data.rows[1])
            })
        }
        if(localStorage.getItem('token')){

            setLogoutState((
                <p onClick={logOut} className="c-text11 fw-bold fz-16 text_login">로그아웃</p>
            ))
            
        }else{
            setLogoutState("")
        }
        getBanners()

    }, [])

    //멤버 로그인 페이지
    const memberLogin =()=>{
        props.history.push('/logInPage')
    }          
    const iconModifyAndPush=(e,url)=>{
        let name=e.target.getAttribute('name')
        document.getElementsByName(name)[3].style.color="#FECA28"
        if(name==="licenseChoice"){
            document.getElementById(name).src=Icon_feather_openBook2
        }else if(name==="TestInformation"){
            document.getElementById(name).src=Icon_feather_search2
        }else if(name==="setting"){
            document.getElementById(name).src=Icon_feather_settings2
        }
        

        setTimeout(() => {
            props.history.push(url)
        }, 300);

    }
    const kakao=()=>{
        window.ReactNativeWebView.postMessage('https://pf.kakao.com/_rEAej/chat?api_ver=1.1&kakao_agent=sdk%2F1.39.10%20os%2Fjavascript%20sdk_type%2Fjavascript%20lang%2Fko-KR%20device%2FWin32%20origin%2Fhttp%253A%252F%252Fjfpartners.co.kr&app_key=b115895f79d9c574116940b014b9816c&referer=http%3A%2F%2Fjfpartners.co.kr%2F%3Futm_source%3Dgoogle%26utm_medium%3Dp_sa%26utm_campaign%3D%25EA%25B5%25AC%25EA%25B8%2580_SA_%25EC%259E%2590%25EC%2582%25AC%26utm_content%3Dgosu_main%26utm_term%3D%25EA%25B3%25A0%25EC%2588%2598%25EC%259D%2598%25EC%259A%25B4%25EC%25A0%2584%25EB%25A9%25B4%25ED%2597%2588%26gclid%3DCj0KCQiAjKqABhDLARIsABbJrGkh1azPwOK5tZ-ZwxOcmGklfXNAvD4uyaFQwN8otRkHNYL8BbEHoZwaAphJEALw_wcB')
       
    }
    const tel=()=>{
        window.location.href="tel: 1811-9662"
    }
    const logOut =()=>{
        axios.post(url+"/users/logout").then((res)=>console.log(res.data.message))
        localStorage.removeItem('token')
        localStorage.removeItem('userInfo')
        window.location.reload()
        
    }
   
    const goToSchedule=()=>{

        if(localStorage.getItem('schedule')){
            const data=JSON.parse(localStorage.getItem('schedule'))
            props.history.push('/schedule',{name:data.name,phone:data.phone})
        }else{
            setVisible(!visible)
        }
    }
    return (
    <div className="bg-gray1">
        <header className="gosu-header-relative">

            <label className="gosu-header">
                <img  onClick={()=> window.ReactNativeWebView.postMessage(banners.linkUrl)} className="Advertising" alt="" src={banners.imageUrl}/>
            </label>

        </header>
        <div className="wrap-main-guest">
            <div className="user_box bg-white is-flex x-between y-center px-20">
                <p className="c-text11 fw-bold fz-16">{userName} 님</p>
            <label>
                {loginMenu}
                {logoutState}
            </label>
            </div>
                {/* {latitude}
                {longitude}
        <button onClick={locationInfo}>
            위치정보 보기
            </button>
        <button onClick={remove}>
            위치정보 지우기
        </button> */}
            <div className="box_group mb-10">
                <a href="/writeTestSampleLadingPage">
                    <label className="test_box bg-orange2">
                        <div className="header_box mb-15">
                            <p className="fz-14 c-text11 fw-bold">기출문제 전력질주!</p>
                        </div>
                    <p className="fz-14 fw-bold mb-10">필기시험</p>
                        <div className="black_line text-center bg-black2">
                        <p className="fz-20 fw-bold c-white lh-14">기출 문제</p>
                        <img  className="handle_img" alt="핸들이미지" src={handle}/>
                        </div>
                        <div>
                            <div className="name_box bg-white"></div>
                        </div>
                        
                    </label>
                </a>
                <a href="/writeTestMockTestLandingPage">  
                    <label className="test_box ml-20 bg-orange3">
                        <div className="header_box mb-15 ">
                            <p className="fz-14 c-white fw-bold " >실전 모의고사!</p>
                        </div>
                        <p className="fz-14 fw-bold c-white mb-10">필기시험</p>
                        <div className="black_line text-center bg-black2">
                            <p className="fz-20 fw-bold c-white lh-14">모의고사</p>
                            <img  className="handle_img" alt="핸들이미지" src={handle}/>
                        </div>
                        <div>
                            <div className="name_box bg-white"></div>
                        </div>

                    </label>
                </a>
                <a href="/functionalDrivingVideo">
                    <label className="test_box mt-20 bg-white">
                        <div className="header_box_2 mb-10">
                            <p className="fz-14 c-text11 fw-bold">도로주행영상</p>
                        </div>
                        <p className="fw-bold fz-16 lh-14" >먼저 눈으로<br/>익혀봅시다!</p>
                        <div className="camera">
                            <img className="" alt="사진기" src={video}/>
                        </div>
                    </label>
                </a>
                <a href="/driveTestCenterInfo">
                    <label className="test_box mt-20 ml-20 bg-black2">
                        <div className="header_box_3 mb-10">
                            <p className="fz-14 c-white fw-bold">운전연습장</p>
                        </div>
                        <p className="fw-bold fz-16 lh-14 c-white">내 주변 연습장을<br/>찾아보세요!</p>
                        
                        <img alt="사이드바" className="bar" src={side_bar}/>
                        <div className="drive is-flex x-center y-center">
                            <img alt="주변연습장" className="" src={logo_white}/>
                        </div>
                    </label>
                </a>
            </div>

            <div style={{marginTop:'3%'}} className="question_group">
               {
                localStorage.getItem('token')?
                <button onClick={goToSchedule} className=" question_box button ml-5 bg-white">
                    <div className=" is-flex x-center mb-5">
                        <img alt="스케쥴" src={icon_Calendar}/>
                    </div>
                    <p className="fz-10 c-text2">스케쥴</p>
                </button>
                :
                ""
               }
           
            
                
                <button onClick={kakao} className="question_box button ml-5 bg-white">
                    <div className="is-flex x-center mb-5">
                        <img  alt="카카오톡" src={icon_kakao} />
                    </div>
                    <p className="fz-10 c-text2">카카오톡 문의</p>
                </button>     
            
                <button onClick={tel} className="question_box button ml-5 bg-white">
                    <div className="is-flex x-center mb-5">
                        <img alt="전화상담" src={icon_gosu_phone}/>
                    </div>
                    <p className="fz-10 c-text2">전화 상담 </p>
                </button>
               
            </div>
        </div>
            
            
            
         <footer className="gnb_1 is-flex bg-white">

             <div className="gnb_1 is-flex bg-white">
                <button onClick={(e)=>iconModifyAndPush(e,'/licenseChoice')} name="licenseChoice" className="gnb_button button">
                    <div name="licenseChoice" className="mb-2">
                        <img id="licenseChoice" name="licenseChoice"   src={Icon_feather_openBook} alt="시험종류" />
                    </div>
                    <p name="licenseChoice" >시험 종류</p>
                </button>

                <button onClick={(e)=>iconModifyAndPush(e,'/TestInformation')} name="TestInformation" className="gnb_button button">
                    <div name="TestInformation" className="mb-2">
                        <img  id="TestInformation" name="TestInformation"   alt="시험정보" src={Icon_feather_search}/>
                    </div>
                    <p name="TestInformation">시험 정보</p>
                </button>  
                <button onClick={(e)=>iconModifyAndPush(e,'/setting')} name="setting" className="gnb_button button">
                    <div  name="setting" className="mb-2">
                        <img  id="setting" name="setting"   alt="환경설정" src={Icon_feather_settings}/>
                    </div>
                    <p name="setting">환경 설정</p>
                </button>
            </div>

         </footer>
           
        <ScheduleLogInModal
            visible={visible}
            setVisible={setVisible}
        />
    </div>
    )
}

export default MenuPage
