import React, { useState } from 'react'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import icon_Calendar from'../../../image/publishing_Img/icon_Calendar.png'
import { url } from '../../../enum'
function ScheduleLogInModal(props) {
    
    let visible =props.visible
    let setVisible =props.setVisible
    const [name,setName]=useState("")
    const [phone,setPhone]=useState("")
    const [error,setError]=useState("")
    

    const loadSchedule=()=>{
        
        axios.post(url+"/schedules/auth",{name:name, phone:phone}).then((res)=>{
            console.log(res.data.resCode)
            console.log(name, phone)
            let isSuccess=res.data.resCode
            if(isSuccess===0){
                
                setError("")
                props.history.push('/schedule',{name:name,phone:phone})
                localStorage.setItem('schedule',JSON.stringify({name:name,phone:phone}))
                
            }else{
                
                setError("회원 정보가 불일치 합니다. 다시 확인해주세요")
                
            }
        })
    }

    return (
        <div className="modal" style={{display:visible}}>
        <div className="modal-dim"></div>
        <div className="guest-content modal-content bg-white" style={{width:'80%'}}>
            <img className="Calendar" alt="" src={icon_Calendar}/>{}
            <p className="fz-14 fw-bold lh-15 mb-5">지점에서 등록한 스케줄 확인을 위해
                이름과 휴대폰 번호를 입력해주세요.</p>
            <div className="warning_text mb-10">
                <p className="fz-10 c-red2 text-center">{error}</p>
            </div>
            <input className="user_input fz-12 bg-gray1 mb-10" value={name} onChange={(e)=>setName(e.target.value)} type="text" placeholder="이름"/>
            <input className="user_input fz-12 bg-gray1"       value={phone} onChange={(e)=>setPhone(e.target.value)} type="text" placeholder="핸드폰 번호"/>

            <div className="is-flex x-between confirm text-center">
                <div className="button bg-orange2 is-flex y-center x-center" onClick={loadSchedule}>
                    <p className=" fz-12 fw-bold c-text11">확인</p>
                </div>
                <div className="button bg-gray3 is-flex y-center x-center" onClick={()=>setVisible('none')}>
                    <p className=" fz-12 fw-bold c-text11">취소</p>
                </div>
             

            </div>

        </div>
    </div>
    )
}

export default withRouter( ScheduleLogInModal)
