import React, { useState } from 'react'
import Icon_awesome_user_circle from'../../../image/publishing_Img/Icon-awesome-user-circle.png'
import Icon_awesome_lock from'../../../image/publishing_Img/Icon-awesome-lock.png'
import logo_black from '../../../image/publishing_Img/logo_black.png'
import axios from 'axios'
import { url } from '../../../enum'

function LoginPage(props) {
    const [id,setId]=useState("")
    const [password,setPassword]=useState("")

    const idHandler=(e)=>{
        setId(e.target.value)
    }
    const passwordHandler=(e)=>{
        setPassword(e.target.value)
    }
    const login=(e)=>{
        e.preventDefault();
        axios.post(url+"/users/login",{userid:id,password:password}).then(res=>{
            if(res.data.resCode===0){
                //로그인시 같이 날라오는 토큰을 로컬스토리지에 저장
                localStorage.setItem("token",res.data.token)
                //로그인시 같이 날로으는 유저의 정보를 로컬스토리지에 저장
                localStorage.setItem('userInfo',JSON.stringify(res.data.user))
                
                props.history.push('/menu',{user:res.data},{withCredentials:true})
            }else if(res.data.resCode===2){
                return alert("아이디를 찾을 수 없습니다.")
            }else if(res.data.resCode===3){
                return alert("비밀번호가 틀렸습니다.!")
            }
        })
    }
    const goToLobby=()=>{
        props.history.push("/menu")
    }
    const goToSighUp=()=>{
        props.history.push("/signUp")
    }
    const goToFindPassword=()=>{
        props.history.push("/FindPasswrod")
    }
    return (
        <div className="bg-gray1 full-height_loginPage">
            <div className="gosu-header-relative">
                <div className="gosu-header is-flex y-center x-center bg-white">
                    <label className="back_1">
                        <p onClick={goToLobby} className="fz-14 c-text11">이전</p>
                    </label>
                    <p className="c-text11 fz-16 fw-bold">로그인</p>
                </div>
            </div>
            <form onSubmit={login}>
                <div className="wrap-guest-2 " style={{height:"90.5vh"}}>
                    <div className="wrap_box bg-white">
                        <div className="logo is-flex x-center y-center mb-10">
                            <img alt="로고" src={logo_black}/>
                        </div>
                        <div className="typing_box">
                            <div className="box_bar is-flex x-between mb-30">
                                <div className="img_box">
                                <img src={Icon_awesome_user_circle}  alt="로그인 로고"/>
                                </div>
                               
                                <input value={id} onChange={idHandler} className="typing ml-5 fz-15 " type="text" required name="id" placeholder="아이디를 입력해주세요"/>
                            </div>
                            <div className="box_bar is-flex x-between mb-40">
                                <div className="img_box"><img src={Icon_awesome_lock} alt="패스워드로고"/></div>
                                
                                <input value={password} onChange={passwordHandler} className="typing ml-5 fz-15 " type="password" required name="password" placeholder="비밀번호를 입력해주세요"/>
                            </div>
                        </div>
                    
                                                
                        <button className="button guest-2_button is-flex x-center y-center mt-10 bg-orange2">
                            <p className="fz-16 c-white">로그인 하기</p>
                        </button>       
                        <button className="button guest-2_button  is-flex x-center y-center mt-10  bg-orange3" onClick={goToSighUp}>
                            <p className="fz-16 c-white">회원가입</p>
                        </button>
                        <button className="button guest-2_button  is-flex x-center y-center mt-10  bg-orange1" onClick={goToFindPassword}>
                            <p className="fz-16 c-white">비밀번호 찾기</p>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default LoginPage
