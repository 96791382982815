import React from 'react'


function BigImage(props) {

    return (
            <div className="modalOverlay" style={{display:props.visible}}>
                <div className="modalWrapper"  >
                    <img alt="확대" className="modalInner"
                    style={{width:'250%',height:'80%'}} 
                    onClick={()=>props.setVisible('none')}
                    src={props.imageUrl}/>
                </div>
            </div>    )
}

export default BigImage
