import React, { useEffect, useState } from 'react'

import axios from'axios'
import Icon_ionic_ios_arrow_down from'../../../image/publishing_Img/Icon-ionic-ios-arrow-down.png'
import Icon_ionic_ios_arrow_up from'../../../image/publishing_Img/Icon-ionic-ios-arrow-up.png'
import { url } from '../../../enum'
function Notice(props) {
    
    const dropDown =(e)=>{
        
        let name=e.target.getAttribute('name')
        
        let getClass=document.getElementsByName(name)[4]
        if(getClass.className==="box_2"){
            getClass.className="displayBlock"
            document.getElementsByName(name)[3].src=Icon_ionic_ios_arrow_up
        }else{
            getClass.className="box_2"
            document.getElementsByName(name)[3].src=Icon_ionic_ios_arrow_down
        }
    }
    const [notice,setNotice]=useState([])
    useEffect( () => {
        async function getNotice(){

            await axios.get(url+"/notices/enables").then(res=>{
                res.data.resCode===0?
                setNotice(res.data.rows)
                :
                alert("공지사항을 불러올수 없습니다. 잠시후 다시 시도해 주세요!")
                console.log(notice)
            })
        }
        getNotice()
    }, [])
    return (
      <div className="full-height bg-gray1">
        <div className="gosu-header-relative">
          <div className="gosu-header is-flex y-center x-center bg-white">
            <label className="back_1">
              <p
                onClick={() => props.history.goBack()}
                className="fz-14 c-text11"
              >
                이전
              </p>
            </label>
            <p className="c-text11 fz-16 fw-bold">공지사항</p>
          </div>
        </div>

        <div className="wrap-setting-2 bg-gray1">
          <ul>
            {notice.map((value, index) => (
              <li key={value.id} name={value.id}>
                <label
                  name={value.id}
                  className="box_1 bg-white"
                  onClick={dropDown}
                >
                  <p name={value.id} className="fz-14">
                    {value.title}
                  </p>
                  <img
                    name={value.id}
                    alt="arrow"
                    src={Icon_ionic_ios_arrow_down}
                  />
                </label>
                <div name={value.id} className="box_2">
                  <p className="noticeContent">
                    {value.content.split("\n").map((line) => {
                      return (
                        <span>
                          {line}
                          <br />
                        </span>
                      );
                    })}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
}
function lineText()
{}

export default Notice
