import React, { StrictMode, Suspense } from'react'
import {BrowserRouter as Router, Switch, Route} from'react-router-dom'
import LandingPage from './views/LandingPage/LandingPage';
import MenuPage from'./views/MenuPage/MenuPage'
import SampleTestLanding from './views/WriteTest/SampleTestLanding' 
import SampleTest from './views/WriteTest/SampleTest_new' 
import LoginPage from './views/LogInPage/LoginPage'
import SignUpPage from './views/LogInPage/SighUp'
import SignUpSuccess from'./views/LogInPage/SignUpSuccess'
import FindPasswrod from './views/LogInPage/FindPasswrod'
import Schedule from'./views/Schedule/Schedule'
import ModifyUserInfo from './views/LogInPage/ModifyUserInfo'
import MockTestLandingPage from './views/WriteTest/MockTestLandingPage'
import MockTest from './views/WriteTest/MockTest' 
import FunctionalDrivingLandingPage from './views/FunctionalDriveAndCourse/FunctionalDrivingLandingPage'
import FunctionalCourseLandingpage from "./views/FunctionalDriveAndCourse/FunctionalCourseLandingpage";
import FuncationalLocationVideoPage from "./views/FunctionalDriveAndCourse/FuncationalLocationVideoPage"; 
import DrivingTestCategory from'./views/FunctionalDriveAndCourse/DrivingTestCategory'
import DrivingTestInfo from './views/FunctionalDriveAndCourse/DriveTestInfo';
import NearDriveTestCenterLandingPage from './views/NearDriveTestCenter/NearDriveTestCenterLandingPage'
import TestInfomationMenuPage from './views/TestInfomation/TestInfomationMenuPage'
import Detail from'../Component/views/TestInfomation/Detail'
import SettingLandingPage from './views/SettingPage/SettingLandingPage'
import Notice from'./views/SettingPage/Notice'
import LicenseChoice from './views/MenuPage/LicenseChoice'
import FAQ from'./views/SettingPage/FAQ'
import introduction from'./views/SettingPage/Introduction'
import UnregistSplash from'./views/SettingPage/UnregistSplash'
import '../Component/ComponentCSS/style_1.css'
import '../Component/ComponentCSS/style_2.css'
import '../Component/ComponentCSS/main_2.css'
import '../Component/ComponentCSS/gosu.css'

function App() {

  return (
    <Suspense>
      <Router>
        <Switch>
          {/* 첫페이지 */}
          <Route exact path="/" component={LandingPage} />
          {/* 라이센스 선택 페이지 */}
          <Route exact path="/licenseChoice" component={LicenseChoice} />
          {/* 라이센스 메뉴페이지 */}
          <Route exact path="/menu" component={MenuPage} />
          {/* 로그인 페이지 */}
          <Route exact path="/logInPage" component={LoginPage} />
          {/* 회원가입 페이지 */}
          <Route exact path="/signUp" component={SignUpPage} />
          {/* 회원가입 완료 페이지 */}
          <Route exact path="/SignUpSuccess" component={SignUpSuccess} />
          {/* 회원정보 수정 페이지 */}
          <Route exact path="/ModifyUserInfo" component={ModifyUserInfo} />
          {/* 비밀번호찾기 */}
          <Route exact path="/FindPasswrod" component={FindPasswrod} />
          {/* 스케쥴 확인 */}
          <Route exact path="/schedule" component={Schedule} />
          {/* 필기시험 기출문제 LOGOPage*/}
          <Route
            exact
            path="/writeTestSampleLadingPage"
            component={SampleTestLanding}
          />
          {/* 필기시험 문제 PAGE */}
          <Route exact path="/writeTestSample" component={SampleTest}></Route>
          {/* 필기시험 모의고사 LOGOPage */}
          <Route
            exact
            path="/writeTestMockTestLandingPage"
            component={MockTestLandingPage}
          ></Route>
          {/* 필기시험 모의고사 문제 PAGE */}
          <Route
            exact
            path="/writeTestMockTestSample"
            component={MockTest}
          ></Route>
          {/* 기능/도로 주행 영상 */}
          <Route
            exact
            path="/functionalDrivingVideo"
            component={FunctionalDrivingLandingPage}
          ></Route>
          {/* 지역별 운전면허시험장 도로주행 카테고리 */}
          <Route
            exact
            path="/drivingTestCategory"
            component={DrivingTestCategory}
          ></Route>
          {/* 지역별 운전면허시험장 도로주행 상새정보 */}
          <Route
            exact
            path="/driveTestInfo"
            component={DrivingTestInfo}
          ></Route>
          {/* 운전정보 연습장 정보 */}
          <Route
            exact
            path="/driveTestCenterInfo"
            component={NearDriveTestCenterLandingPage}
          ></Route>
          {/* 시험정보  */}
          <Route
            exact
            path="/TestInformation"
            component={TestInfomationMenuPage}
          ></Route>
          {/* 시험 상새정보*/}
          <Route exact path="/detailInfo" component={Detail}></Route>
          {/* 설정 랜딩페이지 */}
          <Route exact path="/setting" component={SettingLandingPage}></Route>
          {/* 회원탈퇴 완료 splash */}
          <Route
            exact
            path="/unregistsplash"
            component={UnregistSplash}
          ></Route>
          {/* 공시사항 페이지 */}
          <Route exact path="/notice" component={Notice}></Route>
          {/* FAQ페이지 */}
          <Route exact path="/FAQ" component={FAQ}></Route>
          {/* 회사소개 패이지 */}
          <Route exact path="/introduction" component={introduction}></Route>
          {/* 2021-07-07 코스 페이지 */}
          <Route
            exact
            path="/course"
            component={FunctionalCourseLandingpage}
          ></Route>
          <Route
            exact
            path="/locationvideo"
            component={FuncationalLocationVideoPage}
          ></Route>
        </Switch>
      </Router>
    </Suspense>
  );
}

export default App;
