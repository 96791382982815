import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import axios from'axios'
import Icon_metro_warning_gray from'../../../image/publishing_Img/Icon-metro-warning-gray.png'
import Iconionic_close_circle_outline_green from '../../../image/publishing_Img/Iconionic-close-circle-outline-green.png'
import img_result_mark from'../../../image/publishing_Img/img_result_mark.png'
import Iconionic_close_circle_outline_orange from '../../../image/publishing_Img/Iconionic-close-circle-outline-orange.png'
import O from '../../../image/publishing_Img/Icon_O.png'
import X from '../../../image/publishing_Img/Icon_X.png'
import numberPad from './numberpad'
import { url } from '../../../enum'


function MockTestModals(props) {
    
    //모달 none/block 샛
    const setVisible=props.setVisible
    ////모달 목적 파악
    const goal=props.isGoal
    const setGoal=props.setIsGoal

    //타이머 간접적으로 멈추기
    const setIsStop=props.setIsStop
    const [modalView,setModalView]=useState("")
    //////////////
    //걸린시간
    let time=props.time
    //내가 풀었던 문제 가져오기
    let myAnswer=props.myAnswer
    //  답안지 정렬
    myAnswer.sort((a,b)=>{return a.sortnumber-b.sortnumber})
    //점수
    let score=0;
    //합격여부
    let isAcceptance="미정"
    //내가 푼 문제
    let questionView=props.questionView;
    //채점했니 안했니?
    let isScoring=props.isScoring
    
    //채점이 시작되면 true로 바꿔준뒤에 다시는 풀었던 배열에 넣지 않도록한다
    let setIsScoring=props.setIsScoring
    //문항 이동 하기 관련 코드///////////////////////////////////////////////////////////////
    const setQueryNumber=props.setQueryNumber
    
    // const setQuestionView=props.setQuestionView

    const updateBool=props.updateBool
 
   const [one,setOne]=useState(true)
    
   //문제로 돌아갈때1
    const goToQuery=(e)=>{
        
        setVisible('none')
        
        setQueryNumber(e.target.value-1)
    }
    //문제로 돌아갈때2
    const goToQueryReset=(e,index,issolved)=>{
        
        // if(issolved===false||index>=questionView.length){
        //     return alert('풀지 않은 문제는 "틀린문제 보기에서는 접근 할 수 없습니다." ')
        // }
        setQueryNumber(index)
        
        setVisible('none')
    }
    //문제로 돌아갈때3
    
    const goToQueryWithOmr=(e,number)=>{
        
        setQueryNumber(number)
        setVisible('none')
    }
    ////////////////////////////////////////////////////////////////////////////////////////
    const scoringFunction =()=>{

        setGoal('scoring')
        setIsStop(true)
    }
    
    let elementMySolved=myAnswer.map((value,index)=>
    <li key={index} value={value.number} onClick={goToQuery}>
        {value.number}번문항
    </li>
     )
     useEffect(() => {
      
        // console.log("답안지",myAnswer)
       
         //선택한 자격증
         let license=localStorage.getItem('license')
       
         
         if(goal==="switchQuery"){
             setModalView(( 

                <div className="modalOverlay" style={{display:props.visible}}>
                    <div className="modalWrapper">
                        <div className="modalInner">
                            <ol>
                                {elementMySolved}
                            </ol>
                            <button onClick={()=>setVisible('none')}>취소</button>
                        </div>
                    </div>
                </div>

                ))
        }else if(goal==="confirmScoring"){
            if(isScoring){
                   
                
                return setModalView((
                    <div className="modal-main-practice_test-default" style={{display:props.visible}}>
                    <div className="modal-dim"></div>
                    <div className="modal-content content_1 bg-white">
                        <div className="icon_warning">
                            <img alt="워닝" src={Icon_metro_warning_gray}/>
                            <p className="c-text11 fz-14" >
                            정말 메인화면으로<br/> 나가시겠습니까?
                            </p>
                        </div>
                        <div className="button_group">
                            <input type="button" onClick={()=>setVisible('none')} value="취소" className="button bg-orange2" />
                            <input type="button" onClick={()=>props.history.push('/menu')} value="나가기" className="button bg-gray3"/>
                        {/* <button onClick={()=>props.history.goBack()}>나가기</button> */}
                        </div>
                    </div>
                </div>

                ))
            }

            if(questionView.length===myAnswer.length){
                setModalView((
                    <div className="modalWrapper">
                        <div className="modalInner">
                            문재플이를 완료했습니다.!
                            <button onClick={scoringFunction}>채점하기</button>
                            <button onClick={()=>setVisible('none')}>취소</button>
                            <button onClick={()=>props.history.goBack()}>나가기</button>
                        </div>
                    </div>
                ))
            }else{

                setModalView((
                    <div className="modal-main-practice_test-default" style={{display:props.visible}}>
                        <div className="modal-dim"></div>
                        <div className="modal-content content_1 bg-white">
                            <div className="icon_warning">
                                <img alt="워닝" src={Icon_metro_warning_gray}/>
                                <p className="c-text11 fz-14" >
                                아직 풀지 못한 문제가 있습니다.<br/>
                                이대로 채점하시겠습니까?
                                </p>
                            </div>
                            <div className="button_group">
                                <input type="button" onClick={()=>setVisible('none')} value="이어서 풀기" className="button bg-orange2" />
                                <input type="button" onClick={scoringFunction} value="채점하기" className="button bg-gray3"/>
                            {/* <button onClick={()=>props.history.goBack()}>나가기</button> */}
                            </div>
                        </div>
                    </div>
                ))
            }
            //채점
        }else if(goal==="scoring"){
            //채점시작 후  guest.sampleTest 배열에 넣기 스토리지에 넣기
          
            console.log("문제",questionView)
            
            for(let i =0; i<myAnswer.length; i++){
                if(myAnswer[i].isCorrect){
                   // eslint-disable-next-line react-hooks/exhaustive-deps
                   score=score+parseInt(myAnswer[i].score)
                   console.log("점수 : "+score)
                }
            }
            //개스트의 회차정보
            let guestMockTestInfo=JSON.parse(localStorage.getItem('guest'))
            //시험보기전 개스트의 회차정보 
            
            //채점이 끝나면 localStorage에 기출문재 정보 저장
            //채점이 안됬을 경우에만 풀이 내용에 넣는다.
            if(!isScoring){
                guestMockTestInfo.mockTest.push(score)
            }
            //채점이 완료되면 true로
            setIsScoring(true)
            //기출문제 점수 오름차순으로
            guestMockTestInfo.mockTest.sort((a,b)=>a-b)

            // //풀이회차
            // let severalTimes=guestMockTestInfo.mockTest.length;
            // //최고점수
            // let maxScore=guestMockTestInfo.mockTest[(guestMockTestInfo.mockTest.length-1)]
            //누적점수
            let cumulativeScore=0
            for(let i =0; i<guestMockTestInfo.mockTest.length; i++){
                cumulativeScore=cumulativeScore+guestMockTestInfo.mockTest[i]
            }
            //평균점수
            // let average = cumulativeScore/guestMockTestInfo.mockTest.length
            //이후 다시 localStorage에 넣는다.
            localStorage.setItem('guest',JSON.stringify(guestMockTestInfo))
            
            let cutScore=0
            if(license==="OM"){
                license="제 1종 보통"
                cutScore=70
            }else if(license==="TM"){
                license="제 2종 보통 수동"
                cutScore=60
            }else if(license==="THM"){
                license="제 2종 보통 자동"
                cutScore=60
            }
            setQueryNumber(0)
            //걸린시간 채크
            let times=parseInt(time.minit*60)+parseInt(time.second)
            
            let timee=Math.floor(2400-times)
            // let minit=Math.floor(timee/60)
            // let second=timee%60
            console.log(timee,"총걸린시간")
            if(one){
                
                axios.put(url+"/users/actions/practice-exam",{token:localStorage.getItem("token"),type:myAnswer.length===questionView.length?`complete40`:`scoring`,score:score})
                .then(res=>{
                    
                })
                setOne(false)
            }

            //합격여부
            if(score>=cutScore){
                isAcceptance='합격'
                 
            setModalView((
                <div className="modal-main-practice_test-default–2" style={{display:props.visible}}>
                    <div className="modal-dim"></div>
                    <div className="modalWrapper">
                        <div className="wrap-test_result_success bg-orange2">
                            <h1>SCORE</h1>
                            <div className="black_line bg-black2">
                                <p>{score}점</p>
                            </div>
                            <img className="result_mark" alt="결과창" src={img_result_mark} />
                            <div className="text_1 mx-20">
                               <p className="fz-18 fw-bold">축하합니다!</p>
                               
                            </div>
                            <div className="text_2 mx-20 c-text11">
                                <p class="fz-18 fw-bold px-10" >
                                    {license} 필기에 합격하셨습니다.!
                                </p>
                            </div>
                            <div className="button_group mx-20 is-flex x-between mb-15">
                                <input type="button" onClick={()=>window.location.reload()} value="다시풀기" className="button restart_button fz-16 fw-bold c-white"/>
                                <input type="button" onClick={()=>setGoal('wrongAnswerNotes')} value="오답 노트" class="button bg-orange2 fz-16 fw-bold c-white"/>
                            </div>
                            <input onClick={()=>props.history.push('/menu')} style={{textAlign:'center'}}  className="button out_button mx-20 fz-16 c-text11 bg-white fw-bold" value="나가기"/>
                            {/* <p>걸린시간 : {numberPad(minit,2)+"분 "+numberPad(second,2)+"초"}</p>
                            <p>풀이회차 : {severalTimes} </p>
                            <p>최고점수 : {maxScore}</p>
                            <p>누적점수 : {cumulativeScore}</p>
                            <p>평균점수 : {average.toFixed(1)}</p> */}

                            
                            {/* 다시 풀기 할땐 랜덤으로 받아온 문제의 index 배열을 같이 보낸다 */}
                            {/* <button onClick={()=>setVisible('none')}>가리기</button> */}
                            
                        </div>
                    </div>
                </div>
                ))
            }else{
                isAcceptance='불합격'
                setModalView((
                    <div className="modal-main-practice_test-default–2" style={{display:props.visible}}>
                        <div className="modal-dim"></div>
                        <div className="modalWrapper">
                            <div className="wrap-test_result_fail">
                                <h1>SCORE</h1>
                                <div className="black_line bg-black2">
                                    <p>{score}점</p>
                                </div>
                                <img className="result_mark" alt="결과창" src={img_result_mark} />
                                <div className="text_1 mx-20">
                                   <p className="fz-18 fw-bold">{license} 필기에 {isAcceptance}하셨습니다!</p>
                                   
                                </div>
                                <div className="text_2 mx-20 c-text11">
                                    <p style={{lineHeight:'1.5'}} className="fz-18 fw-bold">정확한 모의고사 진단을 위해 좀 더<br/>
                                    심사숙고하여 문제를 풀어주시기 바랍니다.</p>
                                </div>
                                <div className="button_group mx-20 is-flex x-between mb-15">
                                    <input type="button" onClick={()=>window.location.reload()} readOnly defaultValue="다시풀기" className="button restart_button fz-16 fw-bold c-white"/>
                                    <input type="button" onClick={()=>setGoal('wrongAnswerNotes')} readOnly defaultValue="오답 노트" className="button bg-orange2 fz-16 fw-bold c-white"/>
                                </div>
                                <input onClick={()=>props.history.push('/menu')} style={{textAlign:'center'}}  className="button out_button mx-20 fz-16 c-text11 bg-white fw-bold" defaultValue="나가기"/>
                                {/* <p>걸린시간 : {numberPad(minit,2)+"분 "+numberPad(second,2)+"초"}</p>
                                <p>풀이회차 : {severalTimes} </p>
                                <p>최고점수 : {maxScore}</p>
                                <p>누적점수 : {cumulativeScore}</p>
                                <p>평균점수 : {average.toFixed(1)}</p> */}
                                {/* 다시 풀기 할땐 랜덤으로 받아온 문제의 index 배열을 같이 보낸다 */}
                                
                            </div>
                        </div>
                    </div>
                    ))
            }
            
           
        }else if(goal==='explain'){
            setModalView((
                <div className="modalWrapper">
                    <div className="modalInner">
                        해설 : {props.explain}
                        <img alt="해설" src={props.explainMedia}/>
                        <button onClick={()=>setVisible('none')}>확인</button>
                    </div>
                </div>
            ))
        }else if(goal==='wrongAnswerNotes'){

            let array=[]         
            for(let i=0; i<40;i++){
                array.push({right:false})
            }
       
           console.log("나의답",myAnswer)
           console.log("문제",questionView)     

           for(let i=0; i<myAnswer.length; i++){
                if(myAnswer[i]){
                    array.splice(myAnswer[i].sortnumber,1,{right:myAnswer[i].isCorrect})
                }
           }
                  
        console.log(array)
            let questions1=[...Array(10)].map((n, index) =>
                        <>
                            {array[index].right?
                                <li key={index} className="capsule on" onClick={(e)=>goToQueryReset(e,index)}>
                                    <div  className="cap_A">
                                        <p>
                                            {numberPad(index+1,2)}  
                                        </p>
                                    </div>
                                    <div  className="cap_B">
                                        <div >
                                            <img alt="O" src={O}/>
                                        </div>
                                    </div>
                                </li>
                            :
                                <li key={index}   className="capsule off" onClick={(e)=>goToQueryReset(e,index)}> 
                                    <div  className="cap_A">
                                        <p >
                                            {numberPad(index+1,2)} 
                                        </p >
                                    </div >
                                    <div   className="cap_B">
                                        <div >
                                            <img alt="X" src={X}/>
                                        </div>
                                    </div>
                                </li>
                            
                            } 
                        </>
                )

                let questions2=[...Array(10)].map((n, index) =>
                <>
                    {array[parseInt(index)+10].right?
                        <li key={parseInt(index)+10} className="capsule on" onClick={(e)=>goToQueryReset(e,parseInt(index)+10)}>
                            <div  className="cap_A">
                                <p >
                                    {numberPad(index+11,2)}  
                                </p>
                            </div>
                            <div className="cap_B">
                                <div >
                                <img  alt="X" src={O}/>
                                </div>
                            </div>
                        </li>
                    :
                        <li key={parseInt(index)+10}   className="capsule off" onClick={(e)=>goToQueryReset(e,parseInt(index)+10)}> 
                            <div  className="cap_A">
                                <p >
                                    {numberPad(index+11,2)} 
                                </p >
                            </div >
                            <div   className="cap_B">
                                <div  >
                                     <img  alt="X" src={X}/>
                                </div>
                            </div>
                        </li>
                    } 
                </>
        )
            let questions3=[...Array(10)].map((n, index) =>
            <>
                {array[parseInt(index)+20].right?
                    <li key={parseInt(index)+20}  className="capsule on" onClick={(e)=>goToQueryReset(e,parseInt(index)+20)}>
                        <div className="cap_A">
                            <p>
                                {numberPad(index+21,2)}  
                            </p>
                        </div>
                        <div  className="cap_B">
                            <div >
                            <img  alt="X" src={O}/>
                            </div>
                        </div>
                    </li>
                :
                    <li key={parseInt(index)+20} className="capsule off" onClick={(e)=>goToQueryReset(e,parseInt(index)+20)}> 
                        <div  className="cap_A">
                            <p >
                                {numberPad(index+21,2)} 
                            </p >
                        </div >
                        <div className="cap_B">
                            <div >
                            <img alt="X" src={X}/>
                            </div>
                        </div>
                    </li>
                } 
            </>
            )

            let questions4=[...Array(10)].map((n, index) =>
            <>
                {array[parseInt(index)+30].right?
                    <li key={parseInt(index)+30}  className="capsule on" onClick={(e)=>goToQueryReset(e,parseInt(index)+30)}>
                        <div className="cap_A">
                            <p >
                                {numberPad(index+31,2)}  
                            </p>
                        </div>
                        <div className="cap_B">
                            <div >
                            <img alt="X" src={O}/>
                            </div>
                        </div>
                    </li>
                :
                    <li key={parseInt(index)+30}  className="capsule off" onClick={(e)=>goToQueryReset(e,parseInt(index)+30)}> 
                        <div  className="cap_A">
                            <p  >
                                {numberPad(index+31,2)} 
                            </p >
                        </div  >
                        <div  className="cap_B">
                            <div >
                            <img alt="X" src={X}/>
                            </div>
                        </div>
                    </li>
                } 
            </>
            )
                //틀린거 맞는거 카운트
            let correct=0
            let uncorrect=0
           for(let i =0; i<myAnswer.length; i++){
               if(myAnswer[i].isCorrect){
                    correct=correct+1
               }else{
                   uncorrect=uncorrect+1
               }
           }
             //점수계산
            for(let i =0; i<myAnswer.length; i++){
                if(myAnswer[i].isCorrect){
                   // eslint-disable-next-line react-hooks/exhaustive-deps
                   score=score+myAnswer[i].score
                 
                }
            }
             //합격여부
             if(score>=70){
                isAcceptance='합격'
            }else{
                isAcceptance='불합격'
            }
            setModalView((
                <div className="modalOverlay" style={{display:props.visible}}>
                    <div className="modal-study_note">
                        
                        <div className="modal-content content_1 bg-white">
                            <button onClick={()=>props.setVisible('none')} className="button modal_close">
                                <img alt="오랜지" src={Iconionic_close_circle_outline_orange}/>
                            </button>
                        <div className="test_guide">
                            <h1>오답노트</h1>
                            <p>해당 문항을 클릭하시면 해당 문제로 이동합니다.</p>
                        </div>
                        <div className="test_table">
                            <ul className="line">
                                {questions1}
                            </ul>
                            <ul className="line">
                                {questions2}
                            </ul>
                            <ul className="line">
                                {questions3}
                            </ul>
                            <ul className="line">
                                {questions4}
                            </ul>
                        </div>
                        
                        
                        {/* {license}({isAcceptance})
                        정답 : {correct}
                        오답 : {uncorrect} */}
                        {/* <button onClick={()=>setVisible('none')}>확인</button> */}
                        {/* <button onClick={()=>props.checkQuestionsHandler('all')}>전체 문제 보기</button>
                        <button onClick={()=>props.checkQuestionsHandler('partOfWrong')}>틀린 문제 보기</button> */}
                        </div>
                    </div>
                </div>
                
            ))

        }else if(goal==="omr"){
            
            let array=[]         
            for(let i=0; i<40;i++){
                array.push({right:[],sortnumber:""})
            }
       
            for(let i=0; i<myAnswer.length; i++){
                if(myAnswer[i]){
                    array.splice(myAnswer[i].sortnumber,1,myAnswer[i])
                }
            }
           
            
            let omr1=[...Array(10)].map((n, index) =>
            <>
            {array[index].sortnumber!==""?
                <li key={index}  onClick={(e)=>goToQueryWithOmr(e,index)} className="capsule on">
                    <div  className="cap_A">
                        <p>
                            {numberPad(index+1,2)}
                        </p>
                    </div>
                    <div  className="cap_B">
                        <p>
                            {array[index].right.join("  ")}
                        </p>
                    </div>
                </li>
            :
           
                <li key={index} onClick={(e)=>goToQueryWithOmr(e,index)} className="capsule off">
                    <div className="cap_A">
                        <p>
                            {numberPad(index+1,2)}
                        </p>
                    </div>
                    <div className="cap_B">
                        <p>
                            x
                        </p>
                    </div>
                </li>
            } 
            </>
            )
            let omr2=[...Array(10)].map((n, index) =>
            <>
            {array[parseInt(index)+10].sortnumber!==""?
                <li key={parseInt(index)+10}  onClick={(e)=>goToQueryWithOmr(e,parseInt(index)+10)} className="capsule on">
                    <div  className="cap_A">
                        <p>
                            {numberPad(index+11,2)}
                        </p>
                    </div>
                    <div  className="cap_B">
                        <p>
                            {array[parseInt(index)+10].right.join("  ")}
                        </p>
                    </div>
                </li>
            :
                <li key={parseInt(index+1)+10} onClick={(e)=>goToQueryWithOmr(e,parseInt(index)+10)} className="capsule off">
                    <div className="cap_A">
                        <p>
                            {numberPad(index+11,2)}
                        </p>
                    </div>
                    <div className="cap_B">
                        <p>
                            x
                        </p>
                    </div>
                </li>
            } 
            </>
            )
            let omr3=[...Array(10)].map((n, index) =>
            <>
            {array[parseInt(index)+20].sortnumber!==""?
                <li key={parseInt(index)+20}  onClick={(e)=>goToQueryWithOmr(e,parseInt(index)+20)} className="capsule on">
                    <div  className="cap_A">
                        <p >
                            {numberPad(index+21,2)}
                        </p>
                    </div>
                    <div  className="cap_B">
                        <p >
                            {array[parseInt(index)+20].right.join("  ")}
                        </p>
                    </div>
                </li>
            :
            
                <li key={parseInt(index+1)+20} onClick={(e)=>goToQueryWithOmr(e,parseInt(index)+20)} className="capsule off">
                    <div className="cap_A">
                        <p>
                            {numberPad(index+21,2)}
                        </p>
                    </div>
                    <div className="cap_B">
                        <p>
                            x
                        </p>
                    </div>
                </li>
            } 
            </>
            )
            let omr4=[...Array(10)].map((n, index) =>
            <>
            {array[parseInt(index)+30].sortnumber!==""?
                <li key={parseInt(index)+30}  onClick={(e)=>goToQueryWithOmr(e,parseInt(index)+30)} className="capsule on">
                    <div className="cap_A">
                        <p >
                            {numberPad(index+31,2)}
                        </p>
                    </div>
                    <div  className="cap_B">
                        <p >
                            {array[parseInt(index)+30].right.join("  ")}
                        </p>
                    </div>
                </li>
            :
                <li key={parseInt(index+1)+30} onClick={(e)=>goToQueryWithOmr(e,parseInt(index)+30)} className="capsule off">
                    <div className="cap_A">
                        <p>
                            {numberPad(index+31,2)}
                        </p>
                    </div>
                    <div className="cap_B">
                        <p>
                            x
                        </p>
                    </div>
                </li>
            } 
            </>
            )
             setModalView((
                <div className="modal-main-practice_test-default–2" style={{display:props.visible}}>
                    <div className="modal-dim"></div>

                    <div className="modal-content content_1 bg-white">
                        <button className="button modal_close" onClick={()=>setVisible('none')}>
                            <img alt="그린" src={Iconionic_close_circle_outline_green}/>
                        </button>
                        <div className="test_guide">
                            <h1>모의고사 답안지</h1>
                            <p>답을 수정하고자 하는 문제를 클릭하시면 해당 문제로 이동합니다.
                                아직 풀지 않은 문제는 이동이 불가능 합니다.</p>
                        </div>
                        <div className="test_table">

                            <ul className="line">
                                {omr1}
                            </ul>
                            <ul className="line">
                                {omr2}
                            </ul>
                            <ul className="line">
                                {omr3}
                            </ul>
                            <ul className="line">
                                {omr4}
                            </ul>

                        </div>
                    </div>
                </div>


             ))   
        }

    }, [goal,updateBool,myAnswer,isScoring,myAnswer.length,props.visible,props.questionNumber])
    


    return (
            <>
               {modalView}
            </>
            
            )
        

}

export default  withRouter (MockTestModals)
