import React from "react";
import logo_black from "../../../image/publishing_Img/logo_black.png";
import text1 from "../../../image/publishing_Img/text1.png";


function Introduction(props) {
  return (
    <div className="bg-white">
      <div className="gosu-header-relative">
        <div className="gosu-header is-flex y-center x-center bg-white">
          <label className="back_1">
            <p
              onClick={() => props.history.goBack()}
              className="fz-14 c-text11"
            >
              이전
            </p>
          </label>
          <p className="c-text11 fz-16 fw-bold">회사소개</p>
        </div>
      </div>
      <div className="warp-main-setting-company_info">
        <div className="logo_line">
          <img alt="로고" src={logo_black} />
        </div>
        <p className="fz-12 text_box">
          고수의 운전면허는 자동차 운전학원이 아닌, 시뮬레이터를 이용하는 신개념
          운전연습장입니다.
          <br />
          운전면허 취득 예정인 여러분께 거품 없이 최소의 비용으로 운전 면허
          취득의 첫걸음부터, 합격할 때까지 반복연습이 가능합니다.
          <br />
          예비 운전자 여러분께 양질의 서비스 제공을 위해 최선을 다하고 있으며,각
          지점의 합격률을 지속적으로 관리하여 회원들의 평가가 좋은 고수의
          운전면허가 될 수 있도록 노력하고 있습니다.
          <br />
          <br />
          
          <br />
          하나. 합격무제한반(3개월)은 합격률이 우수해야만 자신 있게 시행할 수
          있는 제도입니다.
          <br />
          두울. 운전을 처음 시작하고자 하는 예비 운전자 여러분의 첫 시작부터
          끝까지 함께 하겠습니다.
        </p>
        <img className="end_img" alt="엔드" src={text1} />
      </div>
      <footer>
        <a target="_blank" rel="noreferrer" href="http://jfpartners.co.kr/">
          <label className="button_box">
            <p className="fz-18 c-white">고수의 운전면허 사이트 바로가기</p>
          </label>
        </a>
      </footer>
    </div>
  );
}

export default Introduction;
