import React from 'react';
import ReactDOM from 'react-dom';
import App from './Component/App';
import reportWebVitals from './reportWebVitals';

// 리덕스들
import {Provider} from'react-redux'
import {applyMiddleware, createStore} from'redux'
import promiseMiddleware from'redux-promise'
import ReduxThunk from'redux-thunk'
import Reducers from'./Redux/Reducer/CombinReducer'

const StoreWithMiddlerWares=applyMiddleware(promiseMiddleware,ReduxThunk)(createStore)

ReactDOM.render(
  <Provider store={StoreWithMiddlerWares(Reducers,
    //크롬에서 확장프로그램인 리더스 툴을 이용하기 위한 코드
  window.__REDUX_DEVTOOLS_EXTENSION__&&
  window.__REDUX_DEVTOOLS_EXTENSION__())}>
  <React.StrictMode>
    <App />
  </React.StrictMode>
  </Provider>
  ,
  
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
