import React, { useState } from 'react'


import LicenseTestCenter from'./LicenseTestCenter'

function AllCourse() {

    const [courseInfo,setCourseInfo]=useState(<LicenseTestCenter where="nation"/>)

    
    const countryCourse =(e,where)=>{
        
        
        let button=document.getElementsByName('course')
        for(let i=0; i<button.length; i++){
            button[i].className="button off"
        }
        e.target.className="button on"

        setCourseInfo((<LicenseTestCenter where={where}/>));
    }
   
    
    return (

    <div className="test_place_video_box bg-white" >
        <p className="fz-14 fw-bold mb-20">전국 운전면허 시험장</p>
         <table className="area_table">
             <tbody>
                <tr>
                     <td >
                         <input name="course" type="button" className="button on" value="전국" onClick={(e)=>countryCourse(e,'nation')}/>
                     </td> 
                     <td >
                         <input name="course" type="button" className="button off" value="서울" onClick={(e)=>countryCourse(e,'seoul')}/>
                    </td>
                     <td >
                         <input name="course" type="button" className="button off" value="경기/인천"onClick={(e)=>countryCourse(e,'kyungki')}/>
                    </td> 
                 </tr>
                <tr>
                    <td>
                        <input name="course" type="button" className="button off" value="부산/울산/경남" onClick={(e)=>countryCourse(e,'busan')}/>
                    </td>
                    <td>
                        <input name="course" type="button" className="button off" value="대구/경북"onClick={(e)=>countryCourse(e,'daegu')}/>
                    </td>
                    <td>
                        <input name="course" type="button" className="button off" value="광주/전라" onClick={(e)=>countryCourse(e,'kwangju')}/>
                    </td> 
                </tr>
                <tr>
                    <td>
                        <input name="course" type="button" className="button off" value="대전/세종/충청" onClick={(e)=>countryCourse(e,'daejyun')}/>
                    </td>
                    <td>
                        <input name="course" type="button" className="button off" value="강원/제주" onClick={(e)=>countryCourse(e,'kangwon')}/>
                    </td>
                </tr>
             </tbody>
         </table>
            {courseInfo}
         
        
    </div>
    )
}

export default AllCourse
