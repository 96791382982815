import axios from 'axios'
import React from 'react'
import { withRouter } from 'react-router'
import { url } from '../../../enum'

function MemberWithdrawalModal(props) {
    const visible=props.visible
    const setVisible=props.setVisible

    const unregist =()=>{

        if(localStorage.getItem('token')){

            axios.post(url+"/users/unregister",{token:localStorage.getItem('token')}).then(res=>{
                if(res.data.resCode===0){
                    alert("탈퇴가 완료되었습니다")
                    localStorage.removeItem('token')
                    setTimeout(() => {
                        props.history.push('/unregistsplash')
                    }, 400);
                }else{
                    
                    alert("이미 탈퇴한 회원이거나 일시적 오류입니다.")
                }
            })

        }else{

            alert("다시 로그인 한뒤에 시도해주세요!")
        }

    }

    return (
       <div className="modal-main-practice_test-default" style={{display:visible}} >
        <div className="modal-dim"></div>

        <div className="modal-content content_1 bg-white">
            <div className="icon_warning">
               
                <p className="c-text11 fz-14">고수의 운전면허를<br/>
                    탈퇴하시겠습니까?</p>
            </div>
            <div className="button_group">
                <input type="button" value="확인"  onClick={unregist}  className="button bg-orange2 fz-16"/>
                <input type="button"  value="취소" onClick={()=>setVisible('none')} className="button bg-gray3 fz-16"/>
            </div>
        </div>
    </div>
    )
}

export default withRouter (MemberWithdrawalModal)

