import React, { useState } from 'react'
import img_Standard_picture from '../../../image/publishing_Img/img-Standard-picture.svg'


function DetailTab() {
    
    const [view,setView]=useState((
                <div className="guide_text_box bg-white">
                <h2 className="mb-10">학과시험</h2>
                <p>· 전국 운전면허 시험장에서 평일 09:00 ~ 17:00 
                    응시접수 가능<br/>
                    (비문해자, 청각 장애인 학과 시험은 16:00까지 접수)</p>
                <p className="mb-10">· PC학과시험은 당일 접수하여 당일 응시하므로 별도로 예
                    약하실 필요가 없습니다.</p>
                <table className="department_test_table">
                    <thead>
                        <tr>
                            <td>
                                종류
                            </td>
                            <td>
                                1종<br/>
                                대형,특수
                            </td>
                            <td>
                                1종<br/>
                                보통
                            </td>
                            <td>
                                2종<br/>
                                보통
                            </td>
                            <td>
                                2종<br/>
                                소형
                            </td>
                            <td>
                                2종<br/>
                                원동기
                            </td>
                        </tr>
                    
                    </thead>
                    <tbody>

                
                    <tr>
                            <th>
                                시험자격
                            </th>
                            <td className="text-center border_right">
                                19세이상<br/>
                                1,2종 보통<br/>
                                면허취득후<br/>
                                1년 경과한 자<br/>
                            </td>
                            <td colSpan="3" className="text-center border_right">
                                18세이상
                            </td>
                            <td className="text-center">
                                16세이상
                            </td>
                        </tr> 
                        <tr>
                            <th>
                                준비물
                            </th>
                            <td colSpan="5">
                                · 응시원서, 6개월 이내 촬영한 칼라사진3.5X4.5cm(3X4cm 가능) 
                                3매(허용되는 사진규격), <br/><br/>
                                · 신분증 (신분증 인정 범위 참고)
                            </td>
                        
                        </tr> 
                        <tr>
                            <th>
                                시험시간
                            </th>
                            <td colSpan="5" className="text-center">
                                40분
                            </td>
                        
                        </tr> 
                        <tr>
                            <th>
                                수수료
                            </th>
                            <td colSpan="2" className="text-center border_right">
                                10,000원
                            </td>
                            <td colSpan="3" className="text-center">
                            8,000원
                            </td>
                        </tr> 
                        <tr>
                            <th>
                                시험유형
                            </th>
                            <td colSpan="5" className="text-center">
                                객관식(선다형)
                            </td>
                        
                        </tr> 
                        <tr>
                            <th>
                                시험내용
                            </th>
                            <td colSpan="5" className="text-center">
                                · 안전운전에 필요한 교통법규, 안전운전방법, 예절 등
                            </td>
                        
                        </tr> 
                        <tr>
                            <th>
                                합격기준
                            </th>
                            <td colSpan="2" className="text-center border_right">
                                70점이상 시 합격
                            </td>
                            <td colSpan="3" className="text-center">
                                60점이상 시 합격
                            </td>
                        </tr> 

                        <tr>
                            <th>
                                결과발표
                            </th>
                            <td colSpan="5">
                                · 시험완료 후 컴퓨터 모니터에 합격여부 표시<br/>
                                · 응시원서에 합격·불합격날인 후 본인에게 재발급<br/>
                                · 응시자는 본인원서 및 합격·불합격 날인여부 확인
                            </td>
                        </tr> 
                        <tr>
                            <th>
                                주의사항
                            </th>
                            <td colSpan="5">
                                · 학과 최초응시일로부터 1년이내 학과시험에 합격하여야 합니다.<br/>
                                (1년이내 불합격시 신규접수)<br/>
                                · 학과합격시 합격일로부터 1년이내 기능시험에 합격하여야 합니다.<br/>
                                (1년이내 불합격시 신규접수)
                            </td>
                        </tr> 
                        <tr>
                            <th>
                                응시가능 <br/>
                                    언어
                            </th>
                            <td colSpan="5">
                                영어, 중국어, 러시아어, 일본어, 베트남어, 태국어,<br/> 
                                인도네시아어, 몽골어, 캄보디아어, 타갈로그어
                            </td>
                        </tr> 
                        <tr>
                            <th>
                                비문해자를<br/>
                                위한 PC학과<br/> 
                                    시험    
                            </th>
                            <td colSpan="5">
                                · 시험문제와 보기를 음성으로 들을 수 있는 PC학과시험 시간<br/>
                                총 80분 - 민원실에서 접수 시 신청 가능
                            </td>
                        </tr> 
                        <tr>
                            <th>
                                청각장애인을<br/>
                                위한 수화<br/>
                                PC학과 시험
                            </th>
                            <td colSpan="5">
                                · 청각장애인이면서 비문해자를 위한 수화로 보는 PC학과시험 시간<br/>
                                총 80분 - 민원실에서 접수 시 신청 가능
                            </td>
                        </tr> 
                    </tbody>
                </table>

                </div>
             ))
    
    const menuChange=(e)=>{
        let inputs=document.getElementsByTagName('input')
        for(let i=0; i<inputs.length; i++){
            inputs[i].className="button button-1 bg-white c-text1"
        }
        
        
        if(e.target.id==="1"){
            e.currentTarget.className="button button-1 c-white bg-orange2"
            setView((
                <div className="guide_text_box bg-white">
                <h2 className="mb-10">학과시험</h2>
                <p>· 전국 운전면허 시험장에서 평일 09:00 ~ 17:00 
                    응시접수 가능<br/>
                    (비문해자, 청각 장애인 학과 시험은 16:00까지 접수)</p>
                <p className="mb-10">· PC학과시험은 당일 접수하여 당일 응시하므로 별도로 예
                    약하실 필요가 없습니다.</p>
                <table className="department_test_table">
                    <thead>
                        <tr>
                            <td>
                                종류
                            </td>
                            <td>
                                1종<br/>
                                대형,특수
                            </td>
                            <td>
                                1종<br/>
                                보통
                            </td>
                            <td>
                                2종<br/>
                                보통
                            </td>
                            <td>
                                2종<br/>
                                소형
                            </td>
                            <td>
                                2종<br/>
                                원동기
                            </td>
                        </tr>
                    
                    </thead>
                    <tbody>

                
                    <tr>
                            <th>
                                시험자격
                            </th>
                            <td className="text-center border_right">
                                19세이상<br/>
                                1,2종 보통<br/>
                                면허취득후<br/>
                                1년 경과한 자<br/>
                            </td>
                            <td colSpan="3" className="text-center border_right">
                                18세이상
                            </td>
                            <td className="text-center">
                                16세이상
                            </td>
                        </tr> 
                        <tr>
                            <th>
                                준비물
                            </th>
                            <td colSpan="5">
                                · 응시원서, 6개월 이내 촬영한 칼라사진3.5X4.5cm(3X4cm 가능) 
                                3매(허용되는 사진규격), <br/><br/>
                                · 신분증 (신분증 인정 범위 참고)
                            </td>
                        
                        </tr> 
                        <tr>
                            <th>
                                시험시간
                            </th>
                            <td colSpan="5" className="text-center">
                                40분
                            </td>
                        
                        </tr> 
                        <tr>
                            <th>
                                수수료
                            </th>
                            <td colSpan="2" className="text-center border_right">
                                10,000원
                            </td>
                            <td colSpan="3" className="text-center">
                            8,000원
                            </td>
                        </tr> 
                        <tr>
                            <th>
                                시험유형
                            </th>
                            <td colSpan="5" className="text-center">
                                객관식(선다형)
                            </td>
                        
                        </tr> 
                        <tr>
                            <th>
                                시험내용
                            </th>
                            <td colSpan="5" className="text-center">
                                · 안전운전에 필요한 교통법규, 안전운전방법, 예절 등
                            </td>
                        
                        </tr> 
                        <tr>
                            <th>
                                합격기준
                            </th>
                            <td colSpan="2" className="text-center border_right">
                                70점이상 시 합격
                            </td>
                            <td colSpan="3" className="text-center">
                                60점이상 시 합격
                            </td>
                        </tr> 

                        <tr>
                            <th>
                                결과발표
                            </th>
                            <td colSpan="5">
                                · 시험완료 후 컴퓨터 모니터에 합격여부 표시<br/>
                                · 응시원서에 합격·불합격날인 후 본인에게 재발급<br/>
                                · 응시자는 본인원서 및 합격·불합격 날인여부 확인
                            </td>
                        </tr> 
                        <tr>
                            <th>
                                주의사항
                            </th>
                            <td colSpan="5">
                                · 학과 최초응시일로부터 1년이내 학과시험에 합격하여야 합니다.<br/>
                                (1년이내 불합격시 신규접수)<br/>
                                · 학과합격시 합격일로부터 1년이내 기능시험에 합격하여야 합니다.<br/>
                                (1년이내 불합격시 신규접수)
                            </td>
                        </tr> 
                        <tr>
                            <th>
                                응시가능 <br/>
                                    언어
                            </th>
                            <td colSpan="5">
                                영어, 중국어, 러시아어, 일본어, 베트남어, 태국어,<br/> 
                                인도네시아어, 몽골어, 캄보디아어, 타갈로그어
                            </td>
                        </tr> 
                        <tr>
                            <th>
                                비문해자를<br/>
                                위한 PC학과<br/> 
                                    시험    
                            </th>
                            <td colSpan="5">
                                · 시험문제와 보기를 음성으로 들을 수 있는 PC학과시험 시간<br/>
                                총 80분 - 민원실에서 접수 시 신청 가능
                            </td>
                        </tr> 
                        <tr>
                            <th>
                                청각장애인을<br/>
                                위한 수화<br/>
                                PC학과 시험
                            </th>
                            <td colSpan="5">
                                · 청각장애인이면서 비문해자를 위한 수화로 보는 PC학과시험 시간<br/>
                                총 80분 - 민원실에서 접수 시 신청 가능
                            </td>
                        </tr> 
                    </tbody>
                </table>

                </div>
            ))
        }else if(e.target.id==="2"){
            e.currentTarget.className="button button-1 c-white bg-orange2"
            setView((
                <div className="guide_text_box bg-white">
                    <h2 className="mb-15">허용되는 사진 규격</h2>
                    <h3 className="fz-10 c-text11 mb-20">표준 사진 (이미지유형)</h3>
                    <img className="img_picture" alt="이미지" src={img_Standard_picture}/>
                    <h3 className="fz-14 fw-bold lh-15">인화 사진 제출 시 표준규격</h3>
                    <div className="mb-20">
                        <p className="fz-12 c-text11 lh-15">· 3.5cm × 4.5cm 탈모, 상반신, 무 배경, 6개월 이내 촬영한 컬러
                            사진
                        </p>
                        <p className="fz-12 c-text11 lh-15 mb-10">· 복사, 포토샵 등으로 수정하지 않은 사진
                        </p>
                        <p className="fz-12 c-text11 mb-5">
                            · 얼굴 방향은 정면을 응시하고 기울어지지 않은 사진
                        </p>
                        <p className="fz-12 c-text11 mb-10">
                            · 색안경 착용 등 눈을 가리지 않은 사진
                        </p>
                        <p className="fz-12 c-text11 mb-10">· 사진인화용지에 인화된 사진만 허용</p>
                        <p className="fz-12 c-text11">(일반 용지 인쇄 사진은 면허증 발급 시 해상도가 떨어짐)</p>
                    </div>
                    
                    <h3 className="fz-14 fw-bold lh-15 c-text11">홈페이지 이용 시(※ 면허소지자 홈페이지 접수 시)</h3>
                    <div>
                        <p className="fz-12 c-text11 lh-15">· 250킬로바이트(KB) 이하의 JPG파일로 저장
                        </p>
                        <p className="fz-12 c-text11 lh-15">· 가로 350, 세로 450픽셀</p>
                        <p className="fz-12 c-text11 lh-15 mb-5">· 아래 항목의 표준 규격을 준수할 것</p>
                        <p className="fz-12 c-text11 lh-15">  - 여권 사진의 경우, 외교부 여권과에서 제공하는<br/> 여권 사진 규격 
                            참고</p>
                    </div>
                </div>
                
            ))
        }else if(e.target.id==="3"){
            e.currentTarget.className="button button-1 c-white bg-orange2"
            
            setView((
                <div className="guide_text_box bg-white">
                    <h3 className="fw-bold fz-16 text-center c-text11 mb-20">본인 여부 확인을 위해 사용 가능한<br/> 
                        신분증명서 등의 종류</h3>
                    <div className="orange_box">
                        <p className="fz-15 c-white fw-bold">해당 증명서 자체만으로 신분확인이 가능한 신분 증명서 등</p>
                    </div>
                    <p className="mb-15">· 주민등록증(사진에 간인 후 주요 기재사항에 테이프가 
                        부착된 주민등록증 발급신청 확인서), 재외국민 주민등록
                        증, 운전면허증, 여권, 청소년증(유효기간 이내)             
                    </p>
                    <p className="mb-20">· 사진, 생년월일, 성명이 기재된 여타 공공기관 발행 신분증
                        등 예) 공무원증, 선원수첩, 국가기술자격증, 학생증(재학
                        증명서 첨부), 장애인복지카드, 전역증(전역 후 1년이내),
                        외국인등록증, 외국국적 동포 국내거소신고증, 국가보훈
                        처장(證) → 국가유공자증 등 10종
                    </p>
                    <p>· 교정공무원이 수용자를 대리할 경우 교정기관장이 발행한
                        수용 증명서
                    </p>
                    <p><span>
                        - 사진,생년월일,성명이 명확하게 기재되어 있고 공공기관의 장이
                        발행한 것에 한함 </span>
                    </p>
                    <p><span>
                        - 유효기간이 명시되어 있는 신분증명서인 경우 유효기간이 경과하
                        지 아니할 것(여권, 주민등록증 발급신청 확인서 등)
                </span>
                    </p>
                    <p className="mb-25"><span>
                        - 상기 제출한 신분증 상의 사진이 본인과 현저히 다르거나 손괴 되
                        어 본인 확인이 곤란한 경우 신분확인이 가능한 여타 신분증명서
                        등을 제출하도록 하되, 본인이 동의하는 경우 지문 조회 가능
                
                </span>
                    </p>
                    <div className="orange_box">
                        <p className="fz-15 c-white fw-bold">추가 증빙자료가 필요한 신분증의 종류 및 신분확인 절차
                            </p>
                    </div>
                    <p className="mb-15">· 사진, 생년월일, 이름이 식별곤란하거나 동일인 여부가 의
                        심되는 경우
                    </p>
                    <p className="mb-10">
                        · 신분확인이 가능한 신분증명서 등을 제출토록 하되, 사진, 
                        생년월일, 이름의 식별이 어렵거나 위조 의심, 사진이 본인
                        과 다른 사람으로 판단되는 경우 등 본인확인이 어려운 경
                        우 본인의 동의를 얻어 지문 조회 등의 방법으로 신분확인             
                    </p>
                    <p className="mb-20">· 주민등록증이 발급되지 아니하여 지문 정보가 없는 미성년
                        자가 원동기장치자전거 면허시험에 응시하는 경우
                    </p>
                    <p className="mb-20">· 사진, 생년월일, 이름이 식별곤란하거나 동일인 여부가 의
                        심되어 지문 조회 하였음에도 지문 훼손 또는 전산 상 지문
                        누락으로 확인되지 않는 경우 : '사진, 생년월일, 이름'이 
                        기재된 신분증 외에 주민등록번호 뒷자리를 확인할 수 있
                        는 국가기관 발행의 증빙자료를 제출 받아 신분확인
                    </p>
                    <p className="fw-bold mb-15">· *신분증명서 미소지자의 경우 본인 동의 시 지문으로 조회
                        가능하나, 지문이 조회되지 않는 경우 신분증명서 제출을
                        요구할 수 있습니다.
                </p>
                <p className="fw-bold">· *단, 시도지부 안전교육부에서는 지문 조회가 불가합니다.</p>
                </div>

            ))
        }
    }
    return (
        <>
        <div className="wrap_gnb_test_info–exemption bg-gray1">
            <div className="is-flex x-between mx-20">
                <input onClick={menuChange} id="1"  type="button" value="학과시험 정보"
                className="button button-1 c-white bg-orange2"/>

                <input onClick={menuChange} id="2" type="button" value="허용되는 사진 규격"
                className=" button button-1 bg-white c-text1"/>
            </div>
            <div className="is-flex x-between mb-15 mx-20">

                <input onClick={menuChange} id="3" type="button" value="신분증 인정 범위"
                className="button button-1 bg-white c-text1"/>

                <input  type="button" value=""
                className=" button button-1 bg-white c-text1"/>
            </div>
            {view}    
        </div>
        </>
    )
}

export default DetailTab
