import React, { useEffect, useState } from 'react'
import answer_sheet from'../../../image/publishing_Img/answer-sheet.svg'
import icon_test_img from'../../../image/publishing_Img/icon_test_img.svg'
import Icon_tri_dropleft from'../../../image/publishing_Img/Icon-tri-dropleft.png'

import Icon_tri_dropright from'../../../image/publishing_Img/Icon-tri-dropright.png'
import {sampleQuestions} from'./TestData'
//테스트용 모달
import MockTestModals from'./MockTestModals'
import numberPad from './numberpad'


///이후 문제를 랜덤으로 가져올경우 이 컴포넌트에서 문제 랜덤 배열을 localstorage에 저장하고 이페이지이전(logopage나 menu페이지)에서 localstorage에 있는 문제 배열을 지운다.
//타이머 멈추기
let stopTimer=false 
function MockTest() {
    //문장형 1답    18
    //문장형 2답    3
    //사진형        6
    //그림형        8
    //표지판        4
    //동영상        1
     //기출문제 정보
     const [questionNumber,setQuestionNumber]=useState(0)
     
     let randomExam=[
        {
            number:"",
            score: "",
            type: "",
            right: [],
            query: "",
            queryImg: "",
            branches: [
              { word: "", img: "" },
              { word: "", img: "" },
              { word: "", img: "" },
              { word: "", img: "" }
            ],
            explanation: "",
            explanationMedia: ""
          }
     ]
     const [savePointExamSample,setSavePointExamSample]=useState()
     const [questionView,setQuestionView]=useState(randomExam)
     useEffect(() => {
        randomExam=[]
        if(!localStorage.getItem('numbers')){

        
            let array=[]
            let numbers=[]
            //일단 문장현 1답 문제중 18문제를 난수를 통해 임의로 가져온뒤 해당 문제의 number를 가져온다..
            /////////////////문장형 1///////////////////
            console.log(sampleQuestions);
            for(let i=0; i<sampleQuestions.question.length;i++){
            
                if(sampleQuestions.question[i].type.includes("문장형")&&sampleQuestions.question[i].right.length===1){
                    array.push(sampleQuestions.question[i])
                   
                }
            
            }
            let min =Math.ceil(0)
            
            let max = Math.floor(array.length);
            let ran=""
            for(let i=0; i<18;i++){
                ran=Math.floor(Math.random() * (max - min)) + min
               
                if(!numbers.includes(array[ran].number)){                    
                    numbers.push(array[ran].number)
                }else{
                    i--
                }
                
            }
            console.log(numbers)
            console.log(array)
            
     
            ////////문장형 2
            array=[]
            for(let i=0; i<sampleQuestions.question.length;i++){
            
                if(sampleQuestions.question[i].type.includes("문장형")&&sampleQuestions.question[i].right.length===2){
                    array.push(sampleQuestions.question[i])
                }
        
            }
            min =Math.ceil(0)
            max = Math.floor(array.length);
            ran=""
            for(let i=0; i<3;i++){
                ran=Math.floor(Math.random() * (max - min)) + min
                console.log(ran)
                
                if(!numbers.includes(array[ran].number)){
                    numbers.push(array[ran].number)
                }else{
                    i--
                    
                    
                    
                }
            }
            console.log(numbers)
            console.log(array)
            ///////////사진형
            array=[]
            for(let i=0; i<sampleQuestions.question.length;i++){
            
                if(sampleQuestions.question[i].type.includes("사진형")){
                    array.push(sampleQuestions.question[i])
                }

        
            }
            min =Math.ceil(0)
            max = Math.floor(array.length);
            ran=""
            
            for(let i=0; i<6;i++){
                ran=Math.floor(Math.random() * (max - min)) + min
                console.log(ran)

                if(!numbers.includes(array[ran].number)){

                    
                    numbers.push(array[ran].number)
                }else{
                    i--
                    
                    
                    
                }
                
            }
            console.log(numbers)
            console.log(array)
            /////일러스트
            array=[]
            for(let i=0; i<sampleQuestions.question.length;i++){
            
                if(sampleQuestions.question[i].type.includes("일러스트")){
                    array.push(sampleQuestions.question[i])
                }
        
            }
            min =Math.ceil(0)
            max = Math.floor(array.length);
            ran=""
            for(let i=0; i<8;i++){
                ran=Math.floor(Math.random() * (max - min)) + min
                console.log(ran)
                if(!numbers.includes(array[ran].number)){
                    numbers.push(array[ran].number)
                }else{
                    i--
                    
                    
                    
                }
            }
            console.log(numbers)
            console.log(array)
            ////표지판
            array=[]
            for(let i=0; i<sampleQuestions.question.length;i++){
            
                if(sampleQuestions.question[i].type.includes("안전표지")){
                    array.push(sampleQuestions.question[i])
                }
        
            }
            min =Math.ceil(0)
            max = Math.floor(array.length);
            ran=""
            for(let i=0; i<4;i++){
                ran=Math.floor(Math.random() * (max - min)) + min
                console.log(ran)
                if(!numbers.includes(array[ran].number)){
                    numbers.push(array[ran].number)
                }else{
                    i--
                    
                    
                    
                }
            }
            console.log(numbers)
            console.log(array)
            /////동영상
            array=[]
            for(let i=0; i<sampleQuestions.question.length;i++){
            
                if(sampleQuestions.question[i].type.includes("동영상")){
                    array.push(sampleQuestions.question[i])
                }
        
            }
            min =Math.ceil(0)
            max = Math.floor(array.length);
            ran=""
            for(let i=0; i<1;i++){
                ran=Math.floor(Math.random() * (max - min)) + min
                console.log(ran)
                if(!numbers.includes(array[ran].number)){
                    numbers.push(array[ran].number)
                }else{
                    i--
                }
                
            }
               console.log(numbers);
               console.log(array);
            //랜덤 문제 40문 완료 로컬에저장 매인화면이나 모의고사 랜딩페이지에 가면 로컬에서 삭제
            localStorage.setItem("numbers",JSON.stringify(numbers))
            
            //난수를 기준으로 문제뽑기
            for(let i=0; i<numbers.length; i++){
                randomExam.push(sampleQuestions.question[numbers[i]-1])
            }
            setQuestionView(randomExam)
            console.log(numbers)
        }else{
            let numbers=JSON.parse(localStorage.getItem('numbers'))
               //난수를 기준으로 문제뽑기
               for(let i=0; i<numbers.length; i++){
                randomExam.push(sampleQuestions.question[numbers[i]-1])
            }
            setQuestionView(randomExam)
            setSavePointExamSample(randomExam)
            
        }
    }, [])
    

    //문제 이미지/동영상
    // const [media, setMedia] = useState("")
    

    //타이머 멈추기 확인 state
    const [isStop,setIsStop]=useState(false)
    //오탑 정보
    const [wrong] =useState([])


    
    //내가 풀었던 문제 정보
    const [myAnswer]=useState([])
    
    //모의고사 
    //보기 체크 카운트
    let checkLength=document.querySelectorAll('input[name="branches"]:checked').length
    //보기 체크 카운트 다시하기
    function setCheckLength(){
        checkLength=document.querySelectorAll('input[name="branches"]:checked').length
    }
    //항목이동 MODAL visible
    const [visible,setVisible]=useState("none")
    //문재채점버튼
    const [scoringButton,setScoringButton]=useState("")
    //모달 기능 스테이트
    const [isGoal, setIsGoal]=useState("")
    //문제 해설 버튼
    const [explain,setExplain]=useState("")
    
    //넥스트 버튼
    
    
    const [goToNextButton,setGoToNextButton]=useState("")
    //정답/오답
    const [isRightAnswer,setIsRightAnswer]=useState("")
    const [scoredMessage, setScoredMessage]=useState("")
    //답안지
    const omr=()=>{
        setVisible("block")
        setIsGoal("omr")
        if(updateBool){
            setUpdateBool(false)
        }else{
            setUpdateBool(true)
        }
    }
    const [omrButton,setOmrButton]=useState((
        <label onClick={omr} className="answer-sheet">
            <img alt="답안지 이미지" src={answer_sheet}/>
            <p >답안지</p>
        </label>
    ))
    //타이머
    const [time,setTime] =useState({ second : numberPad(0,2), minit : numberPad(40,2)})
    //문재 오답노트 변수
    const [checkQuestions, setCheckQuestions]=useState(false)
    //채점 했니 안했니?
    const [isScoring,setIsScoring]=useState(false)
    useEffect(() => {
       let timer= setInterval(() => {
            if(isStop){
                clearInterval(timer)
                stopTimer=true
            }
            if(time.minit>numberPad(0,2)||time.second>numberPad(0,2)){
                
                if(!stopTimer){
                    
                    time.second=parseInt(time.second-1)
                }
            }else{

              clearInterval(timer)
              alert("재한시간이 끝났습니다.")
              setVisible("block")
              setIsGoal('scoring')
              
            }

            if (time.second < 0) {
                time.second=59
                time.minit=parseInt(time.minit-1)
            }
            if(time.minit<0){
                time.minit=0
            }
            setTime({second:numberPad(time.second,2), minit:numberPad(time.minit,2)})
        }, 1000);
        //타이머
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isStop])
    
    //문제가 바뀔때마다 내가 푼 문제의 데이터를 불러와 강조를 해준다.
    
    useEffect(() => {
        
       
        //페이지를 넘길때마다 선택한 보기의 갯수를 초기화
        //문제 이동시 내가 푼 문제에 대한 정보를 보여주기전에 초기화시킨다.///////
        //내가 푼 문제가 아닐 경우에 실행
       
        //체크박스 풀기 라벨 강조 풀기
        let check=document.getElementsByName('branches')
        for(let i =0; i<check.length; i++){
            check[i].checked=false
        }
        

        let numberBox=document.getElementsByName('numberBox')
        //문제 체크 풀기
        for(let i=0; i<numberBox.length; i++){
            numberBox[i].className="number_box border_70 c-text2"
        }
        let wordBox=document.getElementsByName('wordBox')
        for(let i=0; i<wordBox.length; i++){
            wordBox[i].className="ml-10 fz-14 lh-14 c-text2"
        }

        

        //정답 체크 풀기
        let checkRight=document.getElementsByName('checkRight')
        for(let i=0; i<checkRight.length; i++){
            
            // document.getElementsByName("checkRight")[i]
            document.getElementsByName("checkRight")[i].className="answer_box"
        }
        /////////////////////////////////////////////////////////////////////////
        
        //초기화된 엘리먼트에 내가 푼 정보를 가져와 다시 표현해준다.
        
        
        
        for(var i =0; i<myAnswer.length; i++) {
                if(myAnswer[i].number===questionView[questionNumber].number){
                    for(let a=0; a<myAnswer[i].right.length;a++ ){
                        
                        //내가 선택한 정보를 그대로 가져온다.
                        document.getElementsByName('branches')[(myAnswer[i].right[a]-1)].checked=true
                        document.getElementsByName('numberBox')[(myAnswer[i].right[a]-1)].className="number_box c-white bg-orange3"
                        document.getElementsByName('wordBox')[(myAnswer[i].right[a]-1)].className="ml-10 fz-14 lh-14 c-orange3"
                        
                    }
                }
            }
            // document.getElementById(e.target.id+"selectNumber").className="number_box"
            // document.getElementById(e.target.id+"selectWord").className="ml-10 fz-14 lh-14 c-text2"
            
        let solved=""
        //내가 푼 문재가 아닐경우 해설을 지운다.
        for(let i=0; i<myAnswer.length; i++){
            if(myAnswer[i].number===questionView[questionNumber].number){
                solved=true
                break;
            }
        }
        if(solved){
            
            setGoToNextButton((<button onClick ={goToNextHandler}>다음으로</button>))
        }else{
            setExplain("")
            setGoToNextButton("")
        }
        /////////////////////////////////////////////////////////
    
        //페이지 이동시 정답/오답을 가져와서 화면에 표시///////////////////
        //체점해야하는경우
          if(checkQuestions){
            
            for(let i=0; i<myAnswer.length;i++){
                if(myAnswer[i].number===questionView[questionNumber].number){
                    //풀었을경우에만 style을 넣는다.
                    for(let i=0; i<questionView[questionNumber].right.length; i++){
                        // document.getElementsByName("checkRight")[i]
                        let index=questionView[questionNumber].right[i]
                        document.getElementsByName("checkRight")[index-1].className="answer_box bg_orange2"
                    }
                    if(myAnswer[i].isCorrect){
                        setIsRightAnswer("정답")
                    }else{
                        setIsRightAnswer("오답")
                        setExplain((   
                            <>
                                <p className="c-text11 fz-12">해설</p>
                                <div className="bg-gray1 grary_box">
                                    <p className="c-text1 fz-14 fw-bold">정답 : {questionView[questionNumber].right.map((value)=>
                                            value+"번 "
                                            )}
                                    </p>
                                    <p  className="c-text1 fz-12 lh-14">
                                        {questionView[questionNumber].explanation}
                                    </p>
                                    <div style={{textAlign:'center'}}>
                                        {
                                            questionView[questionNumber].explanationMedia!==""? 
                                            <img alt="해설사진" style={{width:"40%",height:"40%",display:'inline-block'}} src={require(`../../../image/examMedia/${questionView[questionNumber].explanationMedia}`).default}/>
                                            :
                                            ""
                                        }
                                    </div>
                                </div>
                            </>
                        ))
                    }
                }
            }

            //채점이면 다음버튼과 채점버튼을 지운다. 지운다.
            // eslint-disable-next-line no-undef
            setGoToNextButton("")
            // setScoringButton("")
        }
        //채점이 완료되면 일단 모든 문제에 대해서 정답과 해설을 노출한다.
       if(isScoring){
           //답안지를 지운다.

            for(let i=0; i<questionView[questionNumber].right.length; i++){
                // document.getElementsByName("checkRight")[i]
                let index=questionView[questionNumber].right[i]

                document.getElementsByName("checkRight")[index-1].className="answer_box bg-orange2"
            }
            //내가 안푼 문제가 있으면 오답이라고 노출
            for(let i=0; i<myAnswer.length; i++){

                if(myAnswer[i].number!==questionView[questionNumber].number){
                    // eslint-disable-next-line no-undef
                    setIsRightAnswer("오답")    
                    setExplain((
                        <>
                        <p className="c-text11 fz-12">해설</p>
                        <div className="bg-gray1 grary_box">
                            <p className="c-text1 fz-14 fw-bold">정답 : {questionView[questionNumber].right.map((value)=>
                                    value+"번 "
                                    )}
                            </p>
                            <p className="c-text1 fz-12 lh-14">
                                {questionView[questionNumber].explanation}
                                <img alt={questionView[questionNumber].explanationMedia?"해설사진":null} src={questionView[questionNumber].explanationMedia}/>
                            </p>
                        </div> 
                        </> 

                    ))
                }
            }
            
            if(myAnswer.length===0){
                for(let i=0; i<questionView.length; i++){
                    myAnswer.push({isCorrect:false})
                }
            }
          
            if(!myAnswer[questionNumber]){
                
                setExplain((
                    <>
                    <p className="c-text11 fz-12">해설</p>
                    <div className="bg-gray1 grary_box">
                        <p className="c-text1 fz-14 fw-bold">정답 : {questionView[questionNumber].right.map((value)=>
                                value+"번 "
                                )}
                        </p>
                        <p className="c-text1 fz-12 lh-14">
                            {questionView[questionNumber].explanation}
                            <img alt={questionView[questionNumber].explanationMedia?"해설사진":null} src={questionView[questionNumber].explanationMedia}/>
                        </p>
                    </div> 
                    </>

                ))
              
            }else if(!myAnswer[questionNumber].isCorrect||myAnswer[questionNumber].isCorrect===undefined){
                setExplain((
                    <>
                    <p className="c-text11 fz-12">해설</p>
                    <div className="bg-gray1 grary_box">
                        <p className="c-text1 fz-14 fw-bold">정답 : {questionView[questionNumber].right.map((value)=>
                                value+"번 "
                                )}
                        </p>
                        <p className="c-text1 fz-12 lh-14">
                            {questionView[questionNumber].explanation}
                            <img alt={questionView[questionNumber].explanationMedia?"해설사진":null} src={questionView[questionNumber].explanationMedia}/>
                        </p>
                    </div> 
                    </>

                ))
            }else{
                setExplain("")
            }
            //문제 푼게 없다면
            if(myAnswer.length===0){
                  setExplain((
                      <>
                    <p className="c-text11 fz-12">해설</p>
                    <div className="bg-gray1 grary_box">
                        <p className="c-text1 fz-14 fw-bold">정답 : {questionView[questionNumber].right.map((value)=>
                                value+"번 "
                                )}
                        </p>
                        <p className="c-text1 fz-12 lh-14">
                            {questionView[questionNumber].explanation}
                            <img alt={questionView[questionNumber].explanationMedia?"해설사진":null} src={questionView[questionNumber].explanationMedia}/>
                        </p>
                    </div>
                    </>
                  ))
            }
            setOmrButton((
                <label onClick={wrongAnswer} className="answer-sheet" >
                     <img alt="답안지 이미지" src={answer_sheet}/>
                    <p >오답노트</p>
                </label>
            ))
       }
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionNumber,checkQuestions,questionView,isScoring])
    
    ////////////////모달 펑션/////////////////////////////
    //오답노트 소환
    const wrongAnswer=()=>{
        setIsGoal('wrongAnswerNotes')
        setVisible('block')
    }
    //채점 모달 소환
    const scoreIngProcessing = ()=>{

        setIsGoal("scoring")
        setVisible("block")
        setCheckQuestions(true)
        setQuestionNumber(0)
        stopTimer=true
    }
     //나가기를 누르면 문제를 채점할지 그냥 계속 풀지 물어보는 모달을 띄운다.
     const confirmScoring=()=>{
        
        setVisible('block')
        setIsGoal('confirmScoring')
   }
   ///////////////////////////////////////////////////////

    //해설보기 노출
    // const explainHandler=()=>{
    //     setVisible('block')
    //     setIsGoal('explain')
    // }
     //문항이동 토글

     const [updateBool,setUpdateBool]=useState(true)

     const switchQuery=()=>{
        if(myAnswer.length===0){
            return alert("풀었던 문제가 없습니다.")
        }
        
        setVisible("block")
        setIsGoal("switchQuery")
        if(updateBool){
            setUpdateBool(false)
        }else{
            setUpdateBool(true)
        }
        
        
    }
   
    
    //다음문제 버튼
    const goToNextHandler=()=>{
        if((questionNumber+1)>=questionView.length){

            return alert("마지막 문제입니다.")
        }
        setQuestionNumber(questionNumber+1)
    }
    
    
    //문제 이동--내일 할것 이동할 때 내가 만약 풀었던 문제면 채크박스 채크 및 폰트 강조 해주기
    //내가 풀었던 문제만 다음으로 넘어 갈 수 있다.
    const ChangeView=(where)=>{
        if(where==='back'){
            
            //이전
            if((questionNumber+1)===1){
                return alert("첫문제 입니다.")
            }
            setQuestionNumber(questionNumber-1)    
            
        }else if(where==='next'){
            
            if((questionNumber+1)>=questionView.length){
                
                return alert("마지막 문제입니다.")
            }
            
            //내가 풀었던 문제만 이동가능 
            // let isGo=false
            // if(myAnswer.length===0){
            //     //내가 답번한게 없으면 앞으로 못간다.
            // }else{
            //     for(let i =0; i<myAnswer.length;i++){
            //         if(myAnswer[i].number===questionView[questionNumber+1].number){
            //             isGo=true
            //             break;
            //         }
            //     }
            // }
            // //isGo가false면 접근 불가능
            // //채점완료시 모든 문제에 접근이 가능하도록
            // if(!isScoring){
            //     if(!isGo){
            //         return alert("풀지않은 문제는 접근 할 수 없습니다.")
            //     }
            // }
            

            setQuestionNumber(questionNumber+1)    
        }
        
    }
    
    
    //오답노트 view 펑션
    const checkQuestionsHandler = (isCheck)=>{
        setScoredMessage("채점완료")
        
        //전채 문제 오답노트 요청
        if(isCheck==='all'){
            if(myAnswer.length===0){
                setVisible('none')
               return alert("푼 문제가 없습니다.")
            }
            //문재 채점 허락
            setQuestionView(savePointExamSample)
            setCheckQuestions(true)
            //처음문제로 돌아간다
            setQuestionNumber(0)
            setVisible('none')
            
           
              
        }else if(isCheck==='partOfWrong'){

            //내가 틀린것만 보려면?
            //myAnwser에서 false인 문제만 화면상에 보여준다.
            //일단 현재 화면의 문제정보를 지운다
            setCheckQuestions(true)
            setQuestionNumber(0)
            setVisible('none')
            //틀린문제로 화면에 출력되는 정보 바꾸기
            console.log(wrong.length+"몇개틀렸냐? 현재 넘버"+questionNumber)
            if(myAnswer.length===0){
                alert("푼 문제가 없습니다.")
            }else{
                //틀린게 0일경우 그래도 정답인 경우가 있으니
                if(wrong.length===0){
                    //정답인문제를 구해서 뺸다
                  
                    for(let i=0; i<myAnswer.length; i++){
                        if(myAnswer[i].isCorrect){
                            questionView.splice(questionView.findIndex((item,index)=>item.number===myAnswer[i].number),1)
                            console.log(questionView)
                        }
                    }
                }else{
                    console.log(wrong)
                    setQuestionView(wrong)
                    
                }
                  
            }
        }
    }
    

    //문재풀기 펑션
    const solve= (e)=>{
        
        //선택 할 수 있는 총 문항수
        let selectLimit=questionView[questionNumber].right.length
        //선택한 보기의 check name 가져오기
        let check=document.getElementsByName('branches')
        //몇번 몇번 선택했는가?
        let currentSelect=""
        //몇번 선택한 정답 배열에 담기
        let selectArray=[]

       //선택한 보기 선택했을때
       if(e.target.checked){
                //시간이 완료되면 못품
            if(time.minit===numberPad(0,2)&&time.second===numberPad(0,2)){
                    e.target.checked=false
                    
                    return alert("제한시간이 완료되었습니다")
            }

            //채점일경우 문제선택을 못하게한다.
            if(checkQuestions||isScoring){
                    
                return e.target.checked =true
            }
             //보기 선택 갯수 보다 많을 때
            setCheckLength()
            if(checkLength>selectLimit){
                alert("선택할 수 있는 문제는 "+selectLimit+"개 입니다.")
                return e.target.checked=false
            }
            //선택한 문제 스타일

            document.getElementById(e.target.id+"selectNumber").className="number_box c-white bg-orange3"
            document.getElementById(e.target.id+"selectWord").className="ml-10 fz-14 lh-14 c-orange3"
            //선택한 문제 담기(name의 순서대로 담기 때문에 자동으로 오름차순이 된다.)
            for(let i=0; i<check.length; i++){
                
                if(check[i].checked){
                    if(currentSelect===""){
                        currentSelect=check[i].value
                        
                    }else{
                        currentSelect=currentSelect+","+check[i].value
                    }
                }
            }
            //선택한값 배열에 담기
            selectArray=currentSelect.split(",")
            //문제 답이 다 체크가 되었고
            if(selectArray.length===selectLimit){
                //만약 같은 문제에 대한 답을 바꿀때
               for(let i=0; i<myAnswer.length; i++){
                   //답안지의 배열중 현재 풀고있는 문자와 같은 number가지고 있으면 지운다.
                    if(myAnswer[i].number===questionView[questionNumber].number){
                        myAnswer.splice(i,1)
                    }
                }
                //그리고 바로 답채크///////////////////////////////////
                //일단 문제 해설 버튼을 알려주자.
                // setExplain((<button onClick ={explainHandler}>해설</button>))
                //다음으로 가는 버튼
                setGoToNextButton((<button onClick ={goToNextHandler}>다음으로</button>))
                //오답 정답 바로 채크
                let isCorrect=""
                console.log(selectArray,"내가고른거")
                console.log(questionView[questionNumber].right,"정답")
                for(let i=0; i<selectArray.length;i++){
                    if(parseInt(selectArray[i])===parseInt(questionView[questionNumber].right[i])){
                        isCorrect=true
                        
                    }else{
                        isCorrect=false
                        //오답일때마다 그값을 저장
                        wrong.push(questionView[questionNumber])
                        // console.log(wrong.length+" : 틀린거",wrong)
                        break;
                    }
                }
                //그리고 현재 문제에 대한 답을 다시 넣는다.
               myAnswer.push({
                    number:questionView[questionNumber].number,
                    right:selectArray,
                    isCorrect:isCorrect,
                    score:questionView[questionNumber].score,
                    sortnumber:questionNumber
                })

                if(questionNumber+1>=questionView.length){
                   //문재를 다 풀었을때 자동으로 채점
                   setIsGoal('scoring')
                   setVisible('block')
                   setIsStop(true)

                    ///이쪽에서 문제 푼 로그를 떤지자//////////////////////////////////
                }else{
                    setTimeout(() => {
                        
                        setQuestionNumber(questionNumber+1)
                    }, 300);
                  
                }
            }

       

        }else{
            //채점일경우 문제선택을 못하게한다.
            if(checkQuestions||isScoring){
                
                return e.target.checked =true
            }
            //채크박스룰 풀면 오답 정보 자르기
            wrong.splice(wrong.length-1,1)
            console.log(wrong.length,"틀린거")
            //다시한번 채크랭스를set해준다.
            setCheckLength()
            
            //체크받스를 풀경우에 문제 답이 다 채크가 안되있으므로 그냥 지운다.
            document.getElementById(e.target.id+"selectNumber").className="number_box c-text2 border_70"
            document.getElementById(e.target.id+"selectWord").className="ml-10 fz-14 lh-14 c-text2"
            //만약 같은 문제에 대한 답을 바꿀때 있던 배열을 지운다.
            for(let i=0; i<myAnswer.length; i++){
                if(myAnswer[i].number===questionView[questionNumber].number){
                    myAnswer.splice(i,1)
                }
            }
                //체크처리 취소시에 화면에 안보여야 될 것들
                setExplain("")
                setGoToNextButton("")
                setIsRightAnswer("")
                for(let i=0; i<questionView[questionNumber].right.length; i++){
                    // document.getElementsByName("checkRight")[i]
                    let index=questionView[questionNumber].right[i]
                    document.getElementsByName("checkRight")[index-1].style.border=''
                }
       }
       //선택한 값
       console.log(selectArray,"선택한 값")
       
       
       
   

        if(myAnswer.length===questionView.length){
            
            setScoringButton((
                <button onClick={scoreIngProcessing}>
                    채점
                </button>
            ))

        }else{

            setScoringButton("")

        }
        
    }
   
    return (
        <div className="bg-white">
            <header className="gosu-header-relative">
                <div className="gosu-header is-flex y-center x-center bg-white">
                    <label className="back_1">
                        <p className="fz-14 c-orange3" onClick={confirmScoring}>이전</p>
                    </label>
                    <p className="c-orange3 fz-16 fw-bold">실전 모의고사!</p>
                    {omrButton}
                </div>
            </header>
            <article  className="wrap-main-practice_test-default bg-white">
                <div className="exam_name">
                    <div className="is-flex y-center">
                        <img alt="이미지" src={icon_test_img}/>
                        <p className="fz-16 c-white">
                        {questionView[questionNumber].type}{' '}
                        {questionView[questionNumber].branches.length}지 {' '}
                        {questionView[questionNumber].right.length}답 {' '}
                         ({questionView[questionNumber].score}점)
                        </p>
                    </div>
                    <p className="timer">
                        남은 시간 : {time.minit+":"+time.second}
                    </p>
                </div>
                {/* 정답 or 오답 체크 */}
                {/* {isRightAnswer} */}
                {/* 채점 끝났니? */}
                {/* {scoredMessage} */}
                {/* 문제푼거 */}
                <div className="exam_box">
                    <div className="mx-20 question">
                        <h1 className="c-orange2">{questionNumber+1}</h1>
                        <p className="c-text2">
                            {questionView[questionNumber].query}{' '}
                            <br/>
                            <span className="c-orange3 fz-12"> 
                                {questionView[questionNumber].right.length>1?`[정답을${questionView[questionNumber].right.length}개를 선택해주세요]`:``}
                            </span>
                        </p>
                    </div>
                    <div className="imageBox">
                        
                        {
                            questionView[questionNumber].queryImg!==""?
                                questionView[questionNumber].queryImg.includes("mp4")?
                                    
                                    <video playsInline autoPlay loop muted id="embed"   title="문제동영상"  src={require(`../../../image/examMedia/${questionView[questionNumber].queryImg}`).default} key={questionView[questionNumber].queryImg}/>
                                    :
                                    <img id="image" alt="문제이미지" src={require(`../../../image/examMedia/${questionView[questionNumber].queryImg}`).default} key={questionView[questionNumber].queryImg} />
                                    :
                                    ""
                        }
                    </div>
                </div>
                <div className="answer_group">
                        {questionView[questionNumber].branches.map((value,index)=>
                            <label key={index} name="checkRight" className="answer_box">
                                <input type="checkbox" style={{display:'none'}} value={index+1} id={index+1} name="branches" onClick={solve}/>
                                    <p id={index+1+"selectNumber"} name="numberBox" className="number_box border_70 c-text2">
                                        {index+1}
                                    </p>
                                    <p id={index+1+"selectWord"} name="wordBox" className="ml-10 fz-14 lh-14 c-text2">
                                        {value.word}
                                        {value.img===""?"":<img style={{width:"80px",height:'80px'}} alt={value.img?"보기이미지":null} src={require(`../../../image/examMedia/${value.img}`).default}/>   }
                                    </p>
                            </label>
                        )}
                    </div>
                <div className="explanation">
                            
                        {explain}
                        
                </div>
            </article>
            <footer className="gnb-exam">
                <label className="ex-left" onClick={()=>ChangeView("back")}>
                    <img alt="아이콘" src={Icon_tri_dropleft}/>
                    <p >{'<'}이전 문항</p> 
                </label>
                <label className="ex-center">
                    {/* <img alt="아이콘" src={Icon_tri_dropcenter}/> */}
                    <p style={{fontSize:'20px'}}>
                        
                        {questionNumber+1}/{questionView.length}
                    </p>
                    
                </label>
                <label className="ex-right" onClick={()=>ChangeView("next")}>
                    <img alt="아이콘" src={Icon_tri_dropright}/>
                    <p >이후 문항{'>'}</p>
                </label>
            </footer> 
                {/* 모달 컴포넌트 */}
                <MockTestModals 

                    isGoal={isGoal}
                    updateBool={updateBool}
                    setUpdateBool={setUpdateBool}
                    setIsGoal={setIsGoal}
                    myAnswer={myAnswer} 
                    questionView={questionView}
                    queryInfomation={questionView[questionNumber]}
                    //현재 문제
                    setQueryNumber={setQuestionNumber} 
                    //모달 노출 상태
                    visible={visible} 
                    //모달 노출 핸들링
                    setVisible={setVisible}
                    //걸린시간
                    time={time}
                    explain={questionView[questionNumber].explanation}
                    explainMedia={questionView[questionNumber].explanationMedia}
                    //시험 채점 전채 틀린것만
                    checkQuestionsHandler={checkQuestionsHandler}
                    //타이머 간접적으로 멈추기
                    setIsStop={setIsStop}
                    isScoring={isScoring}
                    setIsScoring={setIsScoring}
                    questionNumber={questionNumber}
                    randomExam={randomExam}
                    setQuestionView={setQuestionView}
                  
                    />
                
            
        </div>
    )
}

export default MockTest

