
export default function testCenterData(workSheet) {
  let XLSX = require("xlsx");
  var range = XLSX.utils.decode_range(workSheet["!ref"]);
  let centerInfoList = [];

  let centerInfo = {
    centerName: "",
    centerAddress: "",
    latitude: "",
    longitude: "",
    tel: "",
    homePage: "",
    distance: "",
    testInfo: {
      typeOneTwoNormalFunctionalTest: [],
      perpendicularParking: [],
      typeOneLargeFunctionalTest: [],
      typeTwoSmallAndMotorFunctionTest: [],
      roadDrivingA: [],
      roadDrivingB: [],
      roadDrivingC: [],
      roadDrivingD: [],
      recoveryCar: [],
      smallTowing: [],
      largeTowing: [],
      anotherMotor: [],
    },
  };

  let data = "";
  for (let i = 2; i < range.e.r + 2; i++) {
    data = workSheet["A" + i.toString()].v;

    centerInfo.centerName = data;

    data = workSheet["B" + i.toString()].v;

    centerInfo.centerAddress = data;

    data = workSheet["C" + i.toString()].v;

    centerInfo.latitude = data;

    data = workSheet["D" + i.toString()].v;

    centerInfo.longitude = data;

    if (!workSheet["E" + i.toString()]) {
      data = "1577-1120";
    } else {
      data = workSheet["E" + i.toString()].v;
    }

    centerInfo.tel = data;

    centerInfo.homePage = workSheet["F" + i.toString()].v;

    if (!workSheet["G" + i.toString()]) {
      data = null;
      centerInfo.testInfo.typeOneTwoNormalFunctionalTest = [];
      centerInfo.testInfo.typeOneTwoNormalFunctionalTest.push(data);
    } else {
      data = workSheet["G" + i.toString()].v;
      data = data.split(",");
      centerInfo.testInfo.typeOneTwoNormalFunctionalTest = [];
      for (let i = 0; i < data.length; i++) {
        centerInfo.testInfo.typeOneTwoNormalFunctionalTest.push(data[i]);
      }
    }

    if (!workSheet["H" + i.toString()]) {
      data = null;
      centerInfo.testInfo.perpendicularParking = [];
      centerInfo.testInfo.perpendicularParking.push(data);
    } else {
      data = workSheet["H" + i.toString()].v;
      data = data.split(",");
      centerInfo.testInfo.perpendicularParking = [];
      for (let i = 0; i < data.length; i++) {
        centerInfo.testInfo.perpendicularParking.push(data[i]);
      }
    }

    if (!workSheet["I" + i.toString()]) {
      data = null;
      centerInfo.testInfo.typeOneLargeFunctionalTest = [];
      centerInfo.testInfo.typeOneLargeFunctionalTest.push(data);
    } else {
      data = workSheet["I" + i.toString()].v;
      data = data.split(",");
      centerInfo.testInfo.typeOneLargeFunctionalTest = [];
      for (let i = 0; i < data.length; i++) {
        centerInfo.testInfo.typeOneLargeFunctionalTest.push(data[i]);
      }
    }

    if (!workSheet["J" + i.toString()]) {
      data = null;
      centerInfo.testInfo.typeTwoSmallAndMotorFunctionTest = [];
      centerInfo.testInfo.typeTwoSmallAndMotorFunctionTest.push(data);
    } else {
      data = workSheet["J" + i.toString()].v;
      data = data.split(",");
      centerInfo.testInfo.typeTwoSmallAndMotorFunctionTest = [];
      for (let i = 0; i < data.length; i++) {
        centerInfo.testInfo.typeTwoSmallAndMotorFunctionTest.push(data[i]);
      }
    }

    if (!workSheet["K" + i.toString()]) {
      data = null;
      centerInfo.testInfo.roadDrivingA = [];
      centerInfo.testInfo.roadDrivingA.push(data);
    } else {
      data = workSheet["K" + i.toString()].v;
      data = data.split(",");
      centerInfo.testInfo.roadDrivingA = [];
      for (let i = 0; i < data.length; i++) {
        centerInfo.testInfo.roadDrivingA.push(data[i]);
      }
    }

    if (!workSheet["L" + i.toString()]) {
      data = null;
      centerInfo.testInfo.roadDrivingB = [];
      centerInfo.testInfo.roadDrivingB.push(data);
    } else {
      data = workSheet["L" + i.toString()].v;
      data = data.split(",");
      centerInfo.testInfo.roadDrivingB = [];
      for (let i = 0; i < data.length; i++) {
        centerInfo.testInfo.roadDrivingB.push(data[i]);
      }
    }

    if (!workSheet["M" + i.toString()]) {
      data = null;
      centerInfo.testInfo.roadDrivingC = [];
      centerInfo.testInfo.roadDrivingC.push(data);
    } else {
      data = workSheet["M" + i.toString()].v;
      data = data.split(",");
      centerInfo.testInfo.roadDrivingC = [];
      for (let i = 0; i < data.length; i++) {
        centerInfo.testInfo.roadDrivingC.push(data[i]);
      }
    }

    if (!workSheet["N" + i.toString()]) {
      data = null;
      centerInfo.testInfo.roadDrivingD = [];
      centerInfo.testInfo.roadDrivingD.push(data);
    } else {
      data = workSheet["N" + i.toString()].v;
      data = data.split(",");
      centerInfo.testInfo.roadDrivingD = [];
      for (let i = 0; i < data.length; i++) {
        centerInfo.testInfo.roadDrivingD.push(data[i]);
      }
    }

    if (!workSheet["O" + i.toString()]) {
      data = null;
      centerInfo.testInfo.recoveryCar = [];
      centerInfo.testInfo.recoveryCar.push(data);
    } else {
      data = workSheet["O" + i.toString()].v;
      data = data.split(",");
      centerInfo.testInfo.recoveryCar = [];
      for (let i = 0; i < data.length; i++) {
        centerInfo.testInfo.recoveryCar.push(data[i]);
      }
    }

    if (!workSheet["P" + i.toString()]) {

      data = null;
      centerInfo.testInfo.smallTowing = [];
      centerInfo.testInfo.smallTowing.push(data);
    } else {

      data = workSheet["P" + i.toString()].v;
      console.log(data,"데이터")
      data = data.split(",");
      centerInfo.testInfo.smallTowing = [];
      for (let i = 0; i < data.length; i++) {
          centerInfo.testInfo.smallTowing.push(data[i]);
      }
    }
    
      
    

    if (!workSheet["Q" + i.toString()]) {
      data = null;
      centerInfo.testInfo.largeTowing = [];
      centerInfo.testInfo.largeTowing.push(data);
    } else {
      data = workSheet["Q" + i.toString()].v;
      data = data.split(",");
      centerInfo.testInfo.largeTowing = [];
      for (let i = 0; i < data.length; i++) {
        centerInfo.testInfo.largeTowing.push(data[i]);
      }
    }

    if (!workSheet["R" + i.toString()]) {
      data = null;
      centerInfo.testInfo.anotherMotor = [];
      centerInfo.testInfo.anotherMotor.push(data);
    } else {
      data = workSheet["R" + i.toString()].v;
      data = data.split(",");
      centerInfo.testInfo.anotherMotor = [];
      for (let i = 0; i < data.length; i++) {
        centerInfo.testInfo.anotherMotor.push(data[i]);
      }
    }
    centerInfoList.push(centerInfo);
    centerInfo = {
      centerName: "",
      centerAddress: "",
      latitude: "",
      longitude: "",
      tel: "",
      homePage: "",
      distance: "",
      testInfo: {
        typeOneTwoNormalFunctionalTest: [],
        perpendicularParking: [],
        typeOneLargeFunctionalTest: [],
        typeTwoSmallAndMotorFunctionTest: [],
        roadDrivingA: [],
        roadDrivingB: [],
        roadDrivingC: [],
        roadDrivingD: [],
        recoveryCar: [],
        smallTowing: [],
        largeTowing: [],
        anotherMotor: [],
      },
    };
  }
  console.log("테스트", JSON.stringify(centerInfoList));
  localStorage.setItem('testCenter',JSON.stringify(centerInfoList))
}
