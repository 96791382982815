import React from 'react'
import logo_black from'../../../image/publishing_Img/logo_black.png'
function SignUpSuccess(props) {
    
    const goToLogIn=()=>{
        props.history.push('/logInPage')
    }
    return (
        <div className="full-height">
            <header className="gosu-header-relative">
                <div className="gosu-header is-flex y-center x-center bg-white">
                    <label className="back_1">
                        <p onClick={()=>props.history.goBack()} className="fz-14 c-text11">이전</p>
                    </label>
                    <p className="c-text11 fz-16 fw-bold">회원가입</p>
                </div>
            </header>
            <article className="wrap-guest-4 bg-gray1">
            <div className="wrap_box bg-white">
                <div className="box_center">
                    <div className="logo is-flex x-center y-center mb-25">
                        <img alt="로고" src={logo_black}/>
                    </div>

                    <article className="text-center mb-40">
                        <p className="fz-24 c-text11 fw-bold mb-15">회원가입을 축하합니다!</p>
                        <p className="fz-14 c-text1">로그인 하여 서비스를 이용해주세요!</p>
                    </article>

                    <button className="button login_button bg-orange3" onClick={goToLogIn}>
                        <p className="fz-16 fw-bold c-white">로그인하기</p>
                    </button>
                </div>
            </div>
        </article>
    </div>
    )
}

export default SignUpSuccess
