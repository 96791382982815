import React, { useEffect, useState } from "react";
import axios from "axios";
import { url } from "../../../enum";

function FunctionalDrivingChild(props) {
  const [menuName, setmenuName] = useState("");
  const [roadVideo, setRoadVideo] = useState([]);
  const favorit = localStorage.getItem("favorit");

  // async function getVideos() {
  //   if(menuName)
  //   await axios
  //     .get(url + "/videos/technic")
  //     .then((res) => setTechnicVideo(res.data.rows));
  //   await axios
  //     .get(url + "/videos/drive")
  //     .then((res) => setRoadVideo(res.data.rows));
  //   await axios.get(url + "/videos/by-exams").then((res) => {
  //     setOMdata(res.data.OM);
  //   });
  // }
  function getVideos() {
    if (favorit != null) {
       axios
        .get(
          url +
            "/videos/videolist?type=" +
            props.menuName +
            "&location=" +
            favorit
        )
        .then((res) => setRoadVideo(res.data.rows));
    } else {
       axios
        .get(
          url +
            "/videos/videolist?type=" +
            props.menuName +
            "&location=" +
            "강남 운전면허 시험장"
        )
        .then((res) => setRoadVideo(res.data.rows));
    }
  }
  if (menuName != props.menuName) {
    setmenuName( props.menuName);
    getVideos();
  }
  useEffect(() => {
    getVideos();
    return () => {};
  }, []);

  return (
    <div className="video_list_box bg-white mt-10">
      <p className="fz-14 fw-bold mb-20">{props.menuName} 동영상</p>
      {roadVideo.length != 0 ? (
        roadVideo.map((value, index) => (
          <div key={index} className="video_box">
            <iframe
              className="iframe_1 mb-5"
              src={value.link}
              title={value.name}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen              
            />

            <p className="c-text1 fz-12 mb-20">{value.name}</p>
          </div>
        ))
      ) : (
        <p className="fz-14 fw-bold mb-20">최신 영상을 준비 중에 있습니다.</p>
      )}
    </div>
  );
  // else if (menuName === "제2종보통수동") {
  //     return (
  //         <div className="video_list_box bg-white mt-10">
  //             <p className="fz-14 fw-bold mb-20">제 2종 보통 수동 영상</p>
  //             {THMdata.map((value, index) =>
  //                 <div key={value.id} className="video_box">
  //                     <iframe
  //                         className="iframe_1 mb-5"
  //                         title={value.title}
  //                         src={value.link}
  //                         frameBorder="0"
  //                         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  //                         allowFullScreen />

  //                     <p className="c-text1 fz-12 mb-20">{value.title}</p>
  //                 </div>
  //             )}

  //         </div>

  //     )
  // } else if (menuName === "제2종보통자동") {
  //     return (
  //         <div className="video_list_box bg-white mt-10">
  //             <p className="fz-14 fw-bold mb-20">제 2종 보통 자동 영상</p>

  //             {TMdata.map((value, index) =>
  //                 <div key={value.id} className="video_box">
  //                     <iframe
  //                         className="iframe_1 mb-5"
  //                         title={value.title}
  //                         src={value.link}
  //                         frameBorder="0"
  //                         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  //                         allowFullScreen />

  //                     <p className="c-text1 fz-12 mb-20">{value.title}</p>
  //                 </div>
  //             )}

  //         </div>
  //     )
  // }
}

export default FunctionalDrivingChild;
