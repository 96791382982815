import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import logo from'../../../image/publishing_Img/logo_black.png'
import lorrygrey from '../../../image/publishing_Img/lorry-gray.png'
import lorryWhite from '../../../image/publishing_Img/lorry-white.png'
import ecocargray from '../../../image/publishing_Img/eco-car-gray.png'
import ecocarWhite from '../../../image/publishing_Img/eco-car-white.png'
import sedangary from'../../../image/publishing_Img/sedan-gray.png'
import sedanWhite from'../../../image/publishing_Img/sedan-white.png'
import axios from'axios'
import { url } from '../../../enum'
function LicenseModal(props) {

    const [license, setLicense]=useState("")
    function menu_btn(e){
        let btn_1 =document.getElementsByClassName("test_btn_1")[0];//OM
        let btn_2 = document.getElementsByClassName("test_btn_2")[0];//TM
        let btn_3 = document.getElementsByClassName("test_btn_3")[0];//THM
        let btn_4 = document.getElementsByClassName("test_btn_4")[0];//NEXT
        
        let select=e.target.getAttribute('license')
        setLicense(select)
        if(select==="OM"){
            //사진바꾸기
            btn_1.querySelector(".car_img img").src=lorryWhite;
            btn_2.querySelector(".car_img img").src=ecocargray;
            btn_3.querySelector(".car_img img").src=sedangary;
            //글씨바꾸기
            btn_1.querySelector(".grade").style="color:#fff";
            btn_2.querySelector(".grade").style="color:#6D6D6D";
            btn_3.querySelector(".grade").style="color:#6D6D6D";
            //점수색상 바꾸기
            btn_1.querySelector(".c-score").style="color:#fff";
            btn_2.querySelector(".c-score").style="color:#BCBCBC";
            btn_3.querySelector(".c-score").style="color:#BCBCBC";
            //버튼백그라운드 색상
            btn_1.style="background-color:#FECA28";
            btn_2.style="background-color:#fff";
            btn_3.style="background-color:#fff";
            btn_4.style="background-color:#FECA28";

        }else if(select==='TM'){
            //사진바꾸기
            btn_1.querySelector(".car_img img").src=lorrygrey;
            btn_2.querySelector(".car_img img").src=ecocarWhite;
            btn_3.querySelector(".car_img img").src=sedangary;
            //글씨바꾸기
            btn_1.querySelector(".grade").style="color:#6D6D6D";
            btn_2.querySelector(".grade").style="color:#fff";
            btn_3.querySelector(".grade").style="color:#6D6D6D";
            //점수색상 바꾸기
            btn_1.querySelector(".c-score").style="color:#BCBCBC";
            btn_2.querySelector(".c-score").style="color:#fff";
            btn_3.querySelector(".c-score").style="color:#BCBCBC";
            //버튼백그라운드 색상
            btn_1.style="background-color:#fff";
            btn_2.style="background-color:#FECA28";
            btn_3.style="background-color:#fff";
            btn_4.style="background-color:#FECA28";

        }else if(select==="THM"){
            //사진바꾸기
            btn_1.querySelector(".car_img img").src=lorrygrey;
            btn_2.querySelector(".car_img img").src=ecocargray;
            btn_3.querySelector(".car_img img").src=sedanWhite;
            //글씨바꾸기
            btn_1.querySelector(".grade").style="color:#6D6D6D";
            btn_2.querySelector(".grade").style="color:#6D6D6D";
            btn_3.querySelector(".grade").style="color:#fff";
            //점수색상 바꾸기
            btn_1.querySelector(".c-score").style="color:#BCBCBC";
            btn_2.querySelector(".c-score").style="color:#BCBCBC";
            btn_3.querySelector(".c-score").style="color:#fff";
            //버튼백그라운드 색상
            btn_1.style="background-color:#fff";
            btn_2.style="background-color:#fff";
            btn_3.style="background-color:#FECA28";
            btn_4.style="background-color:#FECA28";

        }
    }
    const next=()=>{
        if(license===""){
            return alert("시험 종류를 선택해주세요!!")
        }
        localStorage.setItem('license',license)

        if(localStorage.getItem('token')){
            axios.put(url+"/users/exam-type",{examType:localStorage.getItem('license'),token:localStorage.getItem('token')}).then(res=>{
                if(res.data.resCode!==0){
                    alert("회원정보가 바뀌지 않았습니다. 다시 로그인 후 시도해 주세요!")
                }
            })
        }
        props.history.push('/menu')
    }
   
    return (
            <div className="bg-gray1 full-height">
                <div className="gosu-header-relative select">
                    <div className="gosu-header is-flex x-center y-center bg-white">
                        <img className="logo" alt="로고" src={logo}/>
                    </div>
                </div>
                
                <div className="wrap_menu_select">
                    <div className="guide_box text-center mb-25 bg-white">
                        <p className="fz-16 fw-bold mb-15">면허 시험 종류 선택하기</p>
                        <p className="fz-14 c-text1">원하시는 시험 종류를 선택 후 다음 버튼을 눌러주세요</p>
                    </div>
                    <div className="group_box">
                        <label license="OM" className="button_box bg-white is-flex x-center y-center test_btn_1 " onClick={menu_btn}>
                            <div license="OM" className="text-center">
                                <div license="OM" className="car_img">
                                    <img license="OM" alt="1종보통 사진" src={lorrygrey}/>
                                </div> 
                                <p license="OM" className="fw-bold fz-16 c-text2 mb-10 grade">1종 보통</p>
                                <p license="OM" className="fz-16 c-score">(합격점수 70점)</p>
                            </div>
                        </label>
                        <label license="TM" className="button_box bg-white is-flex x-center y-center ml-20 button test_btn_2 "onClick={menu_btn}>
                            <div license="TM" className="text-center">
                                <div license="TM" className="car_img mb-5">
                                    <img license="TM" alt="2종 수동 사진" src={ecocargray}/>
                                </div>
                                <p license="TM" className="fw-bold fz-16 c-text2 mb-10  grade">2종 보통 수동</p>
                                <p license="TM" className="fz-16 c-score">(합격점수 60점)</p>
                            </div>
                        </label>
                        <label license="THM" className="button_box bg-white is-flex x-center y-center mt-20 button test_btn_3" onClick={menu_btn}>
                            <div license="THM" className="text-center">
                                <div license="THM" className="car_img mb-5">
                                    <img license="THM" alt="2종 보통 사진" src={sedangary}/>
                                </div>
                                <p license="THM" className="fw-bold fz-16 c-text2 mb-10  grade">2종 보통 자동</p>
                                <p license="THM" className="fz-16 c-score">(합격점수 60점)</p>
                            </div>
                        </label>
                    </div>
                </div>
                    <footer className="footer_button is-flex x-center y-center test_btn_4 bg-gray2" onClick={next}>
                        <p className="fz-20 c-white">다음</p>
                    </footer>
            </div>

            )
        

}

export default  withRouter (LicenseModal)
