import React, { useState } from 'react'


function DetailTab(props) {
    
    const [view,setView]=useState((
                <div className="guide_text_box bg-white">
                    <h2 className="mb-10">교육대상</h2>
                    <p className="mb-5">· 처음으로 운전면허를 취득하는 자<br/>
                        (원동기장치 자전거 응시자 포함)</p>
                    <p className="mb-15">· 군 운전 면허 소지자 중 일반 면허로 갱신하는 자</p>
                    <h3 className="fz-15 fw-bold mb-10">※ 교육대상자 제외</h3>

                    <p>· 운전면허가 있는 사람이 다른 종류의 운전면허를<br/>
                        취득하고자 하는 자<br/>
                        (원동기 장치 면허 소지자 포함)</p>
                    <p>· 외국면허 소지자로서 일반 면허로 갱신<br/>하고자
                        하는 자</p>
                    <p className="mb-10">· 국제 운전면허를 받고자 하는 자</p>

                    <h2 className="mb-10">교육시기</h2>
                    <p className="mb-20">· 학과시험 응시 전까지 언제나 가능</p>

                    <div className=" step_box">
                        <h1 className="fz-15 fw-bold c-text11 mb-10 ml-10">수강신청 절차</h1>

                        <table className="step_table">
                            <tbody>
                                <tr>
                                    <td>교육수강<br/>
                                        신청 및접수</td>
                                    <td>지문등록</td>
                                    <td>수강카드<br/>
                                        발급</td>
                                    <td>강의실<br/>
                                        입실</td>
                                    <td>교육개시<br/>
                                        및 종료</td>
                                    <td>영수증 및<br/>
                                        교육필증</td>
                                </tr>
                            </tbody>
                        </table>


                    </div>
                    <h2 className="mb-10">준비물</h2>
                    <p className="mb-10">· 주민등록증 또는 본인을 확인할 수 있는 신분증,<br/>
                        교육 수강료 무료</p>
                    <h2 className="mb-10">주의사항</h2>
                    <p>· 신규면허 발급 전까지 유효기간 이내에는 수회에<br/> 
                        걸쳐 면허시험에 응시하더라도 추가 교육 수강<br/>
                        의무 면제</p>
                </div>
             ))
    
    const menuChange=(e)=>{
        let inputs=document.getElementsByTagName('input')
        for(let i=0; i<inputs.length; i++){
            inputs[i].className="button button-1 bg-white c-text1"
        }
        
        
        if(e.target.id==="1"){
            e.currentTarget.className="button button-1 c-white bg-orange2"
            setView((
                <div className="guide_text_box bg-white">
                    <h2 className="mb-10">교육대상</h2>
                    <p className="mb-5">· 처음으로 운전면허를 취득하는 자<br/>
                        (원동기장치 자전거 응시자 포함)</p>
                    <p className="mb-15">· 군 운전 면허 소지자 중 일반 면허로 갱신하는 자</p>
                    <h3 className="fz-15 fw-bold mb-10">※ 교육대상자 제외</h3>

                    <p>· 운전면허가 있는 사람이 다른 종류의 운전면허를<br/>
                        취득하고자 하는 자<br/>
                        (원동기 장치 면허 소지자 포함)</p>
                    <p>· 외국면허 소지자로서 일반 면허로 갱신<br/>하고자
                        하는 자</p>
                    <p className="mb-10">· 국제 운전면허를 받고자 하는 자</p>

                    <h2 className="mb-10">교육시기</h2>
                    <p className="mb-20">· 학과시험 응시 전까지 언제나 가능</p>

                    <div className=" step_box">
                        <h1 className="fz-15 fw-bold c-text11 mb-10 ml-10">수강신청 절차</h1>

                        <table className="step_table">
                            <tbody>
                            <tr>
                                <td>교육수강<br/>
                                    신청 및접수</td>
                                <td>지문등록</td>
                                <td>수강카드<br/>
                                    발급</td>
                                <td>강의실<br/>
                                    입실</td>
                                <td>교육개시<br/>
                                    및 종료</td>
                                <td>영수증 및<br/>
                                    교육필증</td>
                            </tr>
                            </tbody>
                        </table>


                    </div>
                    <h2 className="mb-10">준비물</h2>
                    <p className="mb-10">· 주민등록증 또는 본인을 확인할 수 있는 신분증,<br/>
                        교육 수강료 무료</p>
                    <h2 className="mb-10">주의사항</h2>
                    <p>· 신규면허 발급 전까지 유효기간 이내에는 수회에<br/> 
                        걸쳐 면허시험에 응시하더라도 추가 교육 수강<br/>
                        의무 면제</p>
                </div>
            ))
        }else if(e.target.id==="2"){
            e.currentTarget.className="button button-1 c-white bg-orange2"
            setView((
                
                <div className="guide_text_box bg-white">
                <h2 className="mb-10">장소</h2>
                <p className="mb-15">· 전면허시헝장 내 신체검사실 또는 병원<br/>
                    (단, 문경, 강릉, 태백시험장은 신체검사실이 없으므로,<br/>
                    병원에서 받아와야 함) </p>
                <h2 className="mb-5">준비물 및 주의사항</h2>
                <h3 className="mb-15 fz-14 c_orange4">· 신분증, 6개월 이내 촬영한 컬러사진<br/>
                    (규격 3.5cm x 4.5xm 2매)</h3>
                    <h2 className="mb-10">수수료</h2>
                    <p>· 시험장 내 신체검사실 : 1종 대형 / 특수면허 7,000원,<br/>
                        기타 면허 6,000원
                    </p>
                    <p>· 건강검진결과내력서 등 제출 시 신체검사비 무료
                        (1종보통, 7년 무사고만 해당)</p>
                    <p>· 시험장 내 신체검사장 외의 병원인 경우 신체검사비는 
                        일반 의료수가에 따름</p> 
                </div>
            ))
        }else if(e.target.id==="3"){
            e.currentTarget.className="button button-1 c-white bg-orange2"
            
            setView((
                <div className="guide_text_box bg-white">
                    <h3 className="fw-bold fz-16 text-center c-text11 mb-20">본인 여부 확인을 위해 사용 가능한<br/> 
                        신분증명서 등의 종류</h3>
                    <div className="orange_box">
                        <p className="fz-15 c-white fw-bold">해당 증명서 자체만으로 신분확인이 가능한 신분 증명서 등</p>
                    </div>
                    <p className="mb-15">· 주민등록증(사진에 간인 후 주요 기재사항에 테이프가 
                        부착된 주민등록증 발급신청 확인서), 재외국민 주민등록
                        증, 운전면허증, 여권, 청소년증(유효기간 이내)             
                    </p>
                    <p className="mb-20">· 사진, 생년월일, 성명이 기재된 여타 공공기관 발행 신분증
                        등 예) 공무원증, 선원수첩, 국가기술자격증, 학생증(재학
                        증명서 첨부), 장애인복지카드, 전역증(전역 후 1년이내),
                        외국인등록증, 외국국적 동포 국내거소신고증, 국가보훈
                        처장(證) → 국가유공자증 등 10종
                    </p>
                    <p>· 교정공무원이 수용자를 대리할 경우 교정기관장이 발행한
                        수용 증명서
                    </p>
                    <p><span>
                        - 사진,생년월일,성명이 명확하게 기재되어 있고 공공기관의 장이
                        발행한 것에 한함 </span>
                    </p>
                    <p><span>
                        - 유효기간이 명시되어 있는 신분증명서인 경우 유효기간이 경과하
                        지 아니할 것(여권, 주민등록증 발급신청 확인서 등)
                </span>
                    </p>
                    <p className="mb-25"><span>
                        - 상기 제출한 신분증 상의 사진이 본인과 현저히 다르거나 손괴 되
                        어 본인 확인이 곤란한 경우 신분확인이 가능한 여타 신분증명서
                        등을 제출하도록 하되, 본인이 동의하는 경우 지문 조회 가능
                
                </span>
                    </p>
                    <div className="orange_box">
                        <p className="fz-15 c-white fw-bold">추가 증빙자료가 필요한 신분증의 종류 및 신분확인 절차
                            </p>
                    </div>
                    <p className="mb-15">· 사진, 생년월일, 이름이 식별곤란하거나 동일인 여부가 의
                        심되는 경우
                    </p>
                    <p className="mb-10">
                        · 신분확인이 가능한 신분증명서 등을 제출토록 하되, 사진, 
                        생년월일, 이름의 식별이 어렵거나 위조 의심, 사진이 본인
                        과 다른 사람으로 판단되는 경우 등 본인확인이 어려운 경
                        우 본인의 동의를 얻어 지문 조회 등의 방법으로 신분확인             
                    </p>
                    <p className="mb-20">· 주민등록증이 발급되지 아니하여 지문 정보가 없는 미성년
                        자가 원동기장치자전거 면허시험에 응시하는 경우
                    </p>
                    <p className="mb-20">· 사진, 생년월일, 이름이 식별곤란하거나 동일인 여부가 의
                        심되어 지문 조회 하였음에도 지문 훼손 또는 전산 상 지문
                        누락으로 확인되지 않는 경우 : '사진, 생년월일, 이름'이 
                        기재된 신분증 외에 주민등록번호 뒷자리를 확인할 수 있
                        는 국가기관 발행의 증빙자료를 제출 받아 신분확인
                    </p>
                    <p className="fw-bold mb-15">· *신분증명서 미소지자의 경우 본인 동의 시 지문으로 조회
                        가능하나, 지문이 조회되지 않는 경우 신분증명서 제출을
                        요구할 수 있습니다.
                </p>
                <p className="fw-bold">· *단, 시도지부 안전교육부에서는 지문 조회가 불가합니다.</p>
                </div>

            ))
        }else if(e.target.id==="4"){
            
            window.open('https://www.safedriving.or.kr/rerrest/rerrestScheduleViewM.do?menuCode=MN-MO-1121')
            
        }
    }
    return (
        <>
        <div className="wrap_gnb_test_info–exemption bg-gray1">
            <div className="is-flex x-between mx-20">
                <input onClick={menuChange} id="1"  type="button" value="응시 전 교통안전 교육"
                className="button button-1 c-white bg-orange2"/>

                <input onClick={menuChange} id="2" type="button" value="신체검사"
                className=" button button-1 bg-white c-text1"/>
            </div>
            <div className="is-flex x-between mb-15 mx-20">

                <input onClick={menuChange} id="3" type="button" value="신분증 인정 범위"
                className="button button-1 bg-white c-text1"/>

                <input onClick={menuChange} id="4" type="button" value="교육일정 확인"
                className=" button button-1 bg-white c-text1"/>
            </div>
            {view}    
        </div>
        </>
    )
}

export default DetailTab
