import React, { useEffect, useState } from 'react'
import Icon_ionic_ios_arrow_down from'../../../image/publishing_Img/Icon-ionic-ios-arrow-down.png'
import Icon_ionic_ios_arrow_up from'../../../image/publishing_Img/Icon-ionic-ios-arrow-up.png'
import axios from'axios'
import { url } from '../../../enum'

function FAQ(props) {
    const [faq,setFaq]=useState([])

    useEffect(() => {
        axios.get(url+"/faqs").then(res=>{
            if(res.data.resCode===0){
                setFaq(res.data.rows)
            }else{
                alert("데이터를 불러올 수 없습니다.")
                setFaq([{question:"일시적 오류입니다.",answer:"잠시후 다시 시도해 주세요!"}])
            }
        })

    }, [])
    const dropDown =(e)=>{
        
        let name=e.target.getAttribute('name')
        
        let getClass=document.getElementsByName(name)[4]
        if(getClass.className==="box_2"){
            getClass.className="displayBlock"
            document.getElementsByName(name)[3].src=Icon_ionic_ios_arrow_up
        }else{
            getClass.className="box_2"
            document.getElementsByName(name)[3].src=Icon_ionic_ios_arrow_down
        }
    }
    return (
      <div className="full-height bg-gray1">
        <div className="gosu-header-relative ">
          <div className="gosu-header is-flex y-center x-center bg-white">
            <label className="back_1">
              <p
                onClick={() => props.history.goBack()}
                className="fz-14 c-text11"
              >
                이전
              </p>
            </label>
            <p className="c-text11 fz-16 fw-bold">FAQ</p>
          </div>
        </div>
        <div className="wrap-setting-2 ">
          <ul>
            {faq.map((res, index) => (
              <li name={index} key={res.question}>
                <label
                  name={index}
                  className="box_1 bg-white"
                  onClick={dropDown}
                >
                  <p name={index} className="fz-14">
                    {res.question}
                  </p>
                  <img
                    name={index}
                    alt="arrow"
                    src={Icon_ionic_ios_arrow_down}
                  />
                </label>
                <div name={index} className="box_2">
                  <p className="noticeContent">
                    {res.answer.split("\n").map((line) => {
                      //this.props.data.content: 내용
                      return (
                        <span>
                          {line}
                          <br />
                        </span>
                      );
                    })}{" "}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
}

export default FAQ
