import React, { useState } from 'react'
import Icon_logo_youtube_purple from'../../../image/publishing_Img/Icon-logo-youtube_purple.png'
import drive_map from'../../../image/publishing_Img/test/drive_map.png'
import BigImage from'./BigImage'

function DriveTestInfo(props) {
    
    const [list]=useState(props.location.state.testInfo)
    const [visible,setVisible]=useState("none")
    const [imageUrl,setImageUrl]=useState("")
    let where=props.location.state.where
    let category=props.location.state.category
    
    const goBack=()=>{
        props.history.goBack()
    }
    const bigSize=(e,url)=>{
        setImageUrl(url)
        setVisible('block')

    }
    let isIndata;
    console.log(list)
    for(let i=0; i<list.length; i++){
        if(list[i]==="없음"){
            isIndata=false
        }else{
            isIndata=true
            break;
        }
    }
    return (
        <div className="full-height bg-gray1" style={{marginTop:'2%'}}>
            <header className="gosu-header-relative">
                <div className="gosu-header is-flex y-center x-center bg-purple2">
                    <label className="back_1">
                        <p  onClick={goBack} className="fz-14 c-white">이전</p>
                    </label>
                    <p className="fz-16 fw-bold c-white"> {where.centerName}</p>
                </div>
            </header>

            <article className="wrap-test_place_video–2 bg-gray1">
               
                <ul style={{marginTop:'-2%'}} className="exam_type">
                    <li className="type_name">
                        <div className="left_img">
                            <img alt="유튜브 아이콘" className="youtube_img"src={Icon_logo_youtube_purple}/>
                        </div>
                        <p className="c-text11 fz-16"> {category}</p>
                    </li>
                    {  
                     isIndata?
                        list.map((value,index)=>
                                 
                               value.includes('embed')?

                                    <div key={index} className="video bg-white mb-10 mt-10">
                                        <p className="fz-16 fw-bold mb-20 ">동영상</p>
                                            <iframe title="동영상" className="iframe_1 mb-15" style={{height:'200px'}}  src={value} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                        <p className="text-center fz-12 c-text1">(재생을 눌러 영상을 시청하세요)</p>
                                    </div>
                                    :
                                    value==="없음"||value===""?
                                    ""
                                    :
                                    <div key={index} className="test_map bg-white">
                                        {/* 이후 도로주행 인지 기능인지 체크하여 하단의 값을 바꿔준다. */}
                                            <p className="fz-16 fw-bold mb-20">{
                                                                            index===1?
                                                                                category.includes('도로주행')?
                                                                                    "도로주행 맵"
                                                                                    :
                                                                                    "기능시험 맵"
                                                                            :
                                                                                category.includes('도로주행')?
                                                                                    "도로주행 맵"
                                                                                    :
                                                                                    "기능시험 정보"
                                                                            }
                                            </p>
                                        <label>
                                            <img  alt="드라이브맵" onClick={(e)=>bigSize(e,value)} className="mb-15 " src={value}/>
                                        </label>
                                        <p className="text-center fz-12 c-text1">(이미지 클릭 시 확대 이미지로 전환됩니다.)</p>
                                    </div>
                            
                        )
                       :
                        <div  className="video bg-white mb-10 mt-10">
                            <p className="fz-16 fw-bold mb-20 ">데이터가 없습니다.</p>
                        </div>
                    }
                </ul>
            </article>
            <BigImage visible={visible} img={drive_map} imageUrl={imageUrl} setVisible={setVisible}/>
        </div>
    )
}

export default DriveTestInfo
