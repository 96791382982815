import React, { useEffect, useState } from 'react'
import MemberWithdrawalModal from './MemberWithdrawalModal'
import Icon_ionic_ios_arrow_forward from'../../../image/publishing_Img/Icon-ionic-ios-arrow-forward.png'

function SettingLandingPage(props) {
    
    const [visible,setVisible]=useState('none')
    const [token,setTokent]=useState(localStorage.getItem('token'))

    

   
    return (
        <>
        <div className="full-height bg-gray1">
              <div className="gosu-header-relative">
                <div className="gosu-header is-flex y-center x-center bg-white">
                    <label className="back_1">
                        <p onClick={()=>props.history.goBack()} className="fz-14 c-text11">이전</p>
                    </label>
                    <p className="c-text11 fz-16 fw-bold">설정</p>
                </div>
             </div>
            <div className="wrap-main-setting">
            
            
                <label className="box" >
                    <p>앱 버전</p>
                    <p>ver 1.0</p>
                </label>

                <a href="/notice">
                    <label className="box">
                    <p>공지사항</p>
                        <img src={Icon_ionic_ios_arrow_forward} alt="공지사항"/>
                    </label> 
                </a>
                <a href="/FAQ">
                    <label className="box">
                    <p>FAQ</p>
                        <img src={Icon_ionic_ios_arrow_forward} alt="공지사항"/>
                    </label>  
                </a>
            
                <a href="/introduction">
                    <label className="box">
                    <p>회사소개</p>
                        <img src={Icon_ionic_ios_arrow_forward} alt="공지사항"/>
                    </label>     
                </a>
                {
                token?
                <label className="box" onClick={()=>setVisible('block')}>
                <p>회원탈퇴</p>
                    <img src={Icon_ionic_ios_arrow_forward} alt="공지사항"/>
                </label>  
                :
                ""
                }
                
            </div>
        </div>
            <MemberWithdrawalModal setVisible={setVisible} visible={visible} />
        </>
    )
}

export default SettingLandingPage
