import React, { useEffect, useState } from 'react'
import Icon_feather_phone_call from'../../../image/publishing_Img/Icon-feather-phone-call.png'
import Icon_zocial_www from'../../../image/publishing_Img/Icon-zocial-www.svg'
import Icon_black_map_marker from '../../../image/publishing_Img/Icon-black-map-marker.svg'


function NearDriveTestCenterLocationList(props) {
    
    let where = props.where;
   
    const [myLatitude,setMyLatitude]=useState(localStorage.latitude)
    const [myLogitude,setMyLongitude]=useState(localStorage.longitude)
    const [list, setList] = useState([]);
    const data=JSON.parse(localStorage.getItem('gosuData'))
    function getDistanceFromLatLonInKm(lat1, lng1, lat2, lng2){
        function deg2rad(deg){
        return deg * (Math.PI / 180);
        };
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1); // deg2rad below
    var dLon = deg2rad(lng2 - lng1);
    var a =Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2))*Math.sin(dLon / 2)*Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
  };

  const site=(url)=>{
    window.ReactNativeWebView.postMessage(url)
  }
  useEffect(() => {
        let getLocation=setInterval(() => {
            //핸드폰에선 스토리지에 넣는 것이 느려 질 수도 있으므로 계속해서 채크한다.(웹에선 기본 null값으로 나온다. 위치정보를 가져오지 않는다.)
            if(window.localStorage.getItem('latitude')){
                setMyLatitude(window.localStorage.getItem('latitude'))
                setMyLongitude(window.localStorage.getItem('longitude'))
                clearInterval(getLocation)
            }
        }, 500);
  
    }, [])
  
  
 
  useEffect(() => {
    
    for(let i=0; i<data.length; i++){
        if(myLogitude===null){
            data[i].distance="찾는중"
        }else{
            data[i].distance=getDistanceFromLatLonInKm(data[i].latitude,data[i].longitude,parseFloat(myLatitude),parseFloat(myLogitude))
        }
    }
    data.sort((a,b)=>a.distance-b.distance)
    let array=[]
    setList(data)
    if (where === "nation") {
      setList(data);
    }else{
        if(where==="seoul")where=["서울"]
        if(where==="kyungki")where=["경기","인천"]
        if(where==="busan")where=["부산","울산","경남"]
        if(where==="daegu")where=["대구","경북"]
        if(where==="kwangju")where=["전북","전남","광주"]
        if(where==="daejyun")where=["대전","충북","세종","충남"]
        if(where==="kangwon")where=["강원","제주"]
        
        for(let i=0; i<data.length; i++){
            if(data[i].centerAddress.includes(where[0])||data[i].centerAddress.includes(where[1])||data[i].centerAddress.includes(where[2])||data[i].centerAddress.includes(where[3])){
                array.push(data[i])
            }
        }
        setList(array)
    }
  }, [where,myLogitude,myLatitude]);

  const tel=(tel)=>{
    window.location.href=`tel:${tel}`
  }
    return (
       
            
        <div className="test_place_list">
                <ul>
                    {list.length>0?
                      list.map((value,index)=>
                            <li key={value.centerName} className="list_box">
                                <div>
                                    <p className="fz-14 c-text11 lh-15">{value.centerName}</p>
                                    <p className="fz-12 c-text1 lh-15">{value.centerAddress}</p>
                                </div>
                                <div className="right_icons is-flex">
                                    <label onClick={()=>site(value.homePage)}>
                                    <div>
                                        <img alt="아이콘" className="zocial_www" src={Icon_zocial_www}/>
                                    </div>
                                    <p>사이트</p>
                                    </label>
                                    <label onClick={()=>tel(value.tel)}>
                                        <div>
                                        <img alt="아이콘" className="phone_call" src={Icon_feather_phone_call}/>
                                        </div>
                                        <p>전화</p>
                                    </label>
                                    <label>
                                        <div>
                                        <img alt="마커" className="map_marker" src={Icon_black_map_marker}/>
                                            </div>
                                        <p>{Math.floor(value.distance)}km</p>
                                    </label>
                                </div>
                            </li> 
                    )
                    :
                    <li className="list_box">
                        <div>
                            <p className="fz-14 c-text11 lh-15">정보가없습니다.</p>
                        </div>
                    </li>
                    
                    }
                  
                </ul>
        </div>
    
    )
}

export default NearDriveTestCenterLocationList
