import React, { useState } from 'react'


function DetailTab() {
    
    const [view,setView]=useState((
                <div className="guide_text_box bg-white">
                    <h2 className="mb-10">준비물</h2>
                    <p className="mb-20 lh-14">· 온라인 접수 또는 현장 예약 접수 후 시험당일 응시원서,
                        신분증 지참</p>
                    <h2 className="mb-10">합격기준</h2>
                    <p className="mb-20">· 80점 이상</p>
                    <h2 className="mb-15">수수료</h2>
                    <p className="mb-20">· 20,000원</p>
                    <h2 className="mb-10">시험코스</h2>
                    <p className="mb-20 lh-14">· 출발 코스, 굴절 코스,  방향전환 코스, 평행주차 코스, 기어
                        변속 코스, 교통신호가 있는 십자형 교차로코스, 횡단보도
                        코스, 철길건널목코스, 경사로코스, 종료코스</p>
                    <h2 className="mb-10">실격기준</h2>
                    <p className="lh-15 mb-10">· 특별한 사유 없이 출발선에서 30초 이내 출발하지 못한 때<br/>
                        · 경사로 코스·굴절 코스·방향전환 코스·기어변속 코스<br/>
                        (자동변속기장치 자동차의 경우는 제외) 및 평행주차 코스
                        를 어느 하나라도 이행하지 아니한 때<br/>
                        · 특별한 사유 없이 교차로 내에서 30초 이상 정차한 때<br/>
                        · 안전사고를 일으키거나 단 1회라도 차로를 벗어난 때<br/>
                        · 경사로 정지구간 이행 후 30초를 초과하여 통과하지 못한
                        때 또는 경사로 정지구간에서 후진하여 앞 범퍼가 경사로 
                        사면을 벗어난 때</p>
                    <h2 className="mb-5">응시자격</h2>
                    <p className="mb-25">· 19세 이상으로 제 1,2종 보통면허 취득 후 1년 이상인 자</p>
                    <h2 className="mb-10 lh-15">불합격 후 재 응시</h2>
                    <p>· 기능시험 불합격자는 (운전전문학원 검정 불합격 포함)
                        불합격 일로부터 3일 경과 후에 재 응시 가능</p>
                </div>
             ))
    
    const menuChange=(e)=>{
        let td=document.getElementsByTagName('td')
        for(let i=0; i<td.length; i++){
            td[i].className="c-text1 bg-white"
        }
        
        e.currentTarget.className="c-white bg-orange2"
        
        if(e.target.id==="1"){
            setView((
                <div className="guide_text_box bg-white">
                    <h2 className="mb-10">준비물</h2>
                    <p className="mb-20 lh-14">· 온라인 접수 또는 현장 예약 접수 후 시험당일 응시원서,
                        신분증 지참</p>
                    <h2 className="mb-10">합격기준</h2>
                    <p className="mb-20">· 80점 이상</p>
                    <h2 className="mb-15">수수료</h2>
                    <p className="mb-20">· 20,000원</p>
                    <h2 className="mb-10">시험코스</h2>
                    <p className="mb-20 lh-14">· 출발 코스, 굴절 코스,  방향전환 코스, 평행주차 코스, 기어
                        변속 코스, 교통신호가 있는 십자형 교차로코스, 횡단보도
                        코스, 철길건널목코스, 경사로코스, 종료코스</p>
                    <h2 className="mb-10">실격기준</h2>
                    <p className="lh-15 mb-10">· 특별한 사유 없이 출발선에서 30초 이내 출발하지 못한 때<br/>
                        · 경사로 코스·굴절 코스·방향전환 코스·기어변속 코스<br/>
                        (자동변속기장치 자동차의 경우는 제외) 및 평행주차 코스
                        를 어느 하나라도 이행하지 아니한 때<br/>
                        · 특별한 사유 없이 교차로 내에서 30초 이상 정차한 때<br/>
                        · 안전사고를 일으키거나 단 1회라도 차로를 벗어난 때<br/>
                        · 경사로 정지구간 이행 후 30초를 초과하여 통과하지 못한
                        때 또는 경사로 정지구간에서 후진하여 앞 범퍼가 경사로 
                        사면을 벗어난 때</p>
                    <h2 className="mb-5">응시자격</h2>
                    <p className="mb-25">· 19세 이상으로 제 1,2종 보통면허 취득 후 1년 이상인 자</p>
                    <h2 className="mb-10 lh-15">불합격 후 재 응시</h2>
                    <p>· 기능시험 불합격자는 (운전전문학원 검정 불합격 포함)
                        불합격 일로부터 3일 경과 후에 재 응시 가능</p>
                </div>
            ))
        }else if(e.target.id==="2"){
            
            setView((
                <div className="guide_text_box bg-white">
                    <h2 className="mb-10">준비물</h2>
                    <p className="mb-20 lh-14">· 온라인 접수 또는 현장 예약 접수 후 시험당일 응시원서,
                        신분증 지참</p>
                    <h2 className="mb-10">합격기준</h2>
                    <p className="mb-20">· 90점 이상</p>
                    <h2 className="mb-15">수수료</h2>
                    <p className="mb-20">· 20,000원</p>
                    <h2 className="mb-10">시험코스</h2>
                    <p className="mb-20 lh-14">· 출발 코스, 굴절 코스,  방향전환 코스, 평행주차 코스, 기어
                        변속 코스, 교통신호가 있는 십자형 교차로코스, 횡단보도
                        코스, 철길건널목코스, 경사로코스, 종료코스</p>
                    <h2 className="mb-15">실격기준</h2>
                    <p className="lh-14 mb-20">· 운전미숙으로 20초 이내에 출발하지 못한 때<br/>
                        · 시험과제를 하나라도 이행하지 아니한 때<br/>
                        · 시험 중 안전사고를 일으키거나 코스를 벗어난 때</p>
                    <h2 className="mb-10">응시자격</h2>
                    <p className="mb-25">· 19세 이상으로 제 1,2종 보통면허 취득 후 1년 이상인 자</p>
                    <h2 className=" mb-10">불합격 후 재 응시</h2>
                    <p className="lh-12">· 기능시험 불합격자는 (운전전문학원 검정 불합격 포함)
                        불합격 일로부터 3일 경과 후에 재 응시 가능</p>
                </div>
                
            ))
        }else if(e.target.id==="3"){
            
            setView((
                <div className="guide_text_box bg-white">
                    <h2 className="mb-10">준비물</h2>
                    <p className="mb-20 lh-14">· 온라인 접수 또는 현장 예약 접수 후 시험당일 응시원서,
                        신분증 지참</p>
                    <h2 className="mb-10">합격기준</h2>
                    <p className="mb-20">· 80점 이상</p>
                    <h2 className="mb-15">수수료</h2>
                    <p className="mb-20">· 22,000원</p>
                    <h2 className="mb-10">시험코스</h2>
                    <p className="mb-15 lh-14">· 주행거리 300m 이상, 운전장치 조작, 차로 준수·급정지,
                        경사로</p>
                        <p className="mb-25">· 좌·우회전, 직각 주차, 신호교차로, 전진(가속구간)
                    </p>
                    <h2 className="mb-10">실격기준</h2>
                    <p className="lh-15">· 점검이 시작될 때부터 종료될 때까지 좌석 안전띠를 착용
                        하지 아니한 때
                    </p>
                    <p className="lh-15">· 시험 중 안전사고를 일으키거나 차의 바퀴 어느 하나라도
                        연석을 접촉한 때
                    </p>
                    <p className="lh-15 mb-10">· 시험관의 지시나 통제를 따르지 않거나 음주, 과로, 마약·
                        대마 등 약물 등의 영향으로 정상적인 시험 진행이 어려운
                        때
                    </p>
                    <p className="lh-15">· 특별한 사유 없이 출발 지시 후 출발선에서 30초 이내 
                        출발하지 못한 때
                    </p>
                    <p className="lh-15">· 경사로 정지 구간 이행 후 30초를 초과하여 통과하지 못한
                        때 또는 경사로 정지 구간에서 후방으로 <br/>1미터 이상 밀린
                        때
                    </p>
                    <p className="lh-15 mb-10">· 신호 교차로에서 신호위반을 하거나 또는 앞 범퍼가<br/> 정지
                        선을 넘어간 때
                    </p>
                    <p className="lh-15 mb-5">· 각 시험 코스를 어느 하나라도 시도하지 않거나 제대로 이
                        행하지 않을 때 
                    </p>
                    <p className="mb-25"><span> (예: 경사로에서 정지하지 않고 통과, 직각 주차에서 차고에 진입해
                        서 확인선을 접촉하지 않음, 가속코스에서 기어변속을 하지 않음)</span></p>
                    <h2 className="mb-10">주의사항</h2>
                    <p className="lh-15 mb-5">· 학과시험 합격일로부터 1년 이내 기능 시험에 합격하여야
                        함.</p>
                    <p className="lh-15 mb-20">· 1년경과 시 기존 원서 폐기 후 학과시험부터 신규 접수하
                        여야 하며 이때 응시 전 교통안전교육 재수강은 불필요</p>
                    <h2 className="mb-10">불합격 후 재 응시</h2>
                    <p className="lh-12 mb-10">· 기능시험 불합격자는 (운전전문학원 불합격 포함) 불합격
                        일로부터 3일 경과 후에 재 응시 가능
                    </p>
                    <p className="mb-25"><span>예) 1일(월요일)에 불합격하였을 경우, 4일(목요일)부터 재 응시 가능</span></p>
                    <h2 className="mb-15">외국어 음성, 화면지원 서비스 제공</h2>
                    <p>· 영어, 중국어</p>
                </div>
            ))
        }else if(e.target.id==="4"){
            
            setView((
                <div className="guide_text_box bg-white">
                    <h2 className="mb-10">준비물</h2>
                    <p className="mb-20 lh-14">· 온라인 접수 또는 현장 예약 접수 후 시험당일 응시원서,
                        신분증 지참</p>
                    <h2 className="mb-10">합격기준</h2>
                    <p className="mb-20">· 90점 이상</p>
                    <h2 className="mb-15">수수료</h2>
                    <p className="mb-20">· 10,000원</p>
                    <h2 className="mb-10">시험코스</h2>
                    <p className="mb-20 lh-14">· 굴절코스, 곡선코스, 좁은길코스, 연속진로전환코스</p>
                    <h2 className="mb-10">실격기준</h2>
                    <p className="lh-15 mb-20">· 운전미숙으로 20초 이내에 출발하지 못한 때<br/>
                        · 시험과제를 하나라도 이행하지 아니한 때<br/>
                        · 시험 중 안전사고를 일으키거나 코스를 벗어난 때
                    </p>
                    <h2 className="lh-12 mb-15">불합격 후 재 응시</h2>
                    <p className="lh-12">· 기능시험 불합격자는 (운전전문학원 검정 불합격 포함)
                        불합격 일로부터 3일 경과 후에 재 응시 가능</p>
                </div>
            ))
        }else if(e.target.id==="5"){
            
            setView((
                <div className="guide_text_box bg-white">
                    <h2 className="mb-10">준비물</h2>
                    <p className="mb-20 lh-14">· 온라인 접수 또는 현장 예약 접수 후 시험당일 응시원서,
                        신분증 지참</p>
                    <h2 className="mb-10">합격기준</h2>
                    <p className="mb-20">· 90점 이상</p>
                    <h2 className="mb-15">수수료</h2>
                    <p className="mb-20">· 8,000원</p>
                    <h2 className="mb-10">시험코스</h2>
                    <p className="mb-15">· 굴절코스, 곡선코스, 좁은길코스, 연속진로전환코스</p>
                    <p className="mb-30">※다륜형원동기장치자전거 굴절코스, 곡선코스만 진행</p>
                    <h2 className="mb-20">실격기준</h2>
                    <p className="lh-15 mb-15">· 운전미숙으로 20초 이내에 출발하지 못한 때<br/>
                        · 시험과제를 하나라도 이행하지 아니한 때<br/>
                        · 시험 중 안전사고를 일으키거나 코스를 벗어난 때
                    </p>
                    <h2 className="lh-12 mb-10">불합격 후 재 응시</h2>
                    <p className="lh-12">· 기능시험 불합격자는 (운전전문학원 검정 불합격 포함)
                        불합격 일로부터 3일 경과 후에 재 응시 가능</p>
                </div>
            ))
        }else if(e.target.id==="6"){
            
            setView((
                <div className="guide_text_box bg-white">
                   <p className="mb-20 lh-14" style={{textAlign:'center'}}>응시일정 페이지로 이동합니다.</p>
                </div>
            ))
            window.open("https://www.safedriving.or.kr/mainM.do")
        }
    }
    return (
        <>
        <div className="wrap_gnb_test_info–function bg-gray1">
            <table className="gnb_table">
                <tbody>
                    <tr>
                        <td onClick={menuChange} id="1" className="c-white bg-orange2">제1종 대형</td>
                        <td onClick={menuChange} id="2" className="c-text1 bg-white">제1종 특수</td>
                        <td onClick={menuChange} id="3" className="c-text1 bg-white">제1,2종 보통</td>
                    </tr>
                    <tr>
                        <td onClick={menuChange} id="4" className="c-text1 bg-white">제2종 소형</td>
                        <td onClick={menuChange} id="5" className="c-text1 bg-white">제2종 원동기</td>
                        <td onClick={menuChange} id="6" className="c-text1 bg-white">응시일정 확인하기</td>
                    </tr>
                </tbody>
            </table>
            {view}    
        </div>
        </>
    )
}

export default DetailTab
