import React from 'react'
import logo_black from '../../../image/publishing_Img/logo_black.png'
function SignUPModal_2(props) {


    const agree =()=>{
        props.setModal2('none')
        props.setPersonalInformation('1')
    }
    return (

        <div className="modal-2" style={{display:props.modal2}} >
        <div className="modal-dim"></div>
        <div className="wrap_box bg-white modal-content">

            
                <div className="logo-2 mb-25 ">
                    <img src={logo_black}/>
                    <p className="fz-20 fw-bold">개인정보 처리방침</p>
                </div>

               <p className="guide_text fz-10 bg-gray1"> 
                ㈜제이에프파트너스 개인정보취급방침<br/>
                    제 1조 (총칙)<br/>
                    ㈜제이에프파트너스 (이하 “회사”)는 『정보통신망 이용촉진 및 정보보호에 관한 법률』(이하, "정보통신망법") 『개인정보 보호법』, 『통신비밀보호법』 등의 관련 법령의 규정을 준수함으로써, 회원님의 개인정보를 보호하고 있습니다. <br/><br/>
                     
                    회사는 개인정보취급방침을 명시하여 회원님이 자사의 모바일 서비스를 이용하는 과정에서 귀하의 개인정보가 어떠한 방법으로 수집되어, 무슨 용도와 방식으로 이용되고 있으며, 귀하의 개인정보보호를 위하여 법령에 따라 어떠한 조치가 취해지고 있는지 알려드리고자 합니다.
                     <br/><br/>
                    회사는 개인정보보호와 관련된 법령의 개정이 있을 경우, 개정날짜의 명시와 관련 내용을 고지하여 귀하께서 변경된 사항을 쉽게 인지할 수 있도록 노력하겠습니다.<br/><br/>
                     
                    제 2조 (개인정보의 정의)<br/>
                    “개인정보"란 살아 있는 개인에 관한 정보로서 성명, 이메일 등을 통하여 개인을 알아볼 수 있는 정보(해당 정보만으로는 특정 개인을 알아볼 수 없더라도 다른 정보와 쉽게 결합하여 알아볼 수 있는 것을 포함한다)를 말합니다. <br/><br/>
                     
                    제 3조 (개인정보의 수집 및 이용목적)<br/>
                      회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.<br/><br/>
                     
                        (1) 회원관리 목적 <br/>
                          - 본인확인, 개인식별, 중복가입 확인, 성별 확인, 불량회원의 부정이용 방지, 불만처리 및 민원처리, 분쟁처리, 계약이행, 기록보존, 고지사항 전달
                     <br/><br/>
                        (2) 서비스제공 목적<br/>
                          - 콘텐츠 제공, 불만처리 및 기타 회원서비스 제공, 스케쥴 관리
                     <br/><br/>
                        (3) 신규서비스 및 마케팅 광고 활용 목적<br/>
                          - 신규 서비스 개발, 이벤트 및 광고성 정보 전달, 서비스 제공 및 광고 게재, 통계정보 처리를 통한 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계정보 처리<br/><br/>
                     
                          - 회사는 회원별 맞춤 서비스, 기타 다양한 서비스 제공 등의 이유로 회원의 개인정보를 파트너 업체에 제공하거나 또는 공유할 수 있습니다. 개인정보를 제공하거나 공유할 경우에는 정보 제공 이전에 제휴사명, 제휴 목적, 제공 또는 공유되는 개인정보의 범위, 보호 기간 및 관리절차 등에 대해 고지하여 별도의 동의를 구하는 절차를 거치게 되며, 회원이 동의하지 않는 경우에는 타 외부기관에 제공하거나 공유하지 않습니다.
                     <br/><br/>
                    제 4조 (수집하는 개인정보의 항목 및 수집방법)<br/>
                    회사의 원활한 서비스 제공을 위해 아래 명시하고 있는 개인정보를 수집하고 있습니다. 이 중 필수항목은 회사가 제공하는 서비스를 이용하기 위해 반드시 필요한 정보이며, 선택항목은 양질의 서비스 제공을 위한 목적으로 수집하고 있습니다. 회사는 회원님의 개인정보 수집 전, 회원님이 『동의합니다』 또는 『동의하지 않습니다』를 양자택일 하실 수 있는 절차를 마련해 두고 있습니다. 만일, 『동의하지 않습니다』를 선택하실 경우 회사의 서비스 이용에 제한이 있을 수 있습니다.
                     <br/><br/>
                      (1) 필수수집 항목 <br/>
                        1) 회원님의 이름, 성별, 이메일<br/>
                     <br/><br/>
                      (2) 선택수집 항목 <br/>
                        연령확인, 관련 법률 준수를 위해 본인인증이 필요한 경우(이벤트 프로모션, 선택형 서비스 등)회원님의 별도 동의 여부 확인 하에 아래의 정보를 수집할 수 있습니다
                     <br/><br/>
                       - 휴대전화번호, 주소, 성명, 성별, 지역, 내/외국인
                     <br/><br/>
                      (3) 서비스 이용과정에서의 수집 가능한 정보<br/>
                         - 서비스 이용기록, 접속로그 관련 정보<br/>
                             * 회사와 이용자간 민원처리 또는 이용자간 분쟁조정이나 질서의 유지를 위하는 경우 수집하며 제 3자의 경우 절대 접근 및 열람할 수 없습니다.
                          <br/><br/>
                      (4) 개인정보 수집방법 <br/>
                        - 서면양식, 전화상담<br/>
                     <br/><br/>
                    제 5조 (개인정보를 제3자에게 제공하는 경우 제공받는 자의 성명)<br/>
                    회사는 회원님의 사전 동의가 없는 한 제3자에게 수집한 개인정보를 제공하지 않습니다. 단, 회원이 사전에 개인정보 공개에 대하여 동의한 경우와 서비스를 이용하여 타인에게 정신적, 물질적 피해를 주어 그에 따른 법적인 조치를 취하기 위하여 개인정보를 공개해야 한다고 판단되는 충분한 근거가 있는 경우, 법령의 규정에 의거하여 또는 수사 목적의 정당한 권한을 가진 국가기관, 수사기관 등의 요청이 있을 경우 예외적으로 제공할 수 있습니다.
                     <br/><br/>
                    제 6조 (개인정보처리의 위탁)<br/>
                      (1) 회사는 회원지원, 시스템 유지보수 등 회사의 서비스를 위하여 『정보통신망법』 제 25조 및 『개인정보보호법』 제 26조에 따라 법령에서 정해진 절차 및 방법으로 다음과 같이 개인정보 처리업무를 외부에 위탁할 수 있습니다.<br/>
                      (2) "회사"는 위탁계약 체결 시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 재위탁 제한, 수탁자에 대한 관리, 감독, 책임에 관한 사항을 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다
                     <br/><br/>
                    제 7조 (개인정보의 보유 및 이용 기간)<br/>
                    회사는 개인정보 수집 및 이용목적이 달성된 후, 회원탈퇴와 같이 이용목적이 소멸한 경우에는 해당 정보를 지체없이 파괴합니다. 다만, 회사는 약관 및 정책에 따라 부정이용의 방지를 위하여 회원 탈퇴 후 1개월 동안 개인정보를 보유하며 이 기간이 경과한 후에는 회원님의 개인정보는 완전히 삭제됩니다. 다만, 아래의 항목에 대해서는 열거한 사유로 인해 명시한 기간 동안 보존합니다. <br/>
                      (1) 전자상거래등에서의 소비자보호에 관한 법률<br/>
                          - 보존 항목 및 기간 : 소비자의 불만 또는 분쟁처리에 관한 기록(3년)
                       <br/><br/>
                      (2) 정보통신망 이용촉진 및 정보보호 등에 관한 법률<br/>
                          - 보존 항목 및 기간 : 본인확인에 관한 기록(6개월)<br/><br/>
                       
                      (3) 통신비밀보호법에 관한 법률<br/>
                          - 보존 항목 및 기간 : 웹 사이트 방문 및 통신사실확인자료(3개월)<br/><br/>
                     
                      (4) 국세기본법에 관한 법률<br/>
                          - 보존 항목 및 기간 : 국세관련 서류 및 증빙자료(5년)<br/><br/>
                     
                    제 8조 (개인정보의 파기절차 및 파기방법)<br/>
                    회사는 개인정보 보유기간의 경과, 목적 달성 등 개인정보가 불필요할 시 전자적 파일형태로 저장된 개인정보는 기록을 다시 열람할 수 없는 기술적 방법을 사용하여 삭제하며, 종이에 경우 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.<br/><br/>
                     
                    제 9조 (회원 및 법정대리인의 권리와 그 행사방법)<br/>
                    회원 및 법정대리인의 경우 언제든지 개인정보 수집, 이용, 제공 등의 동의를 철회 할 수 있습니다. 또한 개인정보의 열람이나 제공을 요구 할 수 있고 오류가 있는 경우에는 정정을 요구 할 수 있습니다. 행사방법으로는 회원 본인이 직접 '개인정보변경' 또는 '동의철회'를 위해 "회원기록 열람, 정정, 탈퇴"가 가능하며, 회사는 회원 혹은 법정 대리인이 요청한 경우 빠른 시일내로 적법한 절차에 따라 처리하겠습니다.<br/><br/>
                      
                    제 10조 (개인정보 관리책임자의 성명, 개인정보보호 업무 및 관리 부서 명칭과 연락처)<br/>
                    회사는 회원님에게 좋은 서비스를 안전하게 이용할 수 있도록 최선을 다하고 있습니다. 개인정보를 보호하는데 있어 회사의 귀책 사유로 회원님에게 고지한 사항들에 반하는 사고가 발생할 경우에 개인정보고충 처리부서가 모든 책임을 집니다. 그러나 예외적으로 천재지변 및 기술적 보완조치를 취했음에도 불구하고, 해킹 등 기본적인 네트워크상의 위험성에 의해 발생하는 예견불가능한 경우로 인한 정보의 훼손, 침해, 누설되는 경우 또는 방문자가 작성한 게시물에 의한 각종 분쟁에 관해서는 본회사의 귀책사유가 없으므로, 관련 책임을 지지 않습니다. <br/>
                      (1) 개인정보 관리책임자<br/>
                         소속 : ㈜제이에프파트너스<br/>
                         직위 : 대표이사<br/>
                         성명 : 정재헌<br/>
                         메일 : jjh@jfpartners.co.kr<br/><br/>
                     
                      (2) 개인정보 관리담당자(고객센터)<br/>
                         소속 : ㈜제이에프파트너스<br/>
                         운영 : 월~금요일 09:00 ~ 18:00<br/>
                         제외 : 토/일요일 및 공휴일 제외<br/>
                         메일 : jsm@jfpartners.co.kr<br/><br/>
                     
                    기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.<br/><br/>
                     
                       - 개인정보침해신고센터 (http://privacy.kisa.or.kr / 118)<br/>
                       - 개인정보분쟁조정위원회 (http://www.kopico.or.kr / 02-405-5150)<br/>
                       - 정보보호마크인증위원회 (http://www.eprivacy.or.kr / 02-550-9531~2)<br/>
                       - 대검찰청 인터넷범죄수사센터 (http://www.spo.go.kr / 02-3480-3571)<br/>
                       - 경찰청 사이버테러대응센터 (http://www.ctrc.go.kr / 02-393-9112) <br/>
                    회원님은 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보 관리책임자 혹은 개인정보 관리담당자에게 신고하시거나 회사의 고객센터를 통하여 민원제기를 하실 수 있습니다. 이에, 회사는 회원님의 신고사항에 대해 책임의식을 가지고 신속하고 정확한 답변을 드릴 것입니다.
                     <br/><br/>
                    제 11 조 (개인정보보호를 위한 기술적 대책)<br/>
                    회사는 회원님들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.<br/>
                      (1) 비밀번호 암호화<br/>
                        회원님 아이디(ID)의 비밀번호는 암호화되어 저장 및 관리되고 있어 회원님만이 알고 있으며, 개인정보의 확인 및 변경도 비밀번호를 알고 있는 회원님에 의해서만 가능합니다.<br/><br/>
                       
                      (2) 해킹 등에 대비한 대책<br/>
                        회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원님의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 회원님들의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며, 암호화통신 등의 사용으로 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.<br/><br/>
                     
                    제 12 조 (개인정보보호를 위한 관리적 및 물리적 대책)<br/> 
                    회사는 회원의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손 등이 되지 않도록 안전성을 확보하기 위하여 다음과 같은 기술적·관리적 보호대책을 강구하고 있습니다.
                       <br/><br/>
                     
                      (1) 관리적 대책<br/>
                         개인정보관련 취급은 담당자만이 관리하며, 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 개인정보취급방침의 준수를 항상 강조하고 있습니다.
                     <br/><br/>
                        - 개인정보 담당자 : 마케팅, 고객지원, 위탁업체 직원, 기타 개인정보 취급이 불가피한 자<br/><br/>
                     
                      (2) 물리적 대책<br/>
                         회사는 귀하의 개인정보에 대한 접근권한을 최소한의 인원으로 제한하고 있습니다. 
                     <br/><br/>
                     <br/>
                    제 13조 (기타)<br/>
                    회사는 회원님께 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다. 이 경우 회사는 외부사이트 및 자료에 대한 아무런 통제권이 없으므로 그로부터 제공받는 서비스나 자료의 유용성, 진실성, 적법성에 대해 책임을 지지 않습니다. 회사가 포함하고 있는 링크를 클릭(Click)하여 타 사이트(Site)의 페이지로 이동한 경우 해당 사이트의 개인정보취급방침은 자사의 정책과 무관하므로 새로 방문한 사이트의 정책을 확인하시기 바랍니다.
                    현재 개인정보취급방침의 내용 추가, 삭제 또는 수정이 있을 시에는 지체 없이 『정보통신망법』 에서 정하는 절차에 따라 회원님께 고지하도록 하겠습니다.

                                </p>

                            
                            </div>      
            <div className="modal-content btn_absolute is-flex x-between">
            <input className="bg-orange1 fz-16 c-white button"type="button"value="확인" onClick={agree} />
            <input className="bg-white fz-16 button" type="button"value="취소" onClick={()=>props.setModal2('none')}/>

        </div>
        
    </div>
    )
}

export default SignUPModal_2
