import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import logo from "../../../image/publishing_Img/logo_black.png";
import axios from "axios";
import { url } from "../../../enum";
import XLSX from "xlsx";
import gosuData from "./1.gosuData";
import testCenterData from "./2.testCenter";
import testData from "./0.testData";
import ReactGa from "react-ga";

function LandingPage(props) {
  const [latitude, setLatitude] = useState({ latitude: 0 });
  const [longitude, setLongitude] = useState({ longitude: 0 });
  const [versionCheck, setVersionCheck] = useState({ check: false });
  const UA =
    process.env.NODE_ENV === "production" ? "UA-192634952-2" : "UA-192634952-1";
  useEffect(() => {
    ReactGa.initialize(UA);
    ReactGa.set({ page: window.location.pathname });
    ReactGa.pageview(window.location.pathname + window.location.search);
    ReactGa.event({
      category: "stackNumberOfVisiter",
      action: "visitPage",
      label: "LANDINGPAGE",
      nonInteraction: true,
    });
  }, []);

  useEffect(() => {
    const currentVersion = parseInt(localStorage.getItem("version"));
    console.log(currentVersion, "현재버전");
    axios.get(url + "/settings/excel").then((res) => {
      
      let version = res.data.excel.version;
      console.log(version);
      axios
        .get(res.data.excel.url, { responseType: "arraybuffer" })
        .then((res) => {
          let setData = new Uint8Array(res.data);
          let workExcel = XLSX.read(setData, { type: "array" });

          //고수면허 학원 정보 긁어오기
          let sheetName = workExcel.SheetNames[2];
          let workSheet = workExcel.Sheets[sheetName];
          gosuData(workSheet);

          //시험장 정보 긁어오기
          //고수면허 학원 정보 긁어오기
          sheetName = workExcel.SheetNames[1];
          workSheet = workExcel.Sheets[sheetName];
          testCenterData(workSheet);

          sheetName = workExcel.SheetNames[0];
          workSheet = workExcel.Sheets[sheetName];
          console.log(sheetName);
          testData(workSheet);
        });

      localStorage.setItem("version", version);
    });
  }, [versionCheck]);

  useEffect(() => {
    //로그인 되어있는지 안되어있는지 확인

    axios
      .post(url + "/users/is-logged-in", {
        token: localStorage.getItem("token"),
      })
      .then((res) => {
        if (res.data.resCode !== 0) {
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          localStorage.removeItem("schedule");
        }
      });
    setLatitude({ latitude: window.localStorage.getItem("latitude") });
    setLongitude({ longitude: window.localStorage.getItem("longitude") });
    latitude.latitude = window.localStorage.getItem("latitude");
    longitude.longitude = window.localStorage.getItem("longitude");
    //react-native에서 메세지받기
    window.__WEBVIEW_BRIDGE__ = {
      init: function () {
        try {
          //ios에서 메세지 받기
          window.addEventListener("message", (e) => {
            //위치정보 초기화
            window.localStorage.removeItem("latitude");
            window.localStorage.removeItem("longitude");
            window.localStorage.removeItem("location");
            let loc = e.data;
            let locationArray;
            try {
              locationArray = loc.split(",");
              window.localStorage.setItem(
                "latitude",
                parseFloat(locationArray[0])
              );
              window.localStorage.setItem(
                "longitude",
                parseFloat(locationArray[1])
              );
              window.localStorage.setItem("location", {
                latitude: locationArray[0],
                longitude: locationArray[1],
              });
            } catch (error) {
              console.log("IOS가 기기가 아님니다.");
            }
          });

          //안드로이드 전용
          // document.addEventListener("message",e=>{alert(e.data)});
        } catch (error) {
          console.log(error);
        }
      },
    };
    window.__WEBVIEW_BRIDGE__.init();
  }, []);

  const confirmLocation = () => {
    setLatitude({ latitude: window.localStorage.getItem("latitude") });
    setLongitude({ longitude: window.localStorage.getItem("longitude") });
    latitude.latitude = window.localStorage.getItem("latitude");
    longitude.longitude = window.localStorage.getItem("longitude");
    window.localStorage.setItem(
      "location",
      JSON.stringify({
        latitude: latitude.latitude,
        longitude: longitude.longitude,
      })
    );

    if (localStorage.getItem("license")) {
      window.location.href = "/menu";
    } else {
      window.location.href = "/licenseChoice";
    }
  };
  return (
    <div className="full-height bg-orange2 " onClick={confirmLocation}>
      <div className="wrap-splash">
        <div className=" bg-white bar">
          <img className="logo_img" alt="랜딩페이지 이미지" src={logo} />
        </div>

        <div className="touch_text">
          <p className="subTitle">1종/2종 보통, 이거면 끝!</p>
          <br />
          <br />
          TAP TO START
        </div>
      </div>
    </div>
  );
}

export default withRouter(LandingPage);
