import React, { useEffect, useState, Link } from "react";
import Icon_youtube from "../../../image/publishing_Img/yt_icon_rgb.png";
import Icon_feather_phone_call from "../../../image/publishing_Img/Icon-feather-phone-call.png";
import Icon_black_map_marker from "../../../image/publishing_Img/Icon-black-map-marker.svg";
import axios from "axios";
import { url } from "../../../enum";
import FunctionalCourseLandingpage from "./FunctionalCourseLandingpage";

function FunctionalLocationChild(props) {
  const [menuName,setmenuName] = useState("");
  const [savelocation, setlocation] = useState([]);
  
    const [myLatitude, setMyLatitude] = useState(localStorage.latitude);
    const [myLogitude, setMyLongitude] = useState(localStorage.longitude);
  if (props.menuName != menuName) {
    setmenuName(props.menuName);
    getLocations();
  }
    function getLocations() {
      axios
        .get(url + "/videos/location?location=" + props.menuName)
        .then((res) => setlocation(res.data.rows));
    }
 
  useEffect(() => {
     let getLocation = setInterval(() => {
       //핸드폰에선 스토리지에 넣는 것이 느려 질 수도 있으므로 계속해서 채크한다.(웹에선 기본 null값으로 나온다. 위치정보를 가져오지 않는다.)
       if (window.localStorage.getItem("latitude")) {
         setMyLatitude(window.localStorage.getItem("latitude"));
         setMyLongitude(window.localStorage.getItem("longitude"));

         clearInterval(getLocation);
       }
     }, 500);
    getLocations();
    if (localStorage.getItem("favorit")) {
      CheckChange();
    }
     return () => {
     };
   }, []);
   
  function getDistanceFromLatLonInKm(lat1, lng1, lat2, lng2) {
    function deg2rad(deg) {
      return deg * (Math.PI / 180);
    }
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1); // deg2rad below
    var dLon = deg2rad(lng2 - lng1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
  }
  
  var handleChange = (event) => {
    console.log(event.target.checked + " : " + event.target.value);
    localStorage.removeItem("favorit");
    if (event.target.checked)
      localStorage.setItem("favorit", event.target.value);
    CheckChange();
  };
   const CheckChange = function () {
     let menu = document.getElementsByClassName("star");
     if (localStorage.getItem("favorit")) {
       let save = localStorage.getItem("favorit");
       for (let i = 0; i < menu.length; i++) {
         if (menu[i].value == save) {
           menu[i].checked = true;
         } else {
           menu[i].checked = false;
         }
       }
     } else
     {
       let save = localStorage.getItem("favorit");
       for (let i = 0; i < menu.length; i++) {
         menu[i].checked = false;
       }
      }
   };
  return (
    <div className="video_list_box bg-white mt-10">
      {CheckChange()}
      <p className="fz-14 fw-bold mb-20">{props.menuName}</p>
      <div className="test_place_list ">
        <ul>
          {savelocation.map((value, index) => (
            <li key={index} className="list_box">
              <a href={"course?menuName=" + value.name}>
                <div>
                  <p className="fz-16 c-text11 lh-15">{value.name}</p>
                  <p className="fz-12 c-text1 lh-15">{value.info}</p>
                </div>
              </a>
              <div className="right_icons is-flex">
                <label>
                  <div>
                    <a href={"course?menuName=" + value.name}>
                      <img
                        alt="아이콘"
                        className="zocial_www"
                        src={Icon_youtube}
                      />
                    </a>
                  </div>
                </label>
                <label>
                  <div>
                    <input
                      className="zocial_www star"
                      type="checkbox"
                      title="bookmark page"
                      value={value.name}
                      onChange={handleChange}
                    />
                  </div>
                </label>
                <label>
                  <div>
                    <img
                      alt="마커"
                      className="map_marker"
                      src={Icon_black_map_marker}
                    />
                  </div>
                  <p>
                    {Math.round(
                      getDistanceFromLatLonInKm(
                        value.latitude,
                        value.longitude,
                        parseFloat(myLatitude),
                        parseFloat(myLogitude)
                      )
                    )}
                    km
                  </p>
                </label>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default FunctionalLocationChild;
