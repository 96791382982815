import React from 'react'
import logo_black from '../../../image/publishing_Img/logo_black.png'
function SignUPModal_1(props) {

    const agree =()=>{

        props.setModal1('none')
        props.setTermsConditions("1")
    }
    return (
        <div className="modal-2" style={{display:props.modal1}}>
        <div className="modal-dim"></div>
        <div className="wrap_box bg-white modal-content">

            
                <div className="logo-2 mb-25 ">
                    <img src={logo_black}/>
                    <p className="fz-20 fw-bold">이용약관</p>
                </div>

               <p className="guide_text fz-10 bg-gray1"> 
                (주) 제이에프파트너스 서비스 이용약관
                 <br/><br/>
                제 1장 총칙
                 <br/><br/>
                제 1조(목적) 
                <br/><br/>
                본 약관은 (주)제이에프파트너스 (이하 '회사')가 제공하는 서비스의 이용조건 및 절차, 회원의 권리〮의무 등 기타 필요한 사항을 규정함을 목적으로 합니다
                 <br/>
                제 2조(용어의 정의)
                <br/>
                1. 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br/>
                  (1) "회사"라 함은 유, 무선통신망 등을 통하여 "서비스"를 제공하는 사업자 주체인 "(주)제이에프파트너스 " 및 그 관계회사를 의미합니다.
                  <br/>
                  (2) "회원" 이란 회사의 서비스 관련 어플리케이션을 다운로드 받아 이용하는 자를 의미합니다.
                  <br/>
                  (3) "콘텐츠"라 함은 회사가 서비스를 위해 제공하는 어플리케이션을 의미합니다. 
                  <br/>
                  (4) "모바일 기기"라 함은, 휴대전화, 태블릿PC, 휴대용 게임기, 기타 회사가 제공하는 어플리케이션을 다운로드 받아 설치하여 이용할 수 있거나, 네트워크를 통해 회사의 서비스를 이용할 수 있는 기기를 지칭합니다.
                  <br/>
                  (5) "서비스"라 함은 "회사"의 웹사이트, 모바일 사이트, 모바일 어플리케이션 등의 플랫폼을 통해 "회원"에게 제공되는 서비스 고객지원, 정보제공 기타 이에 부수하는 모든 서비스를 말합니다.
                  <br/>
                  (6) "회원 계정" 이란 "회원"이 "회사"의 서비스 이용을 위해 가입한 오프라인상의 회원의 신분을 의미합니다.
                    <br/>  
                  (7) "위치정보"라 함은 이동성이 있는 물건 또는 개인이 특정한 시간에 존재하거나 존재하였던 장소에 관한 정보로서 「전기통신사업법」 제2조제2호 및 제3호에 따른 전기통신설비 및 전기통신회선설비를 이용하여 수집된 것을 의미합니다.
                  <br/>
                  (8) "개인위치정보"라 함은 특정 개인의 위치정보(위치정보만으로는 특정 개인의 위치를 알 수 없는 경우에도 다른 정보와 용이하게 결합하여 특정 개인의 위치를 알 수 있는 것을 포함한다)를 말한다.
                  <br/>
                  (9) "개인위치정보주체"라 함은 개인위치정보에 의하여 식별되는 자를 말한다.
                  <br/>
                  (10) "위치기반서비스사업"이라 함은 위치정보를 이용하여 "회사"가 이를 활용하여 제공하는 "서비스"를 의미합니다.
                  <br/>
                2. 본 약관에서 사용하는 용어의 정의는 제 1항 각 호에서 정하는 것을 제외하고 관계법령 및 기타 일반적 상관례에 의합니다.
                 <br/>
                제 3조(약관의 효력, 명시와 변경)
                1. 이 약관은 서비스 초기화면에 게시, 공지되거나 전자우편 등의 방법으로 공시되는 시점부터 효력이 발생하고, 회원이 약관에 동의한 시점부터 약관의 적용을 받습니다. 
                 <br/>
                2. "회사"는 본 약관의 내용을 "회원"이 쉽게 알 수 있도록 "서비스"의 초기 화면, "서비스 내 설정 화면" 등 "회사"가 지정하는 곳에 게시하여 "회원"에게 공지합니다.
                 <br/>
                3. "회사"는 본 약관의 개정 필요 시, 『약관의 규제에 관한 법률』, 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』, 『콘텐츠산업 진흥법』, 『게임산업진흥에 관한 법률』, 『전자상거래 등에서의 소비자 보호에 관한 법률』 등의 관련 법령에 위배되지 않는 범위에서 서비스 기간 동안 약관의 일부 또는 전부를 변경할 수 있습니다. 
                 <br/>
                4. "회사"는 약관의 변경된 내용에 관해 서비스 적용 7일전부터 공시할 의무가 있으며, 회원에게 불리하게 적용되는 약관일 경우 적용일로부터 30일전에 공시할 의무가 있습니다. 변경된 약관은 공시한 적용일로부터 효력이 발생합니다. 
                 <br/>
                5. "회사"가 전항에 따라 개정약관을 공시하였음에도 불구하고 변경된 약관의 효력 발생일 이후에도 "서비스"를 계속 이용할 경우, 회원이 명시적 거부의사를 표시 하지 않은 경우 회원은 개정약관에 동의한 것으로 간주합니다.
                 <br/>
                6. "회원"은 변경된 약관에 동의하지 않을 권리가 있으며, 변경된 약관에 동의하지 않을 경우에는 서비스 이용을 즉시 중단, 탈퇴 할 수 있습니다.
                 <br/>
                제 4조(약관 외 준칙) 
                약관에서 규정하지 아니한 사항에 대해서는 『약관의 규제에 관한 법률』, 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』, 『콘텐츠산업 진흥법』, 『게임산업진흥에 관한 법률』, 『전자상거래 등에서의 소비자 보호에 관한 법률』, 『위치정보의 보호 및 이용 등에 관한 법률』 등의 관련 법령 및 "회사"의 운영정책에 따릅니다. 
                 <br/><br/>
                제 2장 서비스 이용계약
                 <br/><br/>
                제 5조(이용신청 및 방법) <br/><br/>
                1. "회원"은 "회사"가 제공하는 "서비스"에 대한 약관을 읽고 그 내용에 대한 동의한 후 회사가 정한 방법 및 절차에 따라 이용신청을 하여야 합니다. 
                 <br/>
                2. "회원"은 이용신청 시 반드시 실명 및 실제 정보를 기재하여야 하며 실명 및 실제 정보를 기재하지 아니한 경우 약관에 따른 "회원"의 권리를 주장할 수 없으며, "회사"는 환급없이 이용계약을 취소, 해지 할 수 있습니다.
                 <br/>
                3. "회원"께서 만일, 하기와 같은 목적으로 "회사"의 "서비스"를 이용하실 경우 회원의 계정은 사전예고 없이 제한 및 이용기록이 삭제 등의 불이익을 받을 수 있습니다. <br/>
                  (1) 타인 소유의 모바일 기기를 도용하여 이용 신청을 한 경우 <br/>
                  (2) 형법 및 청소년보호법 등 기타 현행 법령을 위반하여 "서비스"를 이용하고자 하는 경우<br/>
                  (3) 개인의 영리 목적으로 "서비스"를 이용하고자 하는 경우<br/>
                  (4) "회사"의 "서비스"와 경쟁 관계에 있는 일방의 "회원"이 "회사"의 이익을 저해하려는 목적으로 "서비스"를 이용하고자 하는 경우<br/>
                  (5) "회사"가 지정한 "서비스" 국가 외의 국가에서 접속한 이용자에 대해, 운영 및 정책상 관리의 필요가 있는 경우
                  <br/>
                 <br/>
                제 6조(이용신청의 승낙과 제한) <br/><br/>
                1. "회원"이 "회사"가 요구하는 정보에 대한 실명 및 실제정보를 명확히 기재하여 이용신청을 한 경우, 회사는 이용신청을 승인합니다.
                 <br/>
                2. "회사"는 다음 각 호에 해당하는 이용신청에 대해서는 승인을 거부할 수 있으며, 추후 밝혀진 사항에 대해서 계약을 취소, 해지 할 수 있습니다.
                  <br/>
                  (1) 실명이 아닌 경우<br/>
                  (2) 타인의 개인정보를 이용 또는 도용하여 신청한 경우<br/>
                  (3) "회사"가 요구하는 정보를 누락하거나, 허위의 사실로 기재하여 신청하는 경우<br/>
                  (4) 개인의 영리를 목적으로 신청하는 경우<br/>
                  (5) 사회의 공공복리, 미풍양속, 부정한 용도, 명예훼손, 영업방해 등의 사회 상관례상 허용되지 않는 목적 및 정보(아이디, 닉네임)으로 신청한 경우<br/>
                  (6) 이용요금을 납부하지 않거나 잘못 납부하여 확인 할 수 없는 경우<br/>
                  (7) 약관 또는 운영정책에 위반된 행위로 인해 영구제재 당한 기록이 있는 경우<br/>
                  (8) "회사"가 "서비스"를 제공할 것으로 결정하지 않은 국가에서 "서비스"를 이용하는 경우<br/>
                 
                3. 회사는 다음의 각호의 해당하는 경우 그 사유가 해소될 때까지 승인을 유보할 수 있습니다. <br/>
                  (1) "회사"의 설비 부족, 기술적인 오류, 장애가 있는 경우<br/>
                  (2) "서비스"상의 장애 또는 서비스 이용요금 결제수단에 오류, 장애가 있는 경우<br/>
                  (3) 그 밖의 기타 사정으로 인해 이용 승낙이 곤란한 경우
                 <br/><br/>
                제 7조(회원 정보의 제공 및 변경) <br/><br/>
                1. "회원"은 자신의 개인정보를 항시 수정할 수 있습니다. 다만, "서비스" 제공 및 관리를 위하여 필요한 계정 등의 개인정보의 수정은 불가능 할 수 있습니다.<br/>
                2. "회원"은 "회사"에 제공된 정보가 차후 변경되었을 경우, 지체 없이 "서비스" 내 회원정보 수정 페이지나, 고객센터 등을 통하여 변경된 정보로 수정하여야 합니다. 변경된 사항을 "회사"에게 통지하지 않아 발생하게 되는 불이익에 대해서 "회사"는 책임을 지지 않습니다.
                 <br/><br/>
                제 8조(개인정보의 보호 및 사용) <br/><br/>
                1. "회사"는 관계 법령이 정하는 바에 따라 회원의 등록정보를 포함한 개인정보를 보호하기 위해 노력합니다. 회원의 개인정보 보호 및 사용에 관해서는 관계 법령 및 회사의 개인정보취급방침이 적용됩니다.<br/>
                2. "회사"는 "회원"의 개인정보를 본인의 승낙없이 제 3자에게 누설 및 배포하지 않습니다. 단 관계법령에 따라 공신력 있는 기관의 요청이 있는 경우 또는 범죄에 대한 수사상의 목적이 있는 경우에는 예외적 사항에 해당합니다.<br/>
                3. "회사"는 "회원"의 귀책사유로 인하여 노출된 개인정보에 대해서는 일체의 책임을 지지 않습니다.<br/>
                4. "회사"는 "회원"의 개인정보 보호를 위하여 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』 상의 개인정보 유효기간제도에 따라 1년간 미접속한 "회원"의 개인정보를 파기 또는 분리하여 별도로 저장, 관리하며(휴면계정), "회원"의 별도의 요청이 없는 한 사용이 제한됩니다.
                 <br/><br/>
                제 3장 계약 당사자의 의무
                 <br/><br/>
                제 9조(회사의 의무) <br/>
                1. "회사"는 관련 법령을 준수하고, 본 약관이 정하는 권리의 행사, 의무의 이행을 신의성실에 원칙에 입각하여 수행합니다. <br/>
                2. "회사"는 지속적, 안정적 서비스 제공을 위하여 노력합니다. 예외적으로 천재지변, 비상사태 등이 발생한 경우 또는 서비스 개선을 위하여 필요한 경우 서비스의 제공이 중지될 수 있습니다.<br/>
                3. "회사"는 "서비스"가 지속적·안정적으로 "회원"에게 제공하기 위해 최선을 다하며, 안전하게 "서비스"를 이용할 수 있도록 보안시스템을 운영 및 관리합니다.<br/>
                4. "회사"는 "회원"의 계정정보를 포함한 회원의 개인정보가 제3자에가 유출되지 않도록 최선을 다합니다.<br/>
                5. "회사"는 "회원"의 문의, 건의, 불만 사항에 대해 객관적으로 정당하다고 인정되는 경우, 적절한 절차를 거쳐 신속히 처리합니다. 다만, 즉시 처리가 곤란한 사유 발생시 사유 및 처리 일정을 "회원"에게 알려드립니다.
                 <br/><br/>
                제 10조(회원의 의무) <br/>
                1. "회원"은 서비스 이용신청을 함에 있어 본인의 실명 및 진실된 사실에 근거하여 작성하여야 합니다. <br/>
                2. "회원"는 "회사"가 서비스 이용과 관련하여 필요한 사항들을 알릴 수 있도록 연락 가능한 E-mail 주소  등을 제공합니다. <br/>
                3. "회원"은 아래의 각 호에 해당하는 행위를 하지 않아야 합니다 <br/>
                  (1) "회사"의 운영진, 임직원 또는 관계자를 사칭하는 행위<br/>
                  (2) "회사" 및 타인의 "서비스"의 제공 및 이용을 방해하는 행위<br/>
                  (3) "회사"가 허용하지 않은 컴퓨터 프로그램이나 기기 또는 장치를 제작, 배포, 이용, 광고하는 행위<br/>
                  (4) "회사"가 제공하는 "서비스"를 고의적으로 방해하는 행위<br/>
                  (5) "서비스" 내 버그를 악용하거나, 비정상적인 방법으로 기록, 점수 등을 취득하는 행위<br/>
                  (6) "서비스"를 개인의 영리취득의 목적으로 이용한 경우<br/>
                  (7) "회사" 또는 제 3자의 저작권 등 지식재산권을 침해하는 행위<br/>
                  (8) "회사"의 업무를 의도적으로 방해하거나, "회사"의 명예를 실추시키는 행위<br/>
                  (9) 범죄와 관련이 있다는 판단되는 행위<br/>
                  (10) 기타 관계 법령에 위반되는 행위 <br/>
                 
                4. "회원"은 "서비스" 이용 중 본인의 계정 및 비밀번호 및 개인의 잘못으로 인해 발생하는 모든 경우에 대해 책임을 지며, "회사"는 위에 대한 어떠한 책임도 부담하지 않습니다.
                 <br/><br/>
                제 4장 서비스의 이용 및 제한, 중단
                 <br/><br/>
                제 11조(서비스의 이용)<br/><br/>
                1. "회사"는 "서비스"를 이용하는 모바일 기기의 특성 및 "회원"의 이용 편의를 위하여 "회원"에게 임의의 숫자와 문자 등으로 조합된 ID를 발급할 수 있습니다. <br/>
                2. "회사"는 애플리케이션 또는 네트워크를 통해 "서비스"를 제공하며, "회원"은 애플리케이션을 다운로드하여 무료 "서비스"를 이용할 수 있습니다.<br/>
                3. 무료 "콘텐츠"의 경우 무료로 이용가능합니다.
                 <br/><br/>
                제 12조(서비스의 제한)<br/>
                1. "회원"이 회원의 의무조항이나 본 약관에 위반되는 행위를 할 경우, 회사는 해당 "회원"의 서비스 이용에 관해 경고조치, 계약해지, 계정삭제 등의 단계적 조치를 취할 수 있습니다. <br/>
                2. "회원"이 미성년자일 경우 법정대리인이나 보호자의 요청이 있을 경우 "회사"는 해당 회원에 서비스 이용에 제한을 가할 수 있습니다.<br/>
                3. "회사"는 서비스 및 운영의 수정 및 개선이 필요할 경우, 해당 서비스 및 운영의 내용을 추가, 삭제, 변경 할 수 있습니다. <br/><br/>
                 
                제 13조(서비스의 중단)<br/>
                1. "회원"은 개인의 사정으로 인하여 계약을 해지하거나 서비스의 이용 중단를 요청 할 수 있습니다.
                 <br/><br/>
                2. "회사"는 다음 각 호에 해당하는 경우 "서비스"를 제공하지 아니할 수 있습니다. <br/>
                  (1) 컴퓨터 등 정보통신 관련 망들의 정기점검, 긴급점검, 교체 등의 경우<br/>
                  (2) 해킹 등의 정보통신 등의 침해사고, "회원"들의 비정상적인 "서비스 이용"의 경우<br/>
                  (3) 관련 법령에서 특정 시간 또는 방법에 따라 "서비스"제공을 금지하는 경우<br/>
                  (4) 천재지변, 비상사태, 정전, 서비스 설비의 장애 등의 경우 <br/>
                  (5) "회사"의 수익 악화 등 경영상 중대한 문제가 발생하는 경우<br/><br/>
                 
                3. "회사"는 2항 1호의 경우 최소 24시간 이전에 그 사실을 "회원"에게 서비스 초기화면, 공지사항 등을 통하여 고지합니다. 다만, 긴급한 점검일 경우 차후 관련 사항들을 "회원"에게 고지합니다. <br/><br/>
                 
                제 14조(광고의 게재)<br/>
                1. "회사"는 "서비스"의 운영과 관련하여 "서비스 초기 화면", "홈페이지" , 전자우편, SMS, 알림 메시지(Push)기능 등을 사용하여 광고를 제공할 수 있습니다. 광고의 수신자는 "회사"에게 수신거부의 의사표시를 할 수 있으며, "회사"는 "회원"의 수신거부에 의사에 반하는 광고를 제공하지 않습니다.<br/>
                2. "회사"가 제공하는 "서비스"에는 관련 링크, 팝업창, 배너 등의 다양한 광고가 포함되어 있으며, 이를 제 3자가 제공하는 영역으로 연결 될 수 있습니다. <br/>
                3. 제 3자가 제공하는 서비스 페이지로 연결 될 경우, 제 3자와의 연관성이 없는 이상 "회사"는 해당 페이지를 신뢰, 안정성을 보장하지 않으며, 이로 인해 발생한 "회원"의 손해에 대해 "회사"는 책임을 지지 않습니다.
                 <br/><br/>
                제 15조(이용상품)<br/>
                1. "회사"는 "회원"이 별도 비용지급 없이 이용할 수 있는 "서비스"(이하 "무료서비스") 를 제공하며, "회원"은 선택적으로 이를 이용할 수 있습니다.
                 <br/><br/>
                제 5장 청약철회, 계약의 해제 및 해지
                   <br/><br/>
                제16조(회사의 해제 및 해지)<br/>
                1. "회사"는 "회원"이 이 약관에서 규정한 내용 및 현행법을 고의 및 중대한 과실로 위반하여 "회사"에게 손해를 입힌 경우 이용계약을 해지 할 수 있습니다. 이러한 경우 "회원"은 "서비스"의 사용권한을 상실하고 이로 인한 환불 및 손해배상은 청구할 수 없습니다.<br/>
                2. "회사"가 이용계약을 해지하는 경우 "회사"는 "회원"에게 전자우편 등의 방법으로 통보합니다.<br/><br/>
                 
                제17조(회원의 해제 및 해지)<br/>
                1. "회원"은 "서비스" 이용계약을 해지할 수 있습니다. "회원"이 이용계약 해지를 신청한 경우, "회사"는 "회원" 본인 의사 여부를 확인할 수 있으며, 이러한 확인 절차를 거친 후 "회원"이 신청한 조치에 따릅니다.
                 <br/>
                2. "회원"이 회원탈퇴를 원하는 경우에는 "서비스" 내 계정삭제, 고객센터를 통한 회원탈퇴를 할 수 있습니다.
                 <br/><br/>
                제18조(잠정조치로의 이용제한)<br/>
                1. "회사"는 아래 각 호에 해당하는 문제에 대한 조사가 완료될 때까지 "회원"의 서비스 이용을 중지 할 수 있습니다.
                 <br/><br/>
                  (1) 해킹 또는 도용 관련 신고 접수 시<br/>
                  (2) 불법프로그램 사용자, 버그 사용 등 합리적 의심이 가는 위법행위자
                 <br/><br/>
                제19조(이용제한에 대한 이의신청 절차)<br/>
                1. "회원"은 이용제한 등에 대해 "회사"가 정한 절차에 따라 이용제한을 받은 날로부터 15일 이내에 이의신청을 할 수 있습니다. <br/><br/>
                 
                2. "회사"는 "회원"이 제기하는 불만, 이의사항이 정당하다고 판단될 때에는 합리적 기간 내에 신속하게 처리합니다.<br/><br/>
                 
                3. "회사"는 위의 처리 결과에 따른 상응조치를 취하여야 합니다. <br/><br/>
                 
                제 6장 손해배상 <br/><br/>
                 
                제20조(손해배상) <br/>
                1. "회사"는 무료로 제공하는 "서비스"와 관련하여 "회원"에게 발생한 손해에 대해서, "회사"의 고의 또는 중대한 과실로 인하여 발생하지 않은 이상 어떠한 책임도 지지 않습니다.<br/>
                2. "회원"은 본 약관을 위반하거나 "서비스"를 이용함에 있어서 "회사"에 손해를 끼친 경우, 그 손해에 대하여 배상할 책임이 있습니다. <br/>
                제21조(회원의 고충처리 및 분쟁해결)<br/>
                1. "회사"는 "회원"의 편의를 위해 "회원"의 의견이나 불만 제기 방법이나 절차를 "서비스", "공지사항", 등의 영역에 고지합니다. "회사"는 이러한 "회원"의 의견이나 불만을 처리하기 위한 전담조직을 운영합니다.<br/>
                2. "회사"는 "회원"으로부터 제기되는 의견이나 불만이 정당하다고 인정되는 경우, 합리적 기간 내에 신속하게 처리합니다. 만일, 장기간이 소요되는 경우 "회원"에게 기간 소요 사유와 향후 처리일정을 "서비스"내 공지사항 등을 통하여 공지하거나 전자우편 등으로 통보합니다. <br/>
                3. "회사"와 "회원"간에 분쟁이 발생하여 제 3의 분쟁조정기관이 관여할 경우 회사는 이용제한 등 "회원"에게 조치한 사항을 신의성실에 따라 증명하고, 조정 기관에 조정에 따를 수 있습니다. <br/>
                4. "회사"가 운영하는 고객센터의 운영 시간은 아래와 같습니다.<br/>
                  (1) 운영 시간 : 09:00 ~ 18:00<br/>
                  (2) 근무일 : 월요일 ~ 금요일(토, 일요일 및 공휴일 제외)
                 <br/><br/>
                제22조(회사의 면책)<br/>
                1. "회사"는 회원이 서비스를 이용하여 기대하는 수익을 얻지 못한 것에 대하여 책임을 지지 않으며 서비스에 대한 선택 또는 이용으로 발생하는 손해에 대해서 책임을 지지 않습니다.
                2. "회사"는 전시, 사변, 국가 비상사태 등 현재의 기술이나 불가항력적 요소로 인한 서비스의 중지에 경우에는 책임이 면제됩니다. <br/>
                3. "회사"는 "회원"의 귀책사유로 인하여 발생한 "회원" 본인에게 발생한 손해에 대해서는 책임을 지지 않습니다.<br/>
                4. "회사"는 "회원"의 모바일기기 운영의 문제로 인하여 발생하는 제반 문제 또는 객관적으로 "회사"의 귀책사유가 없는 경우에 발생하는 문제에 대해서는 책임을 지지 않습니다.<br/>
                5. "회사"는 사전에 공지된 "서비스"의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 "서비스"가 중지되거나 장애가 발생한 경우에 대해서 "회사"의 중대한 고의, 과실이 없는 한 책임이 면제됩니다.<br/>
                6. "회사"는 "회원"의 모바일 기기 및 모바일 네트워크 문제로 인하여 발생하는 제반사항에 대해서 "회사"의 중대한 고의, 과실이 없는 한 책임이 면제됩니다.<br/>
                7. "회사"는 "회원" 상호간 또는 "회원"과 "회사"와 연관되지 않은 제 3자간에 "서비스"를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며, 손해배상 책임을 지지 않습니다.<br/>
                8. "회사"가 제공하는 서비스 중 "무료서비스"의 경우 "회사"는 어떠한 책임도 지지 않습니다.<br/>
                9. "회사"는 "회원"이 신상정보 및 전자우편주소 등의 부정확한 개인정보에 기재에 대한 손해가 발생한 경우 "회사"의 중대한 고의, 과실이 없는 한 책임이 면제됩니다.<br/>
                13. "회원"은 안내사항, "회사"의 어플리케이션 이용을 위한 기술사항, 가격, 주의사항 등을 주의 깊게 살펴본 후 "회사"의 서비스 어플리케이션을 다운로드 받아야 합니다. 만일, 이러한 사항들을 간과하여 발생한 이용장애 등의 문제에 관하여 "회사"는 어떠한 책임도 지지 않습니다.
                 <br/><br/>
                제 7장 기타사항<br/><br/>
                 
                제25조(저작권 등의 귀속) <br/>
                1. "회사"가 제작한 콘텐츠에 대한 저작권 및 기타 부수 지적재산권은 "회사"의 소유입니다. "회사"는 "서비스"와 관련하여 "회원"에게 "회사"가 정한 이용조건에 따라, "회원"은 이를 양도, 판매 또는 담보로 제공하는 등의 처분행위를 할 수 없습니다.<br/>
                2. "회원"은 "서비스" 도중에 얻은 정보를 "회사" 또는 제공업체의 사전 승낙 없이 복제, 전송, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제 3자에게 이용하게 하여서는 안됩니다. <br/>
                3. "회원"은 본 저작물에 관련한 제 2차적 저작물에 관해서 영리목적으로 이용할 수 없습니다. <br/>
                4. "회사"는 "회원"이 게시하거나 등록한 "서비스" 내의 게시물, 게시 내용에 대해 약관에 정하는 금지행위에 해당된다고 판단하는 경우 사전 통지 없이 삭제, 이동, 등록 거부를 할 수 있습니다.<br/>
                5. "회사"가 운영하는 게시판 등에 게시된 정보로 인하여 법률상 이익이 침해된 자는 "회사"에게 당해 정보의 삭제 또는 반박내용의 게재를 요청할 수 있습니다.<br/>
                6. "회사"가 제공하는 서비스 및 그와 관련된 소프트웨어 기술, 상표, 디자인 등 모든 지적재산권 및 기타 소유권은 "회사"에게 있습니다. <br/><br/>
                 
                제26조(관할법원)<br/>
                본 약관 및 "회사"와 "회원"간의 발생한 분쟁은 대한민국 법의 적용을 받으며, 소송이 제기되는 경우 서울중앙지방법원을 관할 법원으로 합니다. <br/>
                - 공고일자 : 2021년 4월 1일<br/>
                - 시행일자 : 2021년 4월 1일 <br/>
                
               </p>

         
        </div>      
            <div className="modal-content btn_absolute is-flex x-between">
            <input className="bg-orange1 fz-16 c-white button"type="button"value="확인" onClick={agree} />
            <input className="bg-white fz-16 button" type="button"value="취소" onClick={()=>props.setModal1('none')} />

        </div>
        
    </div>
    )
}
export default SignUPModal_1