import React, { useState } from 'react'


function DetailTab(props) {
    
    const [view,setView]=useState((
            <div className="exemption_check bg-white">
            <h1 className="c-text11 fz-16 fw-bold ml-20 mb-10">시험 면제</h1>
            <div className="mb-20">
                <h2 className="c-text11 fz-14 ml-20 float-left">표에서 </h2>
                <div className="orange_circle mx-10 float-left"></div> 
                <h2 className="c-text11 fz-14 ml-20"> 표시가 없는 곳이 면제된 과목입니다.</h2>
            </div>
            <table className="test_exemption_table">
                <thead className="bg-orange2">
                <tr>
                    <th rowSpan="2" className="fz-12 text-center c-white">대상자</th>
                    <th rowSpan="2" className="fz-12 text-center c-white">받고자하는 면허</th>
                    <th colSpan="4"className="subject_text c-white">
                        시 험 과 목
                    </th>
                </tr>
            
                <tr>
                    <td className="fz-10 c-white text-center">신검</td>
                    <td className="fz-10 c-white text-center">학과</td>
                    <td className="fz-10 c-white text-center">기능</td>
                    <td className="fz-10 c-white text-center">주행</td>
                </tr>
                </thead>
                <tbody>
                <tr className="text">
                    <td>
                        국내면허 인정국가의 
                            권한있는 기관에서 교부한 
                            운전면허증 소지자
                    </td>
                    <td>
                        제 2종 보통면허
                    </td>
                    <td>
                        <div className="orange_circle"></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    
                </tr>

                <tr className="text">
                    <td>
                        국내면허 불인정국가의 
                        권한있는 기관에서 교부한 
                        운전면허증 소지자
                    </td>
                    <td>
                        제 2종 보통면허
                    </td>
                    <td>
                        <div className="orange_circle"></div>
                    </td>
                    <td>
                        <div className="orange_circle"></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    
                </tr>

                <tr className="text">
                    <td rowSpan="2">
                        군복무 중 6월 이상 군의 
                        자동차 등 운전 경력자
                    </td>
                    <td>
                        제 1,2종 보통면허 외 면허
                    </td>
                    <td>
                        <div className="orange_circle"></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    
                </tr>

                <tr className="text">
                
                    <td>
                        제 1,2종 보통면허
                    </td>
                    <td>
                        <div className="orange_circle"></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    
                </tr>

                <tr className="text">
                    <td>
                        적성검사·면허갱신을 하지
                        않아 면허 취소 후 5년 
                        이내에 재응시하는 사람
                    </td>
                    <td>
                        취소된 면허와 동일한 1종류의 면허
                    </td>
                    <td>
                        <div className="orange_circle"></div>
                    </td>
                    <td>
                        <div className="orange_circle"></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    
                </tr>

                <tr className="text">
                    <td>
                        제1종 대형면허 소지자
                    </td>
                    <td>
                        제 1종 특수면허,<br/>제 1,2종 소형면허
                    </td>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div className="orange_circle"></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    
                </tr>

                <tr className="text">
                    <td rowSpan="2">
                        제1종 특수면허 소지자
                    </td>
                    <td>
                        제 1종 대형면허<br/>
                        제 1,2종 소형면허
                    </td>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div className="orange_circle"></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    
                </tr>
            
                <tr className="text">
            
                    <td>
                        제1·2종 보통면허
                    </td>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div className="orange_circle"></div>
                    </td>
                    
                </tr>

                <tr className="text">
                    <td rowSpan="2">
                        제1종 보통면허 소지자
                    </td>
                    <td>
                        제1종 대형면허·특수면허
                    </td>
                    <td>
                        <div className="orange_circle"></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div className="orange_circle"></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    
                </tr>
            
                <tr className="text">
            
                    <td>
                        제1·2종 소형면허
                    </td>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div className="orange_circle"></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    
                </tr>

                <tr className="text">
                    <td rowSpan="3">
                        제2종 보통면허 소지자
                    </td>
                    <td>
                        제1종 특수·대형·소형면허
                    </td>
                    <td>
                        <div className="orange_circle"></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div className="orange_circle"></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    
                </tr>
            
                <tr className="text">
                
                    <td>
                        제2종 소형면허
                    </td>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div className="orange_circle"></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    
                </tr>
            
                <tr className="text">
            
                    <td>
                        제1종 보통면허
                    </td>
                    <td>
                        <div className="orange_circle"></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div className="orange_circle"></div>
                    </td>
                    
                </tr>
                
                <tr className="text">
                    <td>
                        제2종 소형면허 또는
                        원동기장치 자전거면허 
                        소지자
                    </td>
                    <td>
                        제 2종 보통면허
                    </td>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div className="orange_circle"></div>
                    </td>
                    <td>
                        <div className="orange_circle"></div>
                    </td>
                    <td>
                        <div className="orange_circle"></div>
                    </td>
                    
                </tr>
            
                <tr className="text">
                    <td>원동기장치 
                        자전거면허 소지자</td>
                    <td>
                        제2종 소형면허
                    </td>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div className="orange_circle"></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    
                </tr>
                <tr className="text">
                    <td>제2종 보통면허 소지자가 
                        면허신청 일로부터 소급
                        하여 7년간 교통사고를 
                        일으킨 사실이 없는 사람</td>
                    <td>
                        제1종 보통면허
                    </td>
                    <td>
                        <div className="orange_circle"></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    
                </tr>
                <tr className="text">
                    <td>제1종운전면허 소지자가 
                        신체장애 등으로 1종운전
                        면허 적성기준에 미달된 자</td>
                    <td>
                        제 2종 보통면허
                    </td>
                    <td>
                        <div className="orange_circle"></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    
                </tr>
                <tr className="text">
                    <td>타인에게 운전면허 대여로 
                        취소된 사람, 무등록차량을 
                        운전하여 취소된 사람</td>
                    <td>
                        취소된 면허와 동일한 1종류의 면허
                    </td>
                    <td>
                        <div className="orange_circle"></div>
                    </td>
                    <td>
                        <div className="orange_circle"></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    
                </tr>
                <tr className="text">
                    <td>군사분계선 이북지역에서
                        운전면허를 받은 사실을 
                        통일부장관이 확인서를 
                        첨부하여 운전면허시험
                        기관의 장에게 통지한 사람</td>
                    <td>
                        제 2종 보통면허
                    </td>
                    <td>
                        <div className="orange_circle"></div>
                    </td>
                    <td>
                        <div className="orange_circle"></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div className="orange_circle"></div>
                    </td>
                    
                </tr>
                </tbody>
            </table>
        </div>
             ))
    
    const menuChange=(e)=>{
        let inputs=document.getElementsByTagName('input')
        for(let i=0; i<inputs.length; i++){
            inputs[i].className="button button-1 bg-white c-text1"
        }

        e.currentTarget.className="button button-1 c-white bg-orange2"
        if(e.target.id==="1"){
            setView((
                <div className="exemption_check bg-white">
                <h1 className="c-text11 fz-16 fw-bold ml-20 mb-10">시험 면제</h1>
                <div className="mb-20">
                    <h2 className="c-text11 fz-14 ml-20 float-left">표에서 </h2>
                    <div className="orange_circle mx-10 float-left"></div> 
                    <h2 className="c-text11 fz-14 ml-20"> 표시가 없는 곳이 면제된 과목입니다.</h2>
                </div>
                <table className="test_exemption_table">
                    <thead className="bg-orange2">
                    <tr>
                        <th rowSpan="2" className="fz-12 text-center c-white">대상자</th>
                        <th rowSpan="2" className="fz-12 text-center c-white">받고자하는 면허</th>
                        <th colSpan="4"className="subject_text c-white">
                            시 험 과 목
                        </th>
                    </tr>
                
                    <tr>
                        <td className="fz-10 c-white text-center">신검</td>
                        <td className="fz-10 c-white text-center">학과</td>
                        <td className="fz-10 c-white text-center">기능</td>
                        <td className="fz-10 c-white text-center">주행</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr className="text">
                        <td>
                            국내면허 인정국가의 
                                권한있는 기관에서 교부한 
                                운전면허증 소지자
                        </td>
                        <td>
                            제 2종 보통면허
                        </td>
                        <td>
                            <div className="orange_circle"></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        
                    </tr>

                    <tr className="text">
                        <td>
                            국내면허 불인정국가의 
                            권한있는 기관에서 교부한 
                            운전면허증 소지자
                        </td>
                        <td>
                            제 2종 보통면허
                        </td>
                        <td>
                            <div className="orange_circle"></div>
                        </td>
                        <td>
                            <div className="orange_circle"></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        
                    </tr>

                    <tr className="text">
                        <td rowSpan="2">
                            군복무 중 6월 이상 군의 
                            자동차 등 운전 경력자
                        </td>
                        <td>
                            제 1,2종 보통면허 외 면허
                        </td>
                        <td>
                            <div className="orange_circle"></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        
                    </tr>

                    <tr className="text">
                    
                        <td>
                            제 1,2종 보통면허
                        </td>
                        <td>
                            <div className="orange_circle"></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        
                    </tr>

                    <tr className="text">
                        <td>
                            적성검사·면허갱신을 하지
                            않아 면허 취소 후 5년 
                            이내에 재응시하는 사람
                        </td>
                        <td>
                            취소된 면허와 동일한 1종류의 면허
                        </td>
                        <td>
                            <div className="orange_circle"></div>
                        </td>
                        <td>
                            <div className="orange_circle"></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        
                    </tr>

                    <tr className="text">
                        <td>
                            제1종 대형면허 소지자
                        </td>
                        <td>
                            제 1종 특수면허,<br/>제 1,2종 소형면허
                        </td>
                        <td>
                            <div></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        <td>
                            <div className="orange_circle"></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        
                    </tr>

                    <tr className="text">
                        <td rowSpan="2">
                            제1종 특수면허 소지자
                        </td>
                        <td>
                            제 1종 대형면허<br/>
                            제 1,2종 소형면허
                        </td>
                        <td>
                            <div></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        <td>
                            <div className="orange_circle"></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        
                    </tr>
                
                    <tr className="text">
                
                        <td>
                            제1·2종 보통면허
                        </td>
                        <td>
                            <div></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        <td>
                            <div className="orange_circle"></div>
                        </td>
                        
                    </tr>

                    <tr className="text">
                        <td rowSpan="2">
                            제1종 보통면허 소지자
                        </td>
                        <td>
                            제1종 대형면허·특수면허
                        </td>
                        <td>
                            <div className="orange_circle"></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        <td>
                            <div className="orange_circle"></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        
                    </tr>
                
                    <tr className="text">
                
                        <td>
                            제1·2종 소형면허
                        </td>
                        <td>
                            <div></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        <td>
                            <div className="orange_circle"></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        
                    </tr>

                    <tr className="text">
                        <td rowSpan="3">
                            제2종 보통면허 소지자
                        </td>
                        <td>
                            제1종 특수·대형·소형면허
                        </td>
                        <td>
                            <div className="orange_circle"></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        <td>
                            <div className="orange_circle"></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        
                    </tr>
                
                    <tr className="text">
                    
                        <td>
                            제2종 소형면허
                        </td>
                        <td>
                            <div></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        <td>
                            <div className="orange_circle"></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        
                    </tr>
                
                    <tr className="text">
                
                        <td>
                            제1종 보통면허
                        </td>
                        <td>
                            <div className="orange_circle"></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        <td>
                            <div className="orange_circle"></div>
                        </td>
                        
                    </tr>
                    
                    <tr className="text">
                        <td>
                            제2종 소형면허 또는
                            원동기장치 자전거면허 
                            소지자
                        </td>
                        <td>
                            제 2종 보통면허
                        </td>
                        <td>
                            <div></div>
                        </td>
                        <td>
                            <div className="orange_circle"></div>
                        </td>
                        <td>
                            <div className="orange_circle"></div>
                        </td>
                        <td>
                            <div className="orange_circle"></div>
                        </td>
                        
                    </tr>
                
                    <tr className="text">
                        <td>원동기장치 
                            자전거면허 소지자</td>
                        <td>
                            제2종 소형면허
                        </td>
                        <td>
                            <div></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        <td>
                            <div className="orange_circle"></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        
                    </tr>
                    <tr className="text">
                        <td>제2종 보통면허 소지자가 
                            면허신청 일로부터 소급
                            하여 7년간 교통사고를 
                            일으킨 사실이 없는 사람</td>
                        <td>
                            제1종 보통면허
                        </td>
                        <td>
                            <div className="orange_circle"></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        
                    </tr>
                    <tr className="text">
                        <td>제1종운전면허 소지자가 
                            신체장애 등으로 1종운전
                            면허 적성기준에 미달된 자</td>
                        <td>
                            제 2종 보통면허
                        </td>
                        <td>
                            <div className="orange_circle"></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        
                    </tr>
                    <tr className="text">
                        <td>타인에게 운전면허 대여로 
                            취소된 사람, 무등록차량을 
                            운전하여 취소된 사람</td>
                        <td>
                            취소된 면허와 동일한 1종류의 면허
                        </td>
                        <td>
                            <div className="orange_circle"></div>
                        </td>
                        <td>
                            <div className="orange_circle"></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        
                    </tr>
                    <tr className="text">
                        <td>군사분계선 이북지역에서
                            운전면허를 받은 사실을 
                            통일부장관이 확인서를 
                            첨부하여 운전면허시험
                            기관의 장에게 통지한 사람</td>
                        <td>
                            제 2종 보통면허
                        </td>
                        <td>
                            <div className="orange_circle"></div>
                        </td>
                        <td>
                            <div className="orange_circle"></div>
                        </td>
                        <td>
                            <div></div>
                        </td>
                        <td>
                            <div className="orange_circle"></div>
                        </td>
                        
                    </tr>
                    </tbody>
                </table>
            </div>
            ))
        }else if(e.target.id==="2"){
            setView((
                <div className="guide_text_box bg-white">
                <h1 className="fz-16 fw-bold c-text11 text-center mt-5 mb-20">운전면허 결격사유 <br/>
                    (도로교통법 제82조, 도로교통법 시행령 제42조)</h1>
                <p className="mb-20">※ 다음에 해당하는 사람은 운전면허를 받을 수 없습니다.</p>
                <h2 className="fz-16 fw-bold c-orange2 mb-5">
                    연령 · 경력
                </h2>
                <p className="mb-5">· 18세 미만인 사람<br/>
                    (원동기장치 자전거의 경우에는 16세 미만)</p>
                <p className="mb-10">· 제1종 대형면허 또는 제1종 특수면허를 받고자 하는
                    사람이 19세 미만이거나 자동차(이륜자동차와 원동기
                    장치자전거를 제외한다)의 운전 경험이 1년 미만인 사람</p>
                <h2 className="fz-16 fw-bold c-orange2 mb-20">
                    정신질환 등
                </h2>
                <p className="mb-20">· 교통상의 위험과 장해를 일으킬 수 있는 정신질환
                    (치매, 조현병, 분열성 정동장애, 양극성 정동장애, 재발성
                    우울장애 등), 정신발육 지연, 뇌전증, 마약·대마·향정신성
                    의약품 또는 알코올 관련 장애 등으로 인하여 정상적인
                    운전을 할 수 없다고 해당 분야 전문의가 인정하는 사람)
                </p>
                <h2 className="fz-16 fw-bold c-orange2 mb-10">
                    신체조건
                </h2>
                <p>
                    · 듣지 못하는 사람 <br/>
                    <span>(제1종 대형·특수 운전면허에 한함)</span>
                </p>
                <p className="mb-10">
                    · 앞을 보지 못하는 사람<br/>
                    <span>(한쪽 눈만 보지 못하는 사람의 경우에는 제1종 운전면허 중 대형·
                        특수 운전면허에 한함)</span>
                </p>
                <p>· 다리, 머리, 척추, 그 밖의 신체의 장애로 인하여 앉아 있을
                    수 없는 사람
                </p>
                <p>· 양쪽 팔의 팔꿈치 관절 이상을 잃은 사람</p>
                <p>
                    · 양쪽 팔을 전혀 쓸 수 없는 사람<br/>
                    <span>(다만, 신체장애 정도에 적합하게 제작·승인된 자동차를 사용하여
                        정상적인 운전을 할 수 있는 경우는 제외)</span>
                </p>
    
            </div>

            ))
        }else if(e.target.id==="3"){
            ////아직 덜완성됨 내일쯤 다시 달라고해서 확인하자
            setView((
                <div className="guide_text_box bg-white">
                <h1 className="fz-16 fw-bold text-center mb-20">응시제한</h1>
                 <h1 className="fz-12 c-text11 lh-15 mb-20">※ 운전면허 행정처분 시 또는 기타 도로교통법 위반시 이의 경중에<br/>{' '} 따라 일정 기간 응시하지 못하게 하는 제도 입니다.</h1>
                 <h1 className="fz-15 fw-bold c-text11 mb-15">
                     제한시간
                 </h1>
                 <h2 className="mb-5">· 5년 제한</h2>
                 <p className="mb-20 ml-10">- 무면허, 음주운전, 약물복용, 과로 운전, 공동위험행위 중
                     사상사고 야기 후 필요한 구호조치를 하지 않고 도주
                 </p>
                 <h2 className="mb-10">· 4년 제한</h2>
                 <p className="mb-20 ml-10">- 5년 제한 이외의 사유로 사상사고 야기 후 도주
                 </p>
                 <h2 className="mb-5">· 3년 제한</h2>
                 <p className="mb-20 ml-10">- 음주운전을 하다가 2회 이상 교통사고를 야기<br/>
                     - 자동차 이용 범죄, 자동차 강·절취한 자가 무면허로 운전
                        <br/>한 경우
                 </p>
                 <h2 className="mb-10">· 2년 제한</h2>
                 <p className="mb-30 ml-10">- 3회 이상 무면허운전<br/>
                     - 운전면허시험 대리 응시를 한 경우<br/>
                      - 운전면허시험 대리 응시를 하고 원동기면허를 취득하고<br/>
                     자 하는 경우<br/>
                        - 공동위험행위로 2회 이상으로 면허취소 시<br/>
                        - 부당한 방법으로 면허 취득 또는 이용, 운전면허시험 <br/>
                        {' '} 대리 응시<br/>
                        - 다른 사람의 자동차를 강·절취한 자<br/>
                       - 음주운전 2회 이상, 측정 불응 2회 이상 자<br/>
                        - 음주운전, 측정불응을 하다가 교통사고를 일으킨 경우<br/>
                        - 운전면허시험, 전문학원 강사자격시험,기능검정원 자격<br/>
                       시험에서 부정행위를 하여 해당 시험이 무효로 처리된 자<br/>
                       {' '}  ※ 도로교통법 제84조의2(부정행위자에 대한 조치)
                 </p>
                 <h2 className="mb-15">· 1년 제한</h2>
                 <p className="mb-20 ml-10">
                     - 무면허운전<br/>
                     - 공동위험행위로 운전면허가 취소된 자가 원동기면허를
                       취득하고자 하는 경우<br/>
                      - 자동차 이용 범죄<br/>
                      - 2년 제한 이외의 사유로 면허가 취소된 경우<br/>
                 </p>
     
                 <h2 className="mb-15">· 6개월 제한</h2>
                 <p className="mb-15 ml-10">
                     - 단순 음주, 단순 무면허, 자동차이용범죄로 면허취소 후 
                     원동기면허를 취득하고자 하는 경우
                 </p>
     
                 <h2 className="mb-15">· 바로 면허시험에 응시 가능한 경우</h2>
                 <p className="mb-15 ml-10">
                     - 적성검사 또는 면허 갱신 미필자<br/>
                     - 2종에 응시하는 1종 면허 적성검사 불합격자
                 </p>
             </div>

            ))
        }else if(e.target.id==="4"){
            setView((
                <div className="guide_text_box bg-white">
                <h2 className="mb-10">면허종류</h2>
                <p>· 제1종 대형·특수면허 : 만19세 이상으로 1,2종 보통면허
                    취득 후 1년 이상인 자</p>
                <p className="mb-5">· 제1종 보통·2종 보통·2종 소형면허 : 만18세 이상인 자</p>
                <p className="mb-5">· 제2종 원동기장치 자전거 면허 : 만16세 이상인 자</p>
                <p className="mb-15">· 제1종·2종 장애인 면허 : 장애인 운동 능력 측정 시험
                    합격자, 1종을 취득하기 위해서는 1종에 부합되는 합격자</p>
                <h2 className="mb-10 c-text1">신체검사 기준</h2>
                <h3 className="fz-14 fw-bold mb-10 c-text11">시력</h3>
                <p>· 제 1종 면허 : 두 눈을 동시에 뜨고 잰 시력 0.8이상, 두 눈
                    의 시력이 각각 0.5이상<br/>
    
                    <span>※ 단, 한쪽 눈을 보지 못하는 사람은 제1종 대형·특수 운전면허에 응시할 수 없으며, 제1종 보통 운전면허를 취득하고자 하는 경우 다른 쪽 눈의 시력 0.8 이상, 수직 시야
                        20˚, 수평 시야 120˚이상, 중심 시야 20˚내 암점 또는 반맹이 없어야 함</span>
                </p>
                <p className="mb-10">· 제 2종 면허 : 두 눈을 동시에 뜨고 잰 시력이 0.5 이상.
                    다만, 한쪽 눈을 보지 못하는 사람은 다른 쪽 눈의 시력이
                    0.6 이상이어야 함</p>
                <h3 className="fz-14 fw-bold c-text11">색채식별</h3>
                <p className=" mb-20">· 적, 녹, 황색의 색채식별가능</p>
                <h3 className="fz-14 fw-bold c-text11 mb-15">청력</h3>
                <p className="mb-10">· 제1종 대형·특수에 한하여 청력 55 데시벨의 소리를 들을
                    수 있을 것</p>
                <p>·보청기 사용 시 40 데시벨의 소리를 들을 수 있을 것</p>
            </div>
            ))
        }
    }
    return (
        <>
        <div className="wrap_gnb_test_info–exemption bg-gray1">
            <div className="is-flex x-between mx-20">
                <input onClick={menuChange} id="1"  type="button" value="시험 면제"
                className="button button-1 c-white bg-orange2"/>

                <input onClick={menuChange} id="2" type="button" value="운전면허 결격사유"
                className=" button button-1 bg-white c-text1"/>
            </div>
            <div className="is-flex x-between mb-15 mx-20">

                <input onClick={menuChange} id="3" type="button" value="응시 제한" className="button button-1 bg-white c-text1"/>

                <input onClick={menuChange} id="4" type="button" value="시험 자격" className=" button button-1 bg-white c-text1"/>
            </div>
            {view}    
        </div>
        </>
    )
}

export default DetailTab
