import React, { useEffect, useState } from "react";
import axios from "axios";
import { url } from "../../../enum";
import faroad from "../../../image/publishing_Img/faroad.png";

function FuncationalLocationVideoPage(props) {
  const [videos, setvideos] = useState([]);

  const search = props.location.search; // returns the URL query String
  const params = new URLSearchParams(search);
  const branch = params.get("branch");
  const course = params.get("course");
  const returnBack = () => {
    props.history.goBack();
  };
  function getLocationVedio() {
    axios
      .get(url + "/videos/contents?branch=" + branch + "&course=" + course)
      .then((res) => setvideos(res.data.rows));
  }
  useEffect(() => {
    getLocationVedio();
    return () => {};
  }, []);
  const twhite = { color: "#ffffff" };
  const bpurple = { background: "#4C1130" };
  const dmargin = {
    marginBottom: "5px",
    minHeight: "4.5vh",
  };
   const ipadding = { paddingBottom: "1.2vh" };
  return (
    <div className="startDriveVideo full-height bg-gray1">
      <header className="gosu-header-relative">
        <div
          className="gosu-header is-flex y-center x-center bg-white"
          style={bpurple}
        >
          <label className="back_1">
            <p onClick={returnBack} className="fz-14 c-purple2" style={twhite}>
              이전
            </p>
          </label>
          <p className="fz-16 fw-bold c-purple2" style={twhite}>
            {branch}
          </p>
        </div>
      </header>
      <article className="wrap-test_place_video–2">
        <div className="test_place_list ">
          <ul>
            <li className="list_box bg-white" style={dmargin}>
              <div className="right_icons is-flex">
                <label>
                  <div>
                    <img
                      alt="아이콘"
                      className="zocial_www"
                      src={faroad}
                      style={ipadding}
                    />
                  </div>
                </label>
                <p className="fz-16 c-text11 lh-15">{course}</p>
              </div>
            </li>
          </ul>
        </div>
        <div className="video_list_box bg-white mt-10">
          <p className="fz-17 fw-bold mb-20">동영상</p>
          <div className="test_place_list ">
            <ul>
              {videos.length != 0 ? (
                videos.map((value, index) => (
                  <div key={value.idx} className="video_box">
                    <iframe
                      className="iframe_1 mb-5"
                      src={value.link}
                      title={value.name}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />

                    <p className="c-text1 fz-12 mb-20">{value.name}</p>
                  </div>
                ))
              ) : (
                <p className="fz-14 fw-bold mb-20">
                  최신 영상을 준비 중에 있습니다.
                </p>
              )}
            </ul>
          </div>
        </div>
      </article>
    </div>
  );
}

export default FuncationalLocationVideoPage;
