import axios from 'axios'
import React, { useState } from 'react'
import { url } from '../../../enum'
import FindPsswordModal from './FindPsswordModal'

function FindPasswrod(props) {
  const [name,setname]=useState("")
  const [email,setEmail]=useState("")
  const [confirmEmail,setConfirmEmail]=useState()
  const [ment,setMent]=useState(
      <>
      이메일과 성함을 확인해주세요
      </>
  )
  const [visible,setVisible]=useState('none')
  const [stop,setStop]=useState(true)
  const checkEmail=()=>{
    
        var regex=/^[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[@]{1}[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[.]{1}[A-Za-z]{1,5}$/;
        if(!regex.test(email)){
            setConfirmEmail(false)
      
            setMent(
                <>
                이메일 양식을 지켜주세요!
                </>
            )
            return alert("이메일 양식을 확인해주세요!")
        }
        if(!stop){
            return alert("회원정보가 확인되었습니다.")
        }
        console.log(email)
        console.log(name)
        axios.post(url+`/users/exists?`,{
            userid:email,
            username:name.trim()

        }).then(res=>{
            
            if(res.data.resCode===0){
                setConfirmEmail(true)
                setStop(false)
                setMent(
                    <>
                        회원정보가 확인 되었습니다.<br/> 
                        다음버튼을 눌러주세요
                    </>
                )
            }else if(res.data.resCode===1){
                setConfirmEmail(false)
                setMent(
                    <>
                      해당 정보가 존재하지 않습니다.
                    </>
                )
            }
        })
        

  }
  const goToNext=()=>{
      if(confirmEmail){
        setVisible('block')
       
      }else{
          alert("성함과 이메일을 확인해주세요!")
      }

  }
    return (
        <>
        
            <header className="gosu-header-relative"> 
                <div className="gosu-header is-flex y-center x-center bg-white">
                    <label className="back_1" onClick={()=>props.history.goBack()}>
                        <p className="fz-14 c-text11">이전</p>
                    </label>
                <p className="c-text11 fz-16 fw-bold" >비밀번호찾기</p>
                </div>
            </header>  
            <article className="wrap-password-find bg-gray1">
                <div className="wrap_box bg-white">
                    <div className="logo is-flex x-center y-center mb-5">
                        <img  src={require('../../../image/publishing_Img/logo_black.png').default } />
                    </div>
                    <p className="fz-12 fw-bold c-text1 mb-10">이름</p>
                    <div className="mb-30 rel_box">
                        <input className="typing_1 ml-5 c-text14 fz-12" value={name.trim()} onChange={(e)=>setname(e.target.value)} type="text" name="password" placeholder="실명을 입력하세요" />
                    </div>
                    <p className="fz-12 fw-bold c-text1 mb-10"> 아이디(이메일)</p>
                    <div className="is-flex x-between mb-10 rel_box">
                        <input className="typing_2 ml-5 c-text14 fz-12"  value={email} onChange={(e)=>setEmail(e.target.value)} type="text" name="ID" placeholder="아이디 입력" />
                        <button className="ID_check button" onClick={checkEmail}>
                            <p className="c-orange3 fw-bold fz-10" >확인</p>
                        </button>
                    </div>
                    <p className="fz-12 text-center lh-15 c-text11">
                       {ment}
                    </p>
                </div>
            </article>
      
            {
            confirmEmail?
            <div onClick={goToNext} id="next" className="Next_button bg-orange2 is-flex y-center x-center button" >
                    <p className="fw-bold c-white  fz-20" id="nextText" >
                        다음
                    </p>
                </div>
                :
                <div onClick={goToNext} id="next" className="Next_button bg-gray2 is-flex y-center x-center button" >
                <p className="fw-bold fz-20" id="nextText" >
                    다음
                </p>
            </div>
            }
            <FindPsswordModal name={name} email={email} visible={visible} setVisible={setVisible} />

        </>
    )
}

export default FindPasswrod
