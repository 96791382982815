import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom';


import TotalTestDetailInfomation from'./TotalTestDetailInfomation'
import AllCourse from'../FunctionalDriveAndCourse/AllCourse'
import axios from 'axios';
import { url } from '../../../enum';

function TestInfomationMenuPage(props) {
   const [banners,setBanners]=useState("")
   const [view, setView]=useState(<TotalTestDetailInfomation/>)
   const changeList=(e)=>{

        let input=document.getElementsByName('input')
        for(let i=0; i<input.length; i++){
            input[i].className="fz-12 c-test1 bg-white button"
        }
         e.currentTarget.className="fz-12 c-white bg-orange1 button"
        if(e.currentTarget.id==="total"){
            setView(<TotalTestDetailInfomation/>)
        }else{
            setView(<AllCourse/>)
        }

    }

  
    useEffect(() => {
        async function getbanner(){

            await axios.get(url+"/banners").then(res=>{
                setBanners(res.data.rows[0])
            })
        }
        getbanner()
        
    }, [])
    return (
        <div className="">
            <header className="gosu-header-relative">
                <div className="gosu-header is-flex y-center x-center bg-white">
                    <label className="back_1" onClick={()=>props.history.goBack()}>
                        <p className="fz-14 c-text11">닫기</p>
                    </label>
                    <p className="c-text11 fz-16 fw-bold">시험정보</p>
                </div>
            </header>
            <article className="wrap_gnb_test_info bg-gray1">
                <div className="is-flex x-between top_box mb-20">
                    <input type="button" id="total" name="input" onClick={changeList} value="공통 시험정보" className="fz-12 c-white bg-orange1 button"/>
                    <input type="button" id="testCenter" name="input"  onClick={changeList} value="시험장 정보" className="fz-12 c-test1 bg-white button"/>
                </div>
                {view}

                <div   className="Advertising_box bg-white">
                    <img  src={banners.imageUrl} onClick={()=> window.ReactNativeWebView.postMessage(banners.linkUrl)} alt="광고"/>
                </div>
            </article>
        </div>
    )
}

export default withRouter(TestInfomationMenuPage)
