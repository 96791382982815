import React, { useEffect, useState } from 'react'
import logo_black from '../../../image/publishing_Img/logo_black.png'
import Icon_ionic_ios_arrow_forward from'../../../image/publishing_Img/Icon-ionic-ios-arrow-forward.png'
import axios from 'axios'
import { url } from '../../../enum'
import SignUPModal_1 from './SignUPModal_1'
import SignUPModal_2 from './SignUPModal_2'
/*
import SignUpConfirmModal from'./SignUpConfirmModal'
    <SignUpConfirmModal visible={visible} email={email} 
    password={password} name={name} setVisible={setVisible}
    birth={toString(birth) } gender={gender} /> 
 */
function SighUp(props) {
    const [email,setEmail]=useState("")
    const [password,setPassword]=useState("")
    const [confirmPassword, setConfirmPassword]=useState("")
    const [name, setName]=useState("")
    const [birth,setBirth]=useState("00000000")
    const [gender, setGender]=useState("2")
    
    const [termsConditions,setTermsConditions]=useState("2")
    const [personalInformation,setPersonalInformation]=useState("2")
    const [marketingInformation,setMarketingInformation]=useState("2")
    const [confirm,setConfirm]=useState("")

    const [modal1,setModal1]=useState('none')
    const [modal2,setModal2]=useState('none')

    useEffect(() => {
          
        if(password.length!==0&&password===confirmPassword){
            
            setConfirm((<p className="fz-8 c-orange3 underline_position">비밀번호가 일치합니다.</p>))
        }else{
            setConfirm("")
        }
        var regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
        //&&marketingInformation마케팅 정보  &&birth!=="" 일단 뺌
        if(regExp.test(email)&&password!==""&&password===confirmPassword&&confirmPassword!==""&&name!==""&&gender!==""&&termsConditions==="1"&&personalInformation==="1"){
            document.getElementsByName('next')[0].className="Next_button bg-orange2 is-flex y-center x-center button"
            document.getElementsByName('next')[1].className="fz-20 c-white"

        }else{
            <p name="next" className="fw-bold fz-20">다음</p>
            document.getElementsByName('next')[0].className="Next_button bg-gray2 is-flex y-center x-center button"
            document.getElementsByName('next')[1].className="fw-bold fz-20"
        }

      
    }, [email,password,confirmPassword,name,birth,gender,termsConditions,personalInformation,marketingInformation])    
    //이메일
    const emailChange=(e)=>{
        setEmail(e.target.value)
    }
    //비밀번호
    const passwordChange =(e)=>{
        setPassword(e.target.value)
    }
    //비밀번호 확인
    const confirmPasswordChange=(e)=>{
        setConfirmPassword(e.target.value)
    }
    //이름
    const nameChange=(e)=>{
        setName(e.target.value)
    }
    //생일
    const birthChange=(e)=>{
        setBirth(e.target.value)
    }
    useEffect(() => {
        var regexp = /^[0-9]*$/
        if(!regexp.test(birth) ) {
            alert("숫자만 입력하세요");
            setBirth("")
        }
    }, [birth])
    //성별선택
    const changeGender=(e)=>{
        let guest_3 =document.getElementsByClassName("wrap-guest-3")[0];

        setGender(e.target.id)
        if(e.target.id==='2'){
            //여자
            guest_3.querySelector(".gender_A").className="fz-12 c-white button gender_button  bg-orange2 gender_A"
            guest_3.querySelector(".gender_B").className="fz-12 text11 button gender_button bg-gray1 gender_B"
            setGender("2")
        }else{
            //남자
            guest_3.querySelector(".gender_A").className="fz-12 text11 button gender_button bg-gray1  gender_A"
            guest_3.querySelector(".gender_B").className="fz-12 c-white button gender_button bg-orange2 gender_B"
            setGender("1")
        }
        
    }
   
  

    //회원가입
    const onSubmit=(e)=>{
        e.preventDefault()
        var regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
        
        if(!regExp.test(email)){
            alert("잘못된 이메일 형식입니다.")
            return false
        }
        if(gender===""){
            alert("성별을 선택해주세요!")
            return false
        }
        if(password.length<6){
            alert("비밀번호 6자리를 입력해주세요")
            return false
        }
        if(password!==confirmPassword){
            alert("비밀번호가 서로 맞지 않습니다.")
            return false
        }
        
        // if(birth.length!==8){
        //     alert("생년 월일을 8자리를 입력해주세요!")
        //     return false
        // }
        if(termsConditions!=="1"){
            alert("이용약관에 동의해주세요")
            return false
        }
        if(personalInformation!=="1"){
            alert("개인정보 취급방침에 동의해주세요")
            return false
        }
       
        console.log(gender)
        axios.post(url+'/users/register',
        {userid:email,password:password,username:name,birthday:birth,gender:gender,examType:localStorage.getItem('license')}).then(res=>{
            //회원가입이 성공하면 코드0
            if(res.data.resCode===0){
                props.history.push('/SignUpSuccess')
            }else if(res.data.resCode===2){
                return alert("중복된 이메일입니다!")
            }
        })
    }

    return (
        <>
        <div className="bg-gray1">
            <header className="gosu-header-relative">
                <div className="gosu-header is-flex y-center x-center bg-white">
                    <label className="back_1">
                        <p onClick={()=>props.history.goBack()} className="fz-14 c-text11">이전</p>
                    </label>
                    <p className="c-text11 fz-16 fw-bold">회원가입</p>
                </div>
            </header>
           </div>
            <form onSubmit={onSubmit} >
                <div className="wrap-guest-3 bg-gray1">
                    <div className="wrap_box bg-white">
                        <div className="logo is-flex x-center y-center mb-5">
                            <img alt="로고" src={logo_black}/>
                        </div>
                        <div>
                            <p className="fz-12 fw-bold c-text11 mb-10">아이디(이메일)</p>
                            <div className="is-flex x-between mb-25 rel_box">
                                <input className="typing_1 ml-5 c-text1 fz-12" type='email' value={email}
                                onChange={emailChange} placeholder="아이디 입력" required/>
                            </div>
                            <p className="fz-12 fw-bold c-text11 mb-10">비밀번호</p>
                            <div className="mb-30 rel_box">
                                <input
                                    className="typing_1 ml-5 c-text1 fz-12" name="password"
                                    id="password"type="password"
                                    vlaue={password} onChange={passwordChange}
                                    placeholder="비밀번호 6자리" required/>
                            {confirm}
                            </div>
                            <p className="fz-12 fw-bold c-text11 mb-10">비밀번호 확인</p>

                            <div className="mb-25 rel_box">
                                <input className="typing_1 ml-5 c-text1 fz-12" name="password" id="confirmPassword" type="password" 
                                value={confirmPassword} onChange={confirmPasswordChange}
                                placeholder="비밀번호 확인" required/>
                            {confirm}
                            </div>
                            <p className="fz-12 fw-bold c-text11 mb-10">이름</p>
                            <div className="mb-40">
                                <input type="text" className="typing_1 ml-5 c-text1 fz-12" value={name} onChange={nameChange}
                                placeholder="실명을 입력하세요" required/>
                            </div>
                            {/* <p className="fz-12 fw-bold c-text11 mb-10">생년월일</p>
                            <div className="mb-25">
                                <input className="typing_1 ml-5 c-text1 fz-12" type="text" name="birth" value={birth} onChange={birthChange} 
                                placeholder="8자리를 입력해주세요" required/>
                            </div> */}
                        </div>
                        <div>
                            
                            <p className="fz-12 fw-bold mb-20 c-text11">약관동의</p>

                            <div className="guide is-flex x-between y-center" >
                                <label className="is-flex y-center" onClick={()=>setModal1('block')}>
                                    <p className="fz-12 c-text11">이용약관 보기</p>
                                    <img className="next_icon" alt="arrow" src={Icon_ionic_ios_arrow_forward}/>
                                </label>
                                <div className="is-flex termsConditions">
                                    <input type="button" value="동의" id="termsConditions" onClick={()=>setTermsConditions("1")} className={`button guide_button ${termsConditions==="1"?"bg-orange1 c-white":"bg-gray1 c-text11"} agree fz-12`} />

                                    <input type="button" value="미동의" id="termsConditions" onClick={()=>setTermsConditions("3")} className={`button guide_button ${termsConditions==="3"?"bg-orange1 c-white":"bg-gray1 c-text11"} agree fz-12`} />
                                </div>
                            </div>

                            <div className="guide is-flex x-between y-center"  >
                                <label className="is-flex" onClick={()=>setModal2('block')}>
                                    <p className="fz-12 c-text"> 개인정보 취급방침 보기</p>
                                    <img className="next_icon" alt="arrow" src={Icon_ionic_ios_arrow_forward}/>
                                </label>
                                <div className="is-flex personalInformation">
                                    <input type="button" value="동의" id="personalInformation" onClick={()=>setPersonalInformation("1")} className={`button guide_button ${personalInformation==="1"?"bg-orange1 c-white":"bg-gray1 c-text11"} agree fz-12`} />

                                    <input type="button" value="미동의" id="personalInformation" onClick={()=>setPersonalInformation("3")} className={`button guide_button ${personalInformation==="3"?"bg-orange1 c-white":"bg-gray1 c-text11"} agree fz-12`} />
                                </div>
                            </div>

                            <div className="guide is-flex x-between y-center"  >
                                <label className="is-flex">
                                    <p className="fz-12 c-text11">마케팅 정보 수신동의 (선택)</p>
                                    <img className="next_icon" alt="arrow" src={Icon_ionic_ios_arrow_forward}/>
                                </label>
                                <div className="is-flex marketingInformation">
                                    <input type="button" value="동의" id="marketingInformationAgree" onClick={()=>setMarketingInformation("1")} className={`button guide_button ${marketingInformation==="1"?"bg-orange1 c-white":"bg-gray1 c-text11"} agree fz-12`} />

                                    <input type="button" value="미동의" id="marketingInformationDisagree" onClick={()=>setMarketingInformation("3")} className={`button guide_button ${marketingInformation==="3"?"bg-orange1 c-white":"bg-gray1 c-text11"} agree fz-12`} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <button name="next" className="Next_button bg-gray2 is-flex y-center x-center button">
                    <p name="next" className="fw-bold fz-20">다음</p>
                </button>
      
            </form>
      
            <SignUPModal_1
            modal1={modal1}
            setModal1={setModal1}
            setTermsConditions={setTermsConditions}
        />
         <SignUPModal_2
            modal2={modal2}
            setModal2={setModal2}
            setPersonalInformation={setPersonalInformation}
        />
    </>
    )
}

export default SighUp
