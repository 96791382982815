export default function testData(workSheet) {
  const XLSX = require("xlsx");
    var range = XLSX.utils.decode_range(workSheet["!ref"]);
  //0 기출문제 1 전국 면허학원 정보 2 고수운전학원 정보

  let question = [];
  let num = 1;
  for (let i = 2; i < range.e.r + 2; i++) {
    let examInfo = {
      number: "",
      score: "",
      type: "",
      right: [],
      query: "",
      queryImg: "",
      branches: [],
      explanation: "",
      explanationMedia: "",
    };
    try {
      let branches = { word: "", img: "" };

      let data = "";
      //유형
      data = workSheet["A" + i.toString()].v;
      examInfo.type = data;

      //번호
      data = workSheet["B" + i.toString()].v;
      examInfo.number = num;

      //문제
      data = workSheet["C" + i.toString()].v;
      examInfo.query = data;

      //문제이미지
      if (!workSheet["D" + i.toString()]) {
        data = "";
        examInfo.queryImg = data;
      } else {
        data = workSheet["D" + i.toString()].v;
        examInfo.queryImg = data;
      }

      let branchImg = "";

      if (!workSheet["J" + i.toString()]) {
        //보기이미지가 없을때
        branchImg = "";
        branches.img = branchImg;

        data = workSheet["E" + i.toString()].v;
        branches.word = data;
        examInfo.branches.push(branches);
        branches = { word: "", img: "" };

        data = workSheet["F" + i.toString()].v;
        branches.word = data;
        examInfo.branches.push(branches);
        branches = { word: "", img: "" };

        data = workSheet["G" + i.toString()].v;
        branches.word = data;
        examInfo.branches.push(branches);
        branches = { word: "", img: "" };

        data = workSheet["H" + i.toString()].v;
        branches.word = data;
        examInfo.branches.push(branches);
        branches = { word: "", img: "" };

        //5번째 문항은 있으면 넣고 없으면 넣지말자
        if (workSheet["I" + i.toString()]) {
          data = workSheet["I" + i.toString()].v;
          branches.word = data;
          examInfo.branches.push(branches);
        }
      } else {
        //보기이미지가 있을때
        branchImg = workSheet["J" + i.toString()].v;

        branchImg = branchImg.split(",");

        data = !workSheet["E" + i.toString()]
          ? ""
          : workSheet["E" + i.toString()].v;
        branches.img = branchImg[0];
        branches.word = data;
        examInfo.branches.push(branches);
        branches = { word: "", img: "" };

        data = !workSheet["F" + i.toString()]
          ? ""
          : workSheet["F" + i.toString()].v;
        branches.img = branchImg[1];
        branches.word = data;
        examInfo.branches.push(branches);
        branches = { word: "", img: "" };

        data = !workSheet["G" + i.toString()]
          ? ""
          : workSheet["G" + i.toString()].v;
        branches.img = branchImg[2];
        branches.word = data;
        examInfo.branches.push(branches);
        branches = { word: "", img: "" };

        data = !workSheet["h" + i.toString()]
          ? ""
          : workSheet["h" + i.toString()].v;
        branches.img = branchImg[3];
        branches.word = data;
        examInfo.branches.push(branches);
        branches = { word: "", img: "" };
      }

      //정답
      data = workSheet["K" + i.toString()].v;
      if (data.length > 0) {
        data = data.split(",");
        for (let i = 0; i < data.length; i++) {
          examInfo.right.push(data[i]);
        }
      } else {
        examInfo.right.push(data);
      }

      //해설
      if (!workSheet["L" + i.toString()]) {
        data = "";
        examInfo.explanation = data;
      } else {
        data = workSheet["L" + i.toString()].v;
        examInfo.explanation = data;
      }
      //해설이미지
      if (!workSheet["M" + i.toString()]) {
        data = "";
        examInfo.explanationMedia = data;
      } else {
        data = workSheet["M" + i.toString()].v;
        examInfo.explanationMedia = data;
      }
      examInfo.score = workSheet["N" + i.toString()].v;
      question.push(examInfo);
       num = num + 1;
    } catch { console.log(i); }
   
  }
  console.log("테스트", JSON.stringify(question));
  localStorage.setItem("testData", JSON.stringify(question));
}